// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {fontFamilyMedium} from "style/fonts";
import styled from "styled-components";
import {IfLoginInfo} from "models/IfLoginInfo";
import {SsqImages} from "images/SsqImages";
import {ssqutil} from "util/ssqutil";
import {ISsqDataCreatePartnerDoc} from "repositories/SsqDataRepository";
import axios from "axios";


type Props = {
  partnerDoc: ISsqDataCreatePartnerDoc;
  headerStyle: string;
  onLoad?;
};
export const UserPartnerManagerProfileImage = (props: Props) => {

  const [user, setUser] = useState({
    nickname: "",
    profilePhotoFileKey: null,
    techPoint: 0,
    userRoles: "",
  });

  useEffect(() => {
    axios.get('/api/guest/communityprofile', {
      params: {id: props.partnerDoc.mngrUserId}
    }).then((res) => {
      setUser(res.data);
    });
  }, [props.partnerDoc.mngrUserId]);


  const techPoint = user?.techPoint;
  const getHeaderStyle = () => {
    if (props.headerStyle === "header") return "header-profile";
    if (props.headerStyle === "userInfo") return "user-profile";
    if (props.headerStyle === "mobile") return "mobile-profile";
    return "mobile-profile";
  };
  const getTechPointCls = (techPoint: number) => {
    if (techPoint >= 1000) return 'first';
    else if (techPoint >= 500) return 'second';
    else if (techPoint >= 100) return 'third';
    else if (techPoint >= 30) return 'fourth';
    return 'fifth';
  };
  const onLoad = (e?) => {
    if (props.onLoad) props.onLoad();
  };

  const getProfileImg = (imageFile: string) => {
    return imageFile == null || imageFile == "" ? SsqImages.mypage.avatar["avatar06"]() :
      imageFile.startsWith("avatar") ? SsqImages.mypage.avatar[imageFile]() :
        imageFile.startsWith("myphoto") ? ssqutil.getCdnUrl(user?.profilePhotoFileKey) : null;
  };


  useEffect(() => {
    if (!user?.profilePhotoFileKey) {
      onLoad();
    }
  }, []);
  return (
    <UserPartnerProfileImageWrap>
      <div className={getHeaderStyle()}>
        <span className={`${getTechPointCls(techPoint)}`}>
          {user?.profilePhotoFileKey &&
            <img src={getProfileImg(user?.profilePhotoFileKey)}
              onLoad={onLoad}
              style={{width: '100%', height: '100%', objectFit: 'cover'}} alt=""/>}
          {!user?.profilePhotoFileKey && <div>{user.nickname?.[0]}</div>}

        </span>
      </div>
    </UserPartnerProfileImageWrap>
  );
};


const UserPartnerProfileImageWrap = styled.div`
  .header-profile span {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 2rem;
    overflow: hidden;
    color: white;
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      background: #EEF1F6;
    }
  }

  .header-profile span.first {
    border: 1px solid #EDAE0A !important;
    background: #EDAE0A;
  }

  .header-profile span.second {
    border: 1px solid #FF7A00 !important;
    background: #FF7A00;
  }

  .header-profile span.third {
    border: 1px solid #7B5DF1 !important;
    background: #7B5DF1;
  }

  .header-profile span.fourth {
    border: 1px solid #407EDB !important;
    background: #407EDB;
  }

  .header-profile span.fifth {
    border: 1px solid #7C899E !important;
    background: #7C899E;
  }

  .user-profile {
    margin-bottom: .6rem;
  }

  .user-profile span {
    width: 4.8rem;
    height: 4.8rem;
    margin: auto;
    border-radius: 100%;
    overflow: hidden;
    ${fontFamilyMedium};
    font-size: 2.4rem;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      background: #EEF1F6;
    }
  }

  .user-profile span.first {
    border: 1px solid #EDAE0A !important;
    background: #EDAE0A;
  }

  .user-profile span.second {
    border: 1px solid #FF7A00 !important;
    background: #FF7A00;
  }

  .user-profile span.third {
    border: 1px solid #7B5DF1 !important;
    background: #7B5DF1;
  }

  .user-profile span.fourth {
    border: 1px solid #407EDB !important;
    background: #407EDB;
  }

  .user-profile span.fifth {
    border: 1px solid #7C899E !important;
    background: #7C899E;
  }
`;
