import {resi18nutil} from "language/resi18nutil";

const resi18n_common = {
  I18N_COMMON_TIT_MAIN: ["솔루션스퀘어", "Solution Square", undefined],
  I18N_COMMON_TIT_QNA: ["Q&A - 솔루션스퀘어", "Q&A - Solution Square", undefined],
  I18N_COMMON_TIT_TECHLOG: ["Knowledge Base - 솔루션스퀘어", "Knowledge Base - Solution Square", undefined],
  I18N_COMMON_TIT_BOARD: ["게시판 - 솔루션스퀘어", "Board - Solution Square", undefined],
  I18N_COMMON_TIT_SAMPLE: ["샘플라이브러리 - 솔루션스퀘어", "sample - Solution Square", undefined],
  I18N_COMMON_TIT_PROD: ["제품 - 솔루션스퀘어", "product - Solution Square", undefined],
  I18N_COMMON_TIT_INDUST: ["산업 - 솔루션스퀘어", "industry - Solution Square", undefined],
  I18N_COMMON_TIT_ONLINE_TRAINING: ["실시간 강의실 - 솔루션스퀘어", "online training - Solution Square", undefined],
  I18N_COMMON_TIT_REMOTE_SUPPORT: ["원격지원 - 솔루션스퀘어", "remote support - Solution Square", undefined],
  I18N_COMMON_TIT_SEARCH: ["검색 - 솔루션스퀘어", "search - Solution Square", undefined],
  I18N_COMMON_TIT_PROFILE: ["프로필 - 솔루션스퀘어", "profile - Solution Square", undefined],
  I18N_COMMON_TIT_PRODUCT_SELECTOR: ["Product Selector - 서비스 - 솔루션스퀘어", "Product Selector - Service - Solution Square", undefined],
  I18N_COMMON_TIT_ENERGY_SAVE: ["에너지 절감 시뮬레이터 - 서비스 - 솔루션스퀘어", "Energy Saving Simulator - Service - Solution Square", undefined],
  I18N_COMMON_TIT_ASSET: ["Asset Insight - 서비스 - 솔루션스퀘어", "Asset Insight - Service - Solution Square", undefined],
  I18N_COMMON_ADMIN_SETTING: ["관리자 설정 - 솔루션 스퀘어", "Admin settings -Solution Square", undefined],
  I18N_COMMON_TIT_SW_SERVICE: ['SW 서비스 - 솔루션스퀘어', 'SW Service - Solution Square', undefined],
  I18N_COMMON_TIT_RND: ['RnD - 커뮤니티 - 솔루션스퀘어', 'RnD - Community - Solution Square', undefined],
  I18N_COMMON_TIT_PARTNER: ['Partner - 커뮤니티 - 솔루션스퀘어', 'Partner - Community - Solution Square', undefined],

  // Product Selector.
  I18N_PRODSEL_SAVE: ['저장하기', 'Save', undefined],
  I18N_PRODSEL_SAVE_DESC: ['새로 만들거나, 덮어 쓸 파일을 선택하세요.', 'Add a new list, or select a list to overwrite.', undefined],
  I18N_PRODSEL_MSG_SELECT_OVERWRITE: ['덮어 쓸 목록을 선택주세요.', 'Please select a list to overwrite.', undefined],
  I18N_PRODSEL_OPEN_DESC: ['불러올 프로젝트를 선택하세요.', 'Select a project to open', undefined],
  I18N_PRODSEL_SAVE_DONE: ['목록이 저장되었습니다', 'The list has been saved.', undefined],
  I18N_PRODSEL_SELECT_OVERWRITE_ITEM: ['덮어 쓸 목록을 선택주세요', 'Please select a list to overwrite', undefined],
  I18N_PRODSEL_TITLE: ['제목', 'Title', undefined],
  I18N_PRODSEL_DESC: ['설명', 'Description', undefined],
  I18N_PRODSEL_PLACEHOLDER: ['20자 이내 텍스트', 'Max. 20 characters', undefined],
  I18N_PRODSEL_PLACEHOLDER2: ['100자 이내 텍스트', 'Max. 100 characters', undefined],
  I18N_PRODSEL_INPUT_ERR_MSG: ['20자 이내 텍스트를 입력해 주세요.', 'Max. 20 characters', undefined],
  I18N_PRODSEL_BTN_NEW: ['새로 만들기', 'New', undefined],
  I18N_PRODSEL_BTN_OVERWRITE: ['덮어 쓰기', 'Overwrite', undefined],
  I18N_PRODSEL_BTN_CANCEL: ['취소', 'Cancel', undefined],
  I18N_PRODSEL_BTN_SAVE: ['저장하기', 'Save', undefined],
  I18N_PRODSEL_OPEN: ['프로젝트 열기', 'Open Project', undefined],
  I18N_PRODSEL_BTN_OPEN: ["열기", "Open", undefined],
  I18N_PRODSEL_NO_LIST: ['저장된 리스트가 없습니다.', 'There is no list.', undefined],
  I18N_PRODSEL_SAVE_ALERT: ['프로젝트를 열기 시 저장되지 않은 목록은 사라집니다.', 'Unsaved lists will disappear when you open the project.', undefined],
  I18N_PRODSEL_LIST_SAVE: ['목록에 담기', "Add item", undefined],
  I18N_PRODSEL_LIST_SAVE_DESC: ['기존 목록에 담거나, 새 목록에 담으세요.', "Update existing project or Create a new project.", undefined],
  I18N_PRODSEL_MY_LIST: ['내 목록', "My Projects", undefined],
  I18N_PRODSEL_NOT_EXIST_MY_LIST: ['내 목록이 없습니다.', "There is no project on the list yet.", undefined],
  I18N_PRODSEL_MADE_MY_LIST: ['새로 만들기를 선택하여 목록을 만드세요.', "Click new to create your own project.", undefined],
  I18N_PRODSEL_ADD: ['담기', "Add", undefined],
  I18N_PRODSEL_MODIFY_TITLE_DESC: ['수정되었습니다.', "The change has been completed.", undefined],
  I18N_PRODSEL_MODIFY: ['수정하기', "Modify", undefined],
  I18N_PRODSEL_NEW_LIST: ['새 프로젝트', "New Project", undefined],
  I18N_PRODSEL_NEW_LIST_DESC: ['담은 목록을 새 프로젝트로 저장합니다.', "Create a New Project", undefined],
  I18N_PRODSEL_MAX_TEN: ['Max 10개. 오래된 순으로 삭제', "Max. 10 (Delete by oldest)", undefined],
  I18N_PRODSEL_MODIFY_DESC: ["프로젝트 정보를 수정하세요.", "Modify the Project.", undefined],
  I18N_PRODSEL_SAVE_ONE: ['저장할 제품이 없습니다.', 'There are no products to save.', undefined],
  I18N_COMMON_PARTNER: ['파트너', "Partner", undefined],
  I18N_COMMON_PARTNER_ONLY: ["파트너 전용", "Partner Only", undefined],

  I18N_UNKNOWN: ['UNKNOWN', 'UNKNOWN', undefined],
};

export type RES_COMMON = keyof typeof resi18n_common;

const [ko, en, enus] = resi18nutil.getres(resi18n_common);

export const resi18n_common_ko = ko;
export const resi18n_common_en = en;
export const resi18n_common_enus = enus;


