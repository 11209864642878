import * as React from 'react';
import {RefObject, useEffect, useRef, useState} from 'react';
import {AssetOverlay, AssetTableWrap, TitleCont} from "asset/style/AssetStyled";
import styled from 'styled-components';
import {StatusIcon} from "asset/cmpns/common/StatusIcon";
import {Link} from "react-router-dom";
import {ReactComponent as GuideIcon} from 'asset/images/icon/icon_guide.svg';
import {IconBtn, TextBtn} from "asset/style/buttonInput";
import useOutsideClick from "hooks/useOutsideClick";
import {useLoginInfo} from "hooks/useLoginInfo";
import {dispDateTime} from "util/formatDateTime";
import {getDevStatClass, getDevStatStr} from "asset/models/defs";
import {useApiDevPjtsMap} from "asset/apis/useApiProjectDevice";
import {IfAlarmRes} from "asset/models/IfAlarmEntity";
import axios from "axios";
import {useSWRConfig} from "swr";
import {i18ntrans} from "language/i18nkey";
import {getGuideUrl} from "../../utils/alarm-util";
import {Nickname} from "asset/pages/settings/useApiNicknameInfo";

type Props = {
  show: boolean,
  hide: () => void,
  buttonRef: RefObject<HTMLButtonElement>,
  alarmList: IfAlarmRes[]
};

export const AlarmModal = ({show, hide, buttonRef, alarmList}: Props) => {
  const ref = useRef();
  useOutsideClick([ref, buttonRef], () => hide(), []);
  const loginInfo = useLoginInfo();
  const [height, setHeight] = useState(window.innerHeight * 0.76);
  const [dragging, setDragging] = useState(false);
  const [initialY, setInitialY] = useState(0);
  const [devPjtsMap] = useApiDevPjtsMap({userId: loginInfo.userId, verified: true});
  const [checkAlarmId, setCheckAlarmId] = useState<number[]>([]);
  const {mutate} = useSWRConfig();
  const [alarmModalAni, setAlarmModalAni] = useState(false);
  const t = i18ntrans.tasset;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();

    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    setDragging(true);
    setInitialY(clientY);
  };

  const handleMouseMove = (e: MouseEvent | TouchEvent) => {
    if (!dragging) return;

    const clientY = 'touches' in e ? e.touches[0].clientY : (e as MouseEvent).clientY;
    const deltaY = (clientY - initialY);
    const calcHeight = height + deltaY;

    // 최소 높이를 확인하여 이를 넘지 않도록 설정합니다.
    setHeight(Math.min(calcHeight, window.innerHeight * 0.9));
    setInitialY(clientY);
  };

  const handleMouseUp = () => {
    if (!dragging) return;
    setDragging(false);
  };

  useEffect(() => {
    const onMouseMove = (e: MouseEvent | TouchEvent) => handleMouseMove(e as MouseEvent);
    const onMouseUp = () => handleMouseUp();

    if (dragging) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('touchmove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('touchend', onMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchend', onMouseUp);
    };
  }, [dragging]);

  const handleRowClick = ({alarmId, resolvedTime}) => {
    if (resolvedTime) return;
    const index = checkAlarmId.indexOf(alarmId);
    const updatedCheckAlarmId = [...checkAlarmId];
    if (index === -1) {
      updatedCheckAlarmId.push(alarmId);
    } else {
      updatedCheckAlarmId.splice(index, 1);
    }
    setCheckAlarmId(updatedCheckAlarmId);
  };

  const handleConfirm = () => {
    //const param = {alarmIds: [...checkAlarmId], resolvedTime: new Date()};
    const param = {alarmIds: [...checkAlarmId], confirmedBy: loginInfo?.userId};
    patchReservedTime(param).then(() => null);
  };

  const handleAllConfirm = () => {
    const notReservedAlarmList = alarmList.filter(ele => !ele.resolvedTime).map(ele => ele.alarmId);
    //const param = {alarmIds: [...notReservedAlarmList], resolvedTime: new Date()};
    const param = {alarmIds: [...notReservedAlarmList], confirmedBy: loginInfo?.userId};
    patchReservedTime(param).then(() => null);
  };

  const patchReservedTime = async (param: { alarmIds: number[]; resolvedTime?: Date; confirmedBy: string }) => {
    if (param.alarmIds.length < 1) return;

    await axios.patch('/api/member/asset/alarm/patch', param, {
      headers: {'Content-Type': 'application/json'}
    }).then(res => {
      console.log(res.data);
      mutate(() => true);
    }).catch(error => {
      console.error(error);
    });
  };
  useEffect(() => {
    if (show) {
      setAlarmModalAni(true);
    } else {
      setTimeout(() => {
        setAlarmModalAni(false);
      }, 400);
    }
  }, [show]);
  if (!alarmModalAni) {
    return null;
  }

  return (<>
    <AlarmModalPage ref={ref} style={{height: height}}>
      {/* resize 되는 높이 영역 */}
      <AlarmModalCont id="table" className={show ? 'open' : 'off'}>
        <TitleCont className="tit_cont">
          <p className="tit">
            {t("I18N_AST_ALARM")}
            <Link to="/asset/alarm"><IconBtn className="circle_arrow" onClick={hide}></IconBtn></Link>
          </p>
          <div>
            <TextBtn className="size-md non_mobile" onClick={handleConfirm}>{t("I18N_AST_ALARM_CONFIRM")}</TextBtn>
            <TextBtn className="size-md" onClick={handleAllConfirm}>{t("I18N_AST_ALL_CONFIRM")}</TextBtn>
          </div>
        </TitleCont>
        <AssetTableWrap className="table_cont non_mobile">
          <div className={"table_scroll"}>
            <div className="table_box">
              <div className="fixed-table-header-bg"></div>
              <div className="fixed-table-wrapper">
                <table className="click_table over_table">
                  <colgroup>
                    <col style={{width: "240px"}}/>
                    <col style={{width: "auto"}}/>
                    <col style={{width: "9%"}}/>
                    <col style={{width: "11%"}}/>
                    <col style={{width: "11%"}}/>
                    <col style={{width: "220px"}}/>
                    <col style={{width: "220px"}}/>
                    <col style={{width: "8%"}}/>
                    <col style={{width: "120px"}}/>
                  </colgroup>
                  <thead>
                  <tr>
                    {[t("I18N_AST_ALARM_STATUS"),
                      t("I18N_AST_ALARM_DESC"),
                      t("I18N_AST_TYPE"),
                      t("I18N_AST_ALARM_PROJECT"),
                      t("I18N_AST_ALARM_DEVICE"),
                      t("I18N_AST_ALARM_OCCURRED_TIME"),
                      t("I18N_AST_ALARM_RESOLVED_TIME"),
                      t("I18N_AST_ALARM_CONFIRMED_BY"),
                      t("I18N_AST_GUIDE")].map((ele, idx) =>
                      <th scope="col" key={idx}>
                        <div className="th-text">
                          {ele}
                        </div>
                      </th>)}
                  </tr>
                  </thead>
                  <tbody>
                  {alarmList?.map(ele =>
                    <tr onClick={() => handleRowClick({alarmId: ele.alarmId, resolvedTime: ele.resolvedTime})}
                      className={(ele.resolvedTime || ele.confirmedBy) ? 'tr_disabled' : `${checkAlarmId.includes(ele.alarmId) ? 'tr_chk' : ''}`}
                      key={ele.alarmId}>
                      <td><StatusIcon icon={getDevStatClass(ele.status)} size={24} disabled={!!ele.resolvedTime}/>{getDevStatStr(ele.status)}</td>
                      <td>{ele.alarmDesc}</td>
                      <td>{ele.type === "DEV" ? "Device" : "Service"}</td>
                      <td>{devPjtsMap[ele.deviceId]?.map(ele => ele.projectName).join(", ")}</td>
                      <td>{ele.devName}</td>
                      <td className={"td_date"}>{dispDateTime(ele.occuredTime, '-')}</td>
                      <td className={"td_date"}>{dispDateTime(ele.resolvedTime, '-')}</td>
                      <td className={"td_date"}>{ele.confirmedBy ? <Nickname id={ele.confirmedBy}/> : '-'}</td>
                      <td>
                        <a href={getGuideUrl(ele.guideUrl) || '#'} target={'_blank'} onClick={(e) => {
                          if (!ele.guideUrl) e.preventDefault();
                        }} style={{cursor: (ele.guideUrl ? 'pointer' : undefined)}} className={"details_btn"}>
                          <GuideIcon fill="#7B8389"/>
                        </a>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="drag_resize"
            onMouseDown={handleMouseDown}
          />
        </AssetTableWrap>
        <AssetTableWrap className="table_cont mobile">
          <div className="table_box">
            <div className="fixed-table-header-bg"></div>
            <div className="fixed-table-wrapper">
              <table className="click_table over_table">
                <colgroup>
                  <col style={{width: "44px"}}/>
                  <col style={{width: "auto"}}/>
                  <col style={{width: "90px"}}/>
                </colgroup>
                <thead>
                <tr>
                  <th colSpan={2}>
                    <div className="th-text">
                      Event
                    </div>
                  </th>
                  <th>
                    <div className="th-text">
                      {t("I18N_AST_GUIDE")}
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                {alarmList?.map(ele =>
                  <tr onClick={() => handleRowClick({alarmId: ele.alarmId, resolvedTime: ele.resolvedTime})}
                    className={(ele.resolvedTime || ele.confirmedBy) ? 'tr_disabled' : `${checkAlarmId.includes(ele.alarmId) ? 'tr_chk' : ''}`}
                    key={ele.alarmId}>
                    <td><StatusIcon icon={getDevStatClass(ele.status)} size={24} disabled={!!ele.resolvedTime}/>{getDevStatStr(ele.status)}</td>
                    <td>{ele.alarmDesc}<span>{ele.occuredTime}</span></td>
                    <td>
                      <a href={getGuideUrl(ele.guideUrl) || '#'} target={'_blank'} onClick={(e) => {
                        if (!ele.guideUrl) e.preventDefault();
                      }} style={{cursor: (ele.guideUrl ? 'pointer' : undefined)}} className={"details_btn"}>
                        <GuideIcon fill="#7B8389"/>
                      </a>
                    </td>
                  </tr>
                )}
                </tbody>
                {/*
                <tr className="tr_chk">
                      <td><StatusIcon icon="normal" size={24}/></td>
                      <td>Drive Over Heat<span>2023-08-02 16:37:00</span></td>
                      <td>
                          <Link to="#">
                          <GuideIcon fill="#7B8389"/>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td><StatusIcon icon="fault" size={24}/></td>
                        <td>Drive Over Heat<span>2023-08-02 16:37:00</span></td>
                        <td>
                          <Link to="#">
                            <GuideIcon fill="#7B8389"/>
                          </Link>
                        </td>
                </tr>
                */}
              </table>
            </div>
          </div>
          <div
            className="drag_resize"
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
          />
        </AssetTableWrap>
      </AlarmModalCont>
      <AssetOverlay className={"overlay on"} onClick={hide}></AssetOverlay>
    </AlarmModalPage>
  </>);
};

const AlarmModalPage = styled.div`
  position: fixed;
  top: 76px;
  left: 92px;
  width: calc(100% - 92px);
  height: calc(100vh - 76px);

  .overlay {
    position: absolute;
    left: 0 !important;
    z-index: 1;
  }

  @media screen and (max-width: 1199px) {
    position: absolute;
    width: 100%;
    left: 0;
    top: 110px;
    height: calc(100vh - 110px);
  }
`;
const AlarmModalCont = styled.div`
  background: #fff;
  position: relative;
  width: 100%;
  z-index: 2;

  &.open {
    animation: alarmAniDown 0.6s forwards;
  }

  &.off {
    animation: alarmAniUp 0.6s forwards;
  }

  @keyframes alarmAniDown {
    from {
      height: 0px;
    }

    to {
      height: 100%;
    }
  }

  @keyframes alarmAniUp {
    from {
      height: 100%;
    }

    to {
      height: 0;
    }
  }

  .tit_cont {
    height: 64px;

    .tit {
      display: flex;
      align-items: center;
      gap: 4px;

      a {
        height: 20px;
      }
    }

    > div {
      button + button {
        margin-left: 22px
      }
    }
  }

  .table_cont {
    height: calc(100% - 64px);
    opacity: 0;
    animation: fadein 0.2s 0.1s forwards;

    .table_box {
      height: 100%;

      tr.tr_disabled {
        cursor: default;

        a {
          cursor: default;
        }

        svg path {
          fill: #ABB0BF;
        }
      }

      th:last-child .th-text {
        padding-left: 20px;
        padding-right: 0;
      }

      td:last-child {
        text-align: center;
      }
    }

    .drag_resize {
      width: 144px;
      height: 8px;
      background: rgba(141, 141, 141, 0.7);
      border-radius: 30px;
      position: absolute;
      bottom: 10px;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
      cursor: row-resize;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1650px) {
    .table_scroll {
      overflow-x: auto;

      .table_box, .fixed-table-header-bg {
        min-width: 1650px;
      }

      .table_box {
        position: relative;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .tit_cont {
      padding: 0 18px;
    }

    .table_cont.mobile {
      th:last-child .th-text {
        padding-left: 20px;
      }

      td {
        &:first-child {
          padding-right: 0;
        }

        &:nth-child(2) {
          padding-left: 12px;
        }

        span {
          display: block;
          font-size: 1.2rem;
          color: #7B8389;
          margin-top: 4px;
        }
      }
    }

    .drag_resize {
      width: 108px !important;
      height: 6px !important;
    }
  }
`;
