export const PartnerDocStatus = Object.freeze({
  OPEN: "OPEN",
  PROCESSING: "PROCESSING",
  DELAYED: "DELAYED",
  CLOSED: "CLOSED",
});

export const getPartnerDocStatusLangKey = (value: string) => {
  if (value === PartnerDocStatus.OPEN) return 'I18N_DOCWRITE_PARTNER_DOC_STATUS_OPEN';
  if (value === PartnerDocStatus.PROCESSING) return 'I18N_DOCWRITE_PARTNER_DOC_STATUS_PROCESSING';
  if (value === PartnerDocStatus.DELAYED) return 'I18N_DOCWRITE_PARTNER_DOC_STATUS_DELAYED';
  if (value === PartnerDocStatus.CLOSED) return 'I18N_DOCWRITE_PARTNER_DOC_STATUS_CLOSED';
  return '';
};