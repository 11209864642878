export const InquiryType = Object.freeze({
  TECH: "TECH",
  SALES: "SALES",
  SERVICE: "SERVICE",
  ETC: "ETC"
});

export const getInquiryTypeLangKey = (value: string) => {
  if (value == '') return 'I18N_PARTNER_QNA_SELECT';
  if (value === InquiryType.TECH) return 'I18N_DOCWRITE_INQUIRY_TYPE_TECH';
  if (value === InquiryType.SALES) return 'I18N_DOCWRITE_INQUIRY_TYPE_SALES';
  if (value === InquiryType.SERVICE) return 'I18N_DOCWRITE_INQUIRY_TYPE_SERVICE';
  if (value === InquiryType.ETC) return 'I18N_DOCWRITE_INQUIRY_TYPE_ETC';
  return '';
};