// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {PartnerQnaRatingWrap} from "communitypage/partner/qna/partner-qna-popup-style";
import axios from "axios";
import {
  IPartnerQnaCustomerScoreEmail,
  ISsqDataCreatePartnerDoc,
  ISsqDataCreatePartnerDocLog,
  ISsqDataDocument
} from "repositories/SsqDataRepository";
import {docwriteutil} from "docwrite/community/docwriteutil";
import {LoginInfo} from "models/IfLoginInfo";
import {langutil} from "language/langutil";

type Props = {
  partnerDoc: ISsqDataCreatePartnerDoc;
  loginInfo: LoginInfo;
  partnerDocLog: ISsqDataCreatePartnerDocLog;
  handleCmd: (cmd: string | 'refresh-doc-detail', data?) => void,
  question: ISsqDataDocument
};
export const PartnerQnaRating = (props) => {
  const initialScore = props.partnerDoc.customerScore || 0;
  const [rating, setRating] = useState(initialScore);  // 현재 별점 상태
  const [ratingFixed, setRatingFixed] = useState(initialScore);  // 고정된 별점 상태
  const [doc, setDoc] = useState(props.partnerDoc);

  const handleLeftHalfEnter = (idx) => setRating(idx + 0.5);
  const handleRightHalfEnter = (idx) => setRating(idx + 1.0);

  const [emailManagers, setEmailManagers] = useState<string[]>([]);

  useEffect(() => {
    setDoc(props.partnerDoc);
  }, [props.partnerDoc]);

  // 별점을 클릭했을 때 처리하는 핸들러
  const handleStarClick = () => {
    setRatingFixed(rating);  // 현재 별점을 고정된 별점으로 설정
  };

  const saveCustomerScore = async () => {
    const formattedRating = rating % 1 === 0 ? `${rating}.0` : rating;
    const updateScore = {
      ...doc,
      customerScore: formattedRating
    };

    // 3. Fetch manager IDs and prepare email manager list
    const managerIds = (await axios.get('/api/guest/partner/manager/all/' +
      [doc.partnerId, doc.inquiryCategory, doc.inquiryType].join("/"))).data;
    const userIds = managerIds.map(manager => manager.userId);
    let newEmailManagers = [...userIds];
    if (!newEmailManagers.includes(doc.mngrUserId)) {
      newEmailManagers.push(doc.mngrUserId);
    }
    console.log('mnger', newEmailManagers);
    setEmailManagers(newEmailManagers);

    if (rating == 0) {
      return alert("별점을 등록해 주세요");
    } else if (rating == props.partnerDoc.customerScore) {
      return alert("별점을 수정해주세요");
    } else {
      // 별점 업데이트 API 호출
      axios.put('/api/guest/partner/doc/update/score', updateScore)
        .then(() => {
          savePartnerDocHistory(doc.docId, props.loginInfo.userId, updateScore)
            .then(() => alert("별점이 등록 되었습니다."));
          console.log(rating, emailManagers, newEmailManagers, "ratind");
          sendPartnerCustomerScoreMail(newEmailManagers);
          props.handleCmd('refresh-doc-detail');
        })
        .catch(error => console.error('Error updating score:', error));
    }
  };


  //별점 등록 시 전송 메일
  const sendPartnerCustomerScoreMail = async (emailManager) => {
    const emailReq: IPartnerQnaCustomerScoreEmail = {
      lang: props.question.exposeSiteLanguage,
      docTitle: props.question.docTitle,
      documentUrl: langutil.urlPrefix(props.question.exposeSiteLanguage) + `/partner/qna/document/${props.question.id}`,
      mngrId: emailManager,
      customerScore: rating
    };
    await axios.post(`/api/guest/sendPartnerQnaCustomerScore`, emailReq);
  };


  // 마우스가 별에서 벗어났을 때
  const handleStarLeave = () => {
    if (rating !== ratingFixed && props.question.writerId == props.loginInfo.userId) {
      setRating(ratingFixed);
    }
  };

  // DB에 별점 기록을 저장하는 비동기 함수
  const savePartnerDocHistory = async (docId, userId, doc) => {
    await docwriteutil.savePartnerLog(docId, userId, doc);
  };

  // 별점 컴포넌트
  function StarRating({score}) {
    return (
      <div className="rating-star">
        {Array(5)
          .fill(0)
          .map((i, idx) => (
            <p key={idx} onClick={props.question.writerId == props.loginInfo.userId && handleStarClick}>
              {score > idx && score < idx + 1 ? (
                <span className="half"></span>
              ) : score > idx ? (
                <span className="fill"></span>
              ) : (
                <span className="empty"></span>
              )}
              {/* 반쪽 별 */}
              <span
                key={idx + "left"}
                className="star-left"
                onMouseEnter={() => props.question.writerId == props.loginInfo.userId && handleLeftHalfEnter(idx)}
                onMouseLeave={handleStarLeave}></span>
              {/* 꽉 찬 별 */}
              <span
                key={idx + "right"}
                className="star-right"
                onMouseEnter={() => props.question.writerId == props.loginInfo.userId && handleRightHalfEnter(idx)}
                onMouseLeave={handleStarLeave}></span>
            </p>
          ))}
      </div>
    );
  }

  return (
    <PartnerQnaRatingWrap>
      <p className={"rating-num"}>
        {rating.toFixed(1)}
        <span>/5.0</span>
      </p>
      <StarRating score={rating}/>
      {props.question.writerId == props.loginInfo.userId && <button onClick={props.question.writerId == props.loginInfo.userId && saveCustomerScore}
        className={"edit-btn"}>저장
      </button>}
    </PartnerQnaRatingWrap>
  );
};