import styled from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import {EditorStyle} from "style/SsqEditorStyle";
import {SsqImages} from "images/SsqImages";
import img_icon_c_comment from "images/common/icon_comment.svg";

export const PartnerSolutionTop = styled.div`
  margin-bottom: 3rem;
  //padding: 4rem 4rem 0;

  .solution-tit-wrap {
    display: flex;
    justify-content: space-between;
  }

  .solution-info {
    display: flex;
    font-size: 1.2rem;
    color: #7783A1;
    margin-top: .8rem;
    ${fontFamilyRegular};


    span + span:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 15px;
      background: #C7D6EA;
      vertical-align: middle;
      margin: 0 4px;
    }

    span b {
      color: #407EDB;
      font-weight: inherit !important;
      margin-left: .4rem;
    }
  }

  .solution-tit {
    font-size: 2.5rem;
    ${fontFamilyBold};
    width: 95%;
    line-height: 1.2;

    .level {
      display: inline-block;
      background: #7783A1;
      color: #fff;
      border-radius: 16px;
      width: 4.8rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 9px;
      text-align: center;
      margin: .5rem 0 .5rem 0rem;
      vertical-align: top;
    }
  }

  button + button {
    margin-left: .8rem
  }

  .solution-btn button {
    height: 3rem;

    img {
      vertical-align: middle
    }

    :hover {
      background: rgba(64, 125, 217, 0.1);
    }

    :active {
      background: rgba(64, 126, 219, 0.3);
    }
  }

  @media screen and (max-width: 764px) {
    position: relative;
    padding: .2rem 0 0 0 !important;
    .solution-tit {
      width: 100%
    }

    .solution-btn {
      position: absolute;
      right: 16px;
      bottom: -5px;
    }
  }
`;
export const PartnerSolutionResult = styled.div`
  ${EditorStyle};

  &:before {
    display: block;
    content: '';
    width: calc(100% + 80px);
    background: #EEF1F6;
    height: 1px;
    margin: 16px 0 18px -40px;
  }

  .SsqEditor {
    padding-bottom: 4rem;
    overflow-x: auto;
  }

  .download {
    border-top: 1px dashed #90A0B6;

    > div:last-child {
      padding: 0 4rem 4rem;
    }

    .down_tit {
      font-size: 1.8rem;
      ${fontFamilyBold};
      margin-bottom: 2.4rem;
      padding: 4rem 4rem 0;
    }

    .accordion-down > div {
      display: inline-block;
      font-size: 1.2rem;
    }

    .down-title {
      margin-right: .7rem;

      > a {
        color: #407EDB;
        font-size: 1.6rem;
        //font-size: 1.3rem !important;
      }
    }

    .down-icon {
      vertical-align: middle;
      margin-right: .7rem;
    }

    .down-info {
      color: #7783A1;

      span {
        ${fontFamilyRegular}
      }
    }

    .down-info span:after {
      content: '';
      width: .1rem;
      height: 1.5rem;
      background: #C7D6EA;
      margin: 0 .5rem;
      display: inline-block;
      vertical-align: middle;
    }

    .down-info span:last-child:after {
      display: none;
    }

    .down-info span b {
      color: #407EDB;
      font-weight: normal !important;
      margin-left: .5rem;
    }
  }

  .Result-bt {
    padding: 2.4rem 0;
    background: #F7F8FB;
    border-top: solid 1px #C7D6EA;
    display: flex;
    justify-content: space-between;

    > div {
      width: 50%;
      padding: 0 4rem
    }

    > div + div {
      border-left: solid 1px #C7D6EA
    }
  }

  @media screen and (max-width: 764px) {
    .SsqEditor {
      padding: 0 16px 16px 0;
    }

    .download {
      .down_tit {
        padding: 16px 16px 0 !important;
        margin-bottom: 16px !important;
      }

      > div:last-child {
        padding: 0 16px 16px !important;
      }
    }

    .Result-bt {
      padding: 0;
      display: block;

      > div {
        width: 100%;
        padding: 24px 16px;
      }

      > div + div {
        border-top: solid 1px #C7D6EA;
        border-left: none;
      }
    }
  }
`;

export const SolutionRelated = styled.div`
  + div {
    margin-top: 1.6rem;
  }

  .related_tit {
    font-size: 1.2rem;

    img {
      vertical-align: middle;
    }
  }

  .related_list {
    color: #407DD8;
    font-size: 1.2rem;

    > div {
      display: inline-block;
      line-height: 2.4rem;

      span {
        cursor: pointer;
      }

      .comma {
        color: #90A0B6;
        margin-right: .4rem;
      }
    }
  }

  .related_list.one-line > div {
    display: block;
  }

`;

export const PartnerQnaPagePopupTop = styled.div`
  .review-input.modify {
    padding: 2.2rem 2.4rem !important;
  }

  .view-header {
    width: 100%;
    border-bottom: .1rem solid #C7D6EA;
    margin-bottom: 3.5rem;
    padding: 0 1.6rem;
    position: relative;
  }

  .view-header-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    //overflow-x: auto;
    height: 6.4rem;
  }

  .view-header-item::-webkit-scrollbar {
    display: none;
  }

  .v-header-left {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .v-header-right {
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
  }

  .qna-check {
    margin-right: 1.2rem;
    display: inline-flex;
  }

  .qna-check .icon-qna {
    width: 40px;
    height: 40px;
  }

  .qna-check .icon-qna.red-a {
    background: url("${SsqImages.community.icon.communityQnaBigA()}") no-repeat center;
  }

  .qna-check .icon-qna.q {
    background: url("${SsqImages.community.icon.communityQnaQ()}") no-repeat center;
  }

  .qna-check .icon-qna.a {
    background: url(${SsqImages.community.icon.communityQnaCheck()}) no-repeat;
  }

  .qna-check .icon-qna.a > span {
    color: #34A597;
    ${fontFamilyMedium};
    font-size: 1rem;
    padding: 0 0.2rem;
    display: inline;
  }

  .btn-list-modify {
    width: 2rem;
    height: 2rem;
    background-image: url("${SsqImages.community.icon.communitySpotMore()}");
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: block;
  }

  .modify-item-body {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    width: 80px;
    position: absolute;
    right: 0.8rem;
    top: 4.8rem;
    z-index: 2;
    display: none;
  }

  .modify-btn {
    color: #2A2C2F;
    ${fontFamilyMedium};
    font-size: 12px;
    width: 80px;
    height: 48px;
    line-height: 48px;
  }

  .modify-btn:first-child {
    border-radius: 4px 4px 0 0;
    background: #fff;
  }

  .modify-btn:last-child {
    border-radius: 0 0 4px 4px;
    border-top: 1px solid #C7D6EA;
    background: #fff;
  }

  .modify-btn.notify {
    border-radius: .4rem;
    border: none;
  }

  .post-button.send {
    background: #407EDB url("${SsqImages.community.icon.communityBtnSend()}") no-repeat 1.3rem center;
    width: auto;
    padding: 0 2rem 0 4rem;
    margin-left: 1.2rem;

    :hover {
      background: #3B71C4 url("${SsqImages.community.icon.communityBtnSend()}") no-repeat 1.3rem center;
    }
  }

  .delete-button {
    width: auto
  }

  .batch-button {
    color: #7783A1 !important;
    width: auto !important;
    padding: 0 2rem;
  }

  .batch-button.white {
    color: #fff !important;
  }


  @media screen and (max-width: 764px) {

    .modify-item-body {
      width: 90%;
      position: fixed;
      left: 50%;
      top: 50%;
      max-width: 20rem;
      transform: translate(-50%, -50%);
      z-index: 101;
    }

    .modify-btn {
      width: 100%;
      max-width: 20rem;
    }

    .mobile-black {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
      background: rgba(0, 0, 0, .5);
      display: none;
    }

  }

`;

//제목 상단의 태그 css
export const PartnerQnaPagePopupTitleTag = styled.div`

  .view-content .view-content-tag ul li {
    ${fontFamilyRegular};
    font-size: 1.2rem;
    color: #7783A1;
    display: inline-block;
    margin-right: 1.2rem;
    margin-bottom: 1rem;
  }

  .view-content .view-content-tag ul > li.type {
    margin-right: 1.8rem;
  }

  .view-content .view-content-tag ul > li.doc-type {
    font-size: 1rem;
    margin-right: 1.6rem;
    ${fontFamilyBold};
    width: 54px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 100px;
    background: #407EDB;
    color: #fff;

    &.proc {
      background: #34A597;
    }

    &.close {
      background: #7783A1;
    }
  }
`;

export const PartnerQnaPagePopupMain = styled.div`
  .community-view {
    width: 100%;
    height: 100%;
    min-height: 90rem;
    background: rgb(238, 241, 246);
    position: relative;
  }

  .view-container {
    padding: 0 0 4rem;
  }

  .community__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 40px
  }

  .community__content div > .content {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
    border-radius: 4px;
    width: 84rem;
    //min-height: 40rem;
    margin-bottom: 1.6rem;
  }

  .community__content div > .content.write {
    margin-bottom: 0
  }

  .community__content div > .content.write .cke_chrome {
    border: none;
  }

  community__content .content.write .cke_chrome .cke_top {
    border: none;
    background: #F5F8FD !important;
  }

  ul.comment-box.solution-profile {
    display: flex;
    margin-top: 2rem;
    display: none;
  }

  ul.comment-box.solution-profile.qna-view {
    margin-top: -2rem;
    margin-bottom: 2rem;
  }

  ul.comment-box {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* margin-top: 1rem; */
  }

  ul.comment-box > li {
    margin-right: .6rem;
    font-size: 1.2rem;
    color: #7783A1;
    ${fontFamilyMedium};
    flex-shrink: 0;
    line-height: 2;
    position: relative;
  }

  .v-header-right ul.comment-box > li {
    margin-right: 1.6rem;
  }

  .v-header-left ul.comment-box > li {
    margin-right: .6rem;
  }

  ul.comment-box .c-txt {
    height: 1.8rem;
    line-height: 1.8rem;
    padding: 0 1rem;
    font-size: 1rem;
    border-radius: 2rem;
  }

  ul.comment-box .c-name {
    color: #2A2C2F;
    font-size: 1.3rem;
  }

  ul.comment-box .c-name span:first-child {
    width: 3.2rem;
    height: 3.2rem;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #F7B91B;
    border-radius: 100%;
    overflow: hidden;
    margin-right: .4rem;
    font-size: 1.6rem;
    line-height: 3rem;
  }

  ul.comment-box .c-name span:last-child {
    vertical-align: middle;
    line-height: 3.2rem;
  }

  ul.comment-box .c-name span img {
    width: 100%;
    height: 100%;
  }

  ul.comment-box .c-name span.unknown {
    border: none;
  }

  ul.comment-box .c-time {
    background-image: url("${SsqImages.community.icon.communityTime()}");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1.6rem;
    padding-left: 1.8rem;
    ${fontFamilyRegular};
  }

  ul.comment-box .c-comment {
    background: url(${img_icon_c_comment}) no-repeat left center;
    padding-left: 1.8rem;
    color: #407EDB;
  }

  ul.comment-box .c-view {
    color: #407EDB;
    background-image: url("${SsqImages.community.icon.communityView()}");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1.6rem;
    padding-left: 1.8rem;
    ${fontFamilyRegular};
  }

  ul.comment-box .c-good {
    color: #407EDB;
    padding-left: 1.8rem;
    background-image: url("${SsqImages.community.icon.communityGood()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .c-not-bad {
    color: #FF215D;
    padding-left: 1.8rem;
    background-image: url("${SsqImages.community.icon.communityNotBad()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .finish-button {
    min-width: 96px;
    width: auto;
    height: 40px;
    background-color: #7783A1;
    color: #fff;
    margin-right: 1rem;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 1rem;

    img {
      margin: auto;
    }

    div {
      margin: auto;
    }
  }

  ul.comment-box .finish-button.on {
    width: auto;
    height: 40px;
    background-color: #407EDB;
    color: #fff;
    margin-right: 1rem;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 1rem;

    img {
      margin: auto;
    }

    div {
      margin: auto;
    }
  }

  ul.comment-box .view-like {
    height: 2.4rem;
    line-height: 2.4rem;
    background-image: url("${SsqImages.community.icon.communityGood24()}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 2.8rem;
    color: #407EDA;
    ${fontFamilyRegular};
  }

  ul.comment-box .view-like.on {
    background-image: url("${SsqImages.community.icon.communityGoodOn24()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .view-bad {
    height: 2.4rem;
    line-height: 2.4rem;
    background-image: url("${SsqImages.community.icon.communityNotBad24()}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    padding-left: 2.8rem;
    color: #FF215D;
    ${fontFamilyRegular};
  }

  ul.comment-box .view-bad.on {
    background-image: url("${SsqImages.community.icon.communityNotBadOn24()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .view-bookmark {
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    background-image: url("${SsqImages.community.icon.communityBookmark()}");
    background-repeat: no-repeat;
    background-position: center;
    display: block;
  }

  ul.comment-box .view-bookmark.on {
    background-image: url("${SsqImages.community.icon.communityBookmarkOn()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .view-share {
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    background-image: url("${SsqImages.common.icon.shareImg()}");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .editor_list {
    padding: 0 0 2.8rem;

    .list_empty {
      font-size: 1.2rem;
      color: #7783A1;

      img {
        vertical-align: middle;
        margin-right: .8rem;
      }

      .list {
        ${fontFamilyRegular};
        margin: 4px;
        padding-left: 12px;
        text-indent: -12px;
        line-height: 1.5;
      }

      .title {
        display: inline-block;
        padding-bottom: 10px;
      }

      .em {
        color: #407EDB;
      }
    }

  }

  aside.community-view-aside {
    //width: 33%;
    padding-top: 32px;
  }

  .aside-content {
    width: 32rem;
    overflow-y: auto;
    padding-bottom: 3rem;
  }

  .community__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .commu_bottom_cont {
      display: flex;
      width: calc(100% / 1);
      justify-content: flex-start;

      > div {
        width: 100%;
      }
    }
  }

  .view-content {
    padding: 0 4rem;
  }


  .view-footer {
    border-top: 1px dashed #ddd;
  }

  .comment-info-wrap {
    display: none;
  }

  @media screen and (max-width: 1199px) {

    .view-container {
      padding-top: 0;
    }

    .SsqEditor img {
      height: auto;
      margin: 35px 0px;
      max-width: 100% !important;
    }

    .aside-content {
      position: relative;
      height: auto;
      width: 100%;
      overflow-y: auto;
      padding: 0 3rem;

      > div {
        display: flex;
        justify-content: end;
      }
    }

    aside.community-view-aside {
      width: 100%;
    }

    aside .solution-list {
      display: none;
    }

    .community__content > .content {
      width: 100%;
    }

    .community__content {
      flex-wrap: wrap;
    }

    .community__content > div {
      width: 100%;
    }

    .community__content div > .content {
      width: 100%;
    }

    .review-input.on {
      padding: 2.2rem 2.4rem !important;
    }

  }
}

@media screen and (max-width: 764px) {

  .v-header-left ul.comment-box {
    display: none;
  }

  .comment-info-wrap {
    margin-top: 2rem;
    display: block !important;

    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .community__content {
    .commu_bottom_cont {
      flex-direction: column;
    }
  }

  .view-outer {
    border-bottom: 1px solid #C7D6EA;
    padding: 0 3rem 3rem !important;
  }

  .aside-content {
    margin-top: 0;
  }

  .aside-content {
    .view-content {
      padding: 0 1.6rem;
    }

    .community__content > div {
      width: 100%;
    }

    .community__content div > .content {
      width: 100%;
    }

    .SsqEditor img {
      height: auto;
      margin: 35px 0px;
      /* max-width: 100% !important; */
    }

    .SsqEditor {
      overflow-x: auto;
    }

    .SsqEditor table {
      max-width: 100%;
      table-layout: fixed;
      word-break: break-word;
      width: 100% !important;
    }

    .v-header-left {
      margin-right: 4rem;
    }

    .content.write .v-header-left {
      margin-right: .4rem;
    }

    .view-content .view-content-tag {
      padding: 0 1.6rem
    }

    .v-header-left .comment-box {
      display: none;
    }

    .v-header-left .qna-type-info {
      color: #7783A1;
      font-size: 1.2rem;
      line-height: 1.8rem;
      padding-left: 1.6rem;
      display: block;
    }


    .view-content .view-content-tag ul li.blog-type {
      display: none
    }

    ul.comment-box.solution-profile {
      display: flex;
      flex-wrap: wrap;
    }

    .v-header-left .comment-box.qna-write {
      display: flex;
      @media screen and (max-width: 764px) {
        display: none;
      }
    }

    .qna-check-header {
      margin-left: 1.6rem
    }
  }
`;

export const QnaPageFooter = styled.div`
  .content-download {
    padding: 4rem
  }

  .content-download > p.title {
    color: #2A2C2F;
    font-size: 1.8rem;
    ${fontFamilyBold};
  }

  .content-download > ul.down-list {
    //margin-top: 2.4rem;
  }

  .content-download > ul.down-list .down-icon {
    position: relative;
  }

  .content-download > ul.down-list > li {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .content-download > ul.down-list li button.down-title {
    color: #407EDB;
    ${fontFamilyMedium};
    font-size: 1.3rem;
    padding-right: 0.7rem;
      //background-image: url("${SsqImages.common.icon.downloadImg}");
    background-repeat: no-repeat;
    background-position: right center;
  }

  .content-download > ul.down-list li ul.file-info {
    display: flex;
    margin-left: .4rem;
  }

  .content-download > ul.down-list li ul.file-info li {
    ${fontFamilyMedium};
    font-size: 1.2rem;
    color: #7783A1;
    border-right: 1px solid #C7D6EA;
    padding: 0 .6rem;
  }

  .content-download > ul.down-list li ul.file-info li:last-child {
    border: none;
  }

  .content-download > ul.down-list li ul.file-info li > span.count {
    color: #407EDB;
    margin-left: .4rem;
  }

  .down-title {
    margin-right: .7rem;

    > a {
      color: #407EDB;
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 764px) {
    .content-download > ul.down-list li ul.file-info {
      margin-left: -0.6rem;
      margin-top: .4rem;
    }
  }

  .review-content {
    background: #F7F8FB;
  }

  .review-content:first-child {
    border-top: .1rem solid #EEF1F6;
  }

  .review-content-item {
    display: flex;
    flex-wrap: nowrap;
    padding: 1rem 2.4rem;
  }

  .review-content-item > .left {
    flex-shrink: 0;
  }

  .review-content-item > .right {
    padding-top: 0.6rem;
  }

  .review-content-item > .right > ul.comment-box {
    display: inline-block;
  }

  .review-content-item > .right > ul.comment-box > li {
    display: inline-block;
  }

  .comment-content {
    margin-right: 1rem;
    ${fontFamilyRegular};
    font-size: 1.3rem;
    color: #2A2C2F;
    line-height: 2.4rem;
    white-space: pre-wrap;
  }

  .review-content-item .right .comment-content {
    order: 1;
    width: 100%;
    margin-right: 0;
  }

  .review-content-item > .right > .list-modify-body {
    position: relative;
  }

  .review-content-item ul.comment-box > li {
    margin-right: .6rem;
  }

  .right > .list-modify-body > .modify-item-body {
    top: 2.8rem;
    display: none;
  }

  @media screen and (max-width: 764px) {
    .right > .list-modify-body > .modify-item-body {
      top: 50%;
    }

    .review-content-item .right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .review-content-item .right .comment-box {
      order: 0;
    }

    .review-content-item .right .comment-content {
      order: 1;
      width: 100%;
      margin-right: 0;
    }

    .review-input.on {
      padding: 2.2rem 2.4rem !important;
    }

    .review-input > textarea {
      ${fontFamilyRegular};
      font-size: 16px;

      transform: scale(0.81);
      transform-origin: left;
    }

    .review-input > textarea::placeholder {
      color: #90A0B6;
      font-size: 1.7rem;
    }
  }

  .review-content-item .right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .review-input {
    padding: 0 0 0 2.4rem;
    display: flex;
    align-items: center;
    border-top: 1px solid #EEF1F6;
    background: #fff;
  }

  .review-input > textarea {
    width: calc(100% - 5rem);
    margin-right: 1rem;
    ${fontFamilyRegular};

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .review-input > textarea::placeholder {
    color: #90A0B6;
  }

  button.ent {
    width: 6.4rem;
    height: 6.4rem;
    background-position: 50%;
    background-image: url("${SsqImages.boris.CommentsBtn()}");
    background-repeat: no-repeat;
    align-self: flex-end;
  }

  button.review-del {
    width: 2.4rem;
    height: 2.4rem;
    background: url("${SsqImages.community.icon.communityReviewDel()}") no-repeat;
    margin-right: 1.6rem;
    align-self: flex-end;
  }

  button.review-enter {
    width: 2.4rem;
    height: 2.4rem;
    background: url("${SsqImages.community.icon.communityReviewEnter()}") no-repeat;
    flex-shrink: 0;
    align-self: flex-end;
  }
`;

export const PartnerSolutionInfo = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  gap: 24px;

  p {
    display: inline-flex;
    align-items: center;
    font-size: 1.2rem;
    color: #7783A1;

    span {
      color: #000;

      &:before {
        display: inline-block;
        content: '';
        background: #EEF1F6;
        width: 1px;
        height: 12px;
        margin: 0 4px;
      }
    }
  }
`;

export const PartnerQuestionProcessWrap = styled.div`
  color: #fff;
  margin-bottom: 16px;

  .info-wrap {
    position: relative;
    background: #434253;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    > div {
      display: flex;
      align-items: center;
    }

    .profile {
      gap: 10px;

      .header-profile span {
        width: 3.2rem;
        height: 3.2rem;
        font-size: 1.6rem;
        line-height: 3rem;
      }

      p {
        font-size: 1.3rem;
        color: #7783A1;
        line-height: 16px;

        &.name {
          color: #fff;
          font-size: 1.4rem;
          ${fontFamilyBold};
          margin-top: 4px;
        }
      }
    }

    .process {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      gap: 110px;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: max-content;

        + div:before {
          display: inline-block;
          content: '';
          width: 130px;
          height: 6px;
          position: absolute;
          left: -125px;
          top: 7px;
          background: #323044;
          border: solid 1px #2A2C2F;
          z-index: 1;
        }

        &:nth-child(2) {
          align-items: center;
        }

        &:last-child {
          align-items: flex-end
        }

        &.now, &.complete {
          .num {
            background: #5691EB;
            color: #fff;
            border: none;

            &:before {
              display: none;
            }
          }
        }

        &.now {
          p.tit {
            color: #5691EB;
            ${fontFamilyBold}
          }

          &:before {
            background: #5691EB;
            border: none;
          }
        }

        &.complete {
          .num {
            background: #5691EB url(${SsqImages.community.icon.communityBoardCheck()}) center center no-repeat;
            background-size: 10px;
            font-size: 0;
          }

          + .complete:before {
            background: #5691EB;
            border: none;
          }
        }
      }

      .num {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        font-size: 1.1rem;
        color: #7783A1;
        background: #323044;
        border: solid 1px #2A2C2F;
        z-index: 2;

        span {
          position: relative;
          z-index: 2;
        }

        :before {
          display: inline-block;
          content: '';
          width: 25px;
          height: 6px;
          background: #323044;
          z-index: 1;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }

      p.tit {
        font-size: 1.5rem;
        line-height: 16px;
        color: #7783A1;
        margin-top: 8px;
      }
    }

    .right {
      gap: 13px;

      .complete-date {
        text-align: right;

        p {
          font-size: 1.3rem;
          color: #7783A1;
          line-height: 16px;
        }

        > .date {
          display: inline-flex;
          align-items: center;
          gap: 4px;
          color: #fff;
          font-size: 1.5rem;
          margin-top: 4px;
          flex-wrap: wrap;
          justify-content: end;

          > .d-flex {
            display: inline-flex;
            align-items: center;
            gap: 4px;

            .dday-label {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 0 9px;
              min-width: 34px;
              height: 16px;
              border-radius: 4px;
              background: rgba(52, 165, 151, 0.3);
              color: #34A597;
              font-size: 1rem;
              ${fontFamilyBold};

              &.delayed {
                background: rgba(255, 0, 69, 0.3);
                color: #FF0045
              }
            }
          }
        }
      }

      .rating {
        text-align: right;
        display: block;

        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
        }
      }

      .edit-btn {
        background: #7783A1;
        border: solid 1px #323044;
        width: 60px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 1.2rem;
        ${fontFamilyBold};
        color: #fff;
      }
    }
  }

  .step-wrap {
    min-height: 48px;
    background: #323044;
    border-radius: 0 0 4px 4px;
    padding: 12px 24px 17px;
    font-size: 1.2rem;
    line-height: 24px;
    ${fontFamilyRegular};
    height: 140px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 14px;

      &-thumb {
        background-color: #646975;
        background-clip: padding-box;
        border: 5px solid transparent;
        border-radius: 10px;
      }
    }

    > div {
      display: flex;

      &.complete {
        color: #7783A1
      }
    }

    span {
      ${fontFamilyRegular};
      margin-left: 4px;
      font-size: 1rem;
      font-variant-numeric: tabular-nums;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (max-width: 1199px) {
    .info-wrap {
      padding-top: 100px;
      height: auto;
      padding-bottom: 22px;
      min-height: 160px;

      .process {
        top: 32px;
        gap: 100px;

        > div + div:before {
          left: -120px;
        }
      }

      .right {
        .edit-btn {
          background: #7783A1 url(${SsqImages.community.icon.writeIconWhite()}) center center no-repeat;
          background-size: 16px;
          font-size: 0;
          width: 32px;
          min-width: 32px;
          height: 32px;
        }
      }
    }

    .step-wrap {
      height: 135px;

      > div {
        justify-content: space-between;
        gap: 4px;
        line-height: initial;

        + div {
          margin-top: 10px;
        }

        p {
          width: calc(100% - 95px);
          word-break: keep-all;
        }

        span {
          width: 95px;
          word-break: break-all;
        }
      }
    }
  }
  @media screen and (max-width: 300px) {
    .info-wrap {
      gap: 8px;
    }

    .complete-date .date {
      flex-direction: column;
    }
  }
`;
export const PartnerQnaRatingWrap = styled.div`
  margin-top: 1px;

  > div {
    display: flex;
    align-items: center;
  }

  .rating-num {
    font-size: 2rem;
    line-height: 20px;

    span {
      color: #7783A1;
      font-size: 1.4rem;
      line-height: 14px;
      margin-left: 1px;
      ${fontFamilyRegular}
    }
  }

  .rating-star {
    p {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    span {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 16px;
      height: 16px;

      &.empty {
        background: url(${SsqImages.community.icon.rating()}) left center no-repeat;
      }

      &.fill {
        background: url(${SsqImages.community.icon.ratingFill()}) left center no-repeat;
      }

      &.half {
        background: url(${SsqImages.community.icon.ratingHalf()}) left center no-repeat;
      }
    }

    span.star-left, span.star-right {
      position: absolute;
      width: 50%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }

    span.star-right {
      left: auto;
      right: 0;
    }
  }

`;