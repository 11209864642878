import {resi18nutil} from 'language/resi18nutil';

// I18N_AST_ 를 asset language prefix 로 사용한다.
const resi18n_asset = {

  // 번역이 필요한 부분 정리
  I18N_AST_ALARM_CONFIRM: ['선택 알람 확인', 'Confirm', undefined],
  I18N_AST_ALL_CONFIRM: ['전체 알람 확인', 'All Confirm', undefined],
  I18N_AST_CONFIRM: ['확인', 'Confirm', undefined],
  I18N_AST_NODATA: ['데이터가 없습니다.', 'No Data Available', undefined],

  I18N_AST_MONITOR_REQUIRED_VALUE: ['필수 값 입니다.', 'Required', undefined],

  I18N_AST_UPDATE_COMPLETE: ['수정 완료 되었습니다.', 'EN_수정 완료되었습니다.', undefined],
  I18N_AST_UPDATE_FAIL: ['수정 실패하였습니다.', 'EN_수정 실패하였습니다.', undefined],
  I18N_AST_DISABLE: ['더 이상 기기가 보이지 않습니다.', 'EN_더이상 기기가 보이지 않습니다.', undefined],
  I18N_AST_SAVE_COMPLETE: ['저장하였습니다', 'EN_저장하였습니다', undefined],
  I18N_AST_FAIL: ['실패하였습니다', 'EN_실패하였습니다', undefined],

  // 맵 박스 관련
  I18N_AST_MAPBOX_SELECT: ["Map 위치 선택", "EN_Map 위치 선택", undefined],
  I18N_AST_MAPBOX_LAT_LON: ["위도 , 경도", "EN_위도 , 경도", undefined],

  // 파라미터 페이지
  I18N_AST_PARAMETER_DELETE_TITLE: ['파라미터 파일 삭제', 'EN_파라미터 파일 삭제', undefined],
  I18N_AST_PARAMETER_DELETE_MSG: ['삭제 하시겠습니까?', 'Do you want to delete?', undefined],
  I18N_AST_PARAMETER_SAVE_TITLE: ['파라미터 저장', 'Parameter Save', undefined],
  I18N_AST_PARAMETER_SAVE_MSG: ['파라미터를 저장하시겠습니까', 'EN_파라미터를 저장하시겠습니까', undefined],
  I18N_AST_PARAMETER_DOWN_TITLE: ['파라미터 다운로드', 'Parameter Download', undefined],
  I18N_AST_PARAMETER_DOWN_MSG: ['파라미터를 다운로드 하시겠습니까', 'EN_파라미터를 다운로드 하시겠습니까', undefined],
  I18N_AST_PARAMETER_FILE: ['파라미터 파일', 'Parameter File', undefined],
  I18N_AST_PARAMETER_FILE_MO: ['파일', 'File', undefined],
  I18N_AST_PARAMETER_GROUP: ['구분', 'Group', undefined],
  I18N_AST_PARAMETER_VALUE: ['값', 'Value', undefined],
  I18N_AST_PARAMETER_UNIT: ['단위', 'Unit', undefined],
  I18N_AST_PARAMETER_RANGE: ['범위', 'Range', undefined],
  I18N_AST_PARAMETER_DEFAULT: ['초기값', 'Default', undefined],
  I18N_AST_PARAMETER_SCALE: ['단계', 'Scale', undefined],
  I18N_AST_PARAMETER_FILE_NAME: ['파일명', 'File Name', undefined],
  I18N_AST_PARAMETER_FILE_TYPE: ['구분', 'File Type', undefined],
  I18N_AST_PARAMETER_ASSET: ['기기', 'Asset', undefined],
  I18N_AST_PARAMETER_DETAILS: ['상세', 'Details', undefined],
  I18N_AST_PARAMETER_ERROR_MSG: ['kr_Error Occurred, Try Again.', 'Error Occurred, Try Again.', undefined],
  I18N_AST_PARAMETER_WAIT_MSG: ['데이터를 불러오는 데 수 분이 걸릴 수 있습니다.<br/>데이터를 보시려면 버튼을 눌러주세요.', 'This will take a few minutes.<br/>Do you want to read the parameters?', undefined],
  I18N_AST_PARAMETER_READ_BTN: ['Parameter 보기', 'Read', undefined],

  // 툴팁 메세지 관련
  I18N_AST_TOOLTIP_CONNECTION: ['연결이 해제된 후 설정된 시간이 지나면\n알람이 발생합니다. ', 'An alarm occurs after a set amount of time has passed since the disconnection. ', undefined],
  I18N_AST_TOOLTIP_UN_AUTH_ASSET: ['KN_Unauthenticated Asset', "Unauthenticated Asset", undefined],

  // QR 기기등록 씬
  // 미등록 기기 팝업
  I18N_AST_AUTH_NOT_REGISTER_TITLE: ['미등록 기기', 'EN_미등록 기기', undefined],
  I18N_AST_AUTH_NOT_REGISTER_CONTENT_TOP: ['이 기기의 소유자 정보가 없습니다. 소유자로 등록하기 위해서는 Solution Square 계정의 전화번호와 Drive의 소유자 휴대폰번호가 일치해야 합니다.', 'EN_이 기기의 소유자 정보가 없습니다. 소유자로 등록하기 위해서는 Solution Square 계정의 전화번호와 Drive의 소유자 휴대폰번호가 일치해야 합니다.', undefined],
  I18N_AST_AUTH_NOT_REGISTER_CONTENT_BOTTOM: ['에서 유지보수 > 소유자정보 > 휴대폰번호에 인증용 휴대폰번호를 입력해주세요.', 'EN_에서 유지보수 > 소유자정보 > 휴대폰번호에 인증용 휴대폰번호를 입력해주세요.', undefined],
  I18N_AST_AUTH_NOT_REGISTER_CONTENT_CONNECT_INTERNET: ['인터넷이 연결되어 있어야 합니다.', 'EN_인터넷이 연결되어 있어야 합니다.', undefined],

  // 소유주에게 권한 요청 팝업
  I18N_AST_AUTH_REQUEST_TO_OWNER_TITLE: ['사용 권한 요청', 'EN_사용 권한 요청', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER_CONTENT_TOP: ['해당 기기는 아래 휴대폰번호의 소유자에게 이미 등록되어 있는 기기 입니다. 소유자에게 사용 권한을 요청하시겠습니까?', 'EN_해당 기기는 아래 휴대폰번호의 소유자에게 이미 등록되어 있는 기기 입니다. 소유자에게 사용 권한을 요청하시겠습니까?', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER_CONTENT_BOTTOM_LEFT: ['기기 소유자 인데, 휴대폰번호가 변경되었다면,', 'EN_기기 소유자 인데, 휴대폰번호가 변경되었다면,', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER_CONTENT_BOTTOM_RIGHT: ['에서 변경된 휴대폰번호를 입력해주세요.', '에서 변경된 휴대폰번호를 입력해주세요.', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER: ['인증 요청하기', 'EN_인증 요청하기', undefined],

  I18N_AST_OWNER_ERR_TITLE: ["소유자 권한 오류", "EN_소유자 권한 오류", undefined],

  I18N_AST_OWNER_ERR_MSG1: ['소유자의 권한에 문제가 있습니다.', 'EN_소유자의 권한에 문제가 있습니다.', undefined],
  I18N_AST_OWNER_ERR_MSG2: ['소유자가 권한문제를 해제한 후에 해당 기기를 공유받을 수 있습니다.', 'EN_소유자가 권한문제를 해제한 후에 해당 기기를 공유받을 수 있습니다.', undefined],

  // 사용자 인증 팝업
  I18N_AST_USER_AUTH: ['사용자 인증', 'EN_사용자 인증', undefined],
  I18N_AST_USER_AUTH_CONTENT: ['휴대폰번호를 확인한 후 \'인증하기\' 버튼을 클릭해 주세요. 인증 SMS가 발송됩니다. 휴대폰번호가 맞지 않으면 계정 설정을 변경해 주세요.', 'EN_휴대폰번호를 확인한 후 \'인증하기\' 버튼을 클릭해 주세요. 인증 SMS가 발송됩니다. 휴대폰번호가 맞지 않으면 계정 설정을 변경해 주세요.', undefined],
  I18N_AST_AUTH: ['인증하기', 'EN_인증하기', undefined],
  I18N_AST_AUTH_CHANGE_ACCOUNT: ['계정 설정 변경하기', 'EN_계정 설정 변경하기', undefined],

  // 인증 완료 팝업
  I18N_AST_AUTH_SUCCESS: ['인증 완료', 'EN_인증 완료', undefined],
  I18N_AST_AUTH_SUCCESS_MSG: ['사용자 인증이 완료되었습니다.', 'EN_사용자 인증이 완료되었습니다.', undefined],

  // 사용자, 관리자 문자 보내는 팝업
  I18N_AST_SMS_TO_OWNER: ['권한 인증', 'EN_권한 인증', undefined],
  I18N_AST_SMS_TO_OWNER_MSG: ['소유자에게 인증 SMS가 발송되었습니다. 소유자에게 발송된 SMS의 인증 번호를 전달 받아, 입력한 후 인증 확인 버튼을 눌러주세요', 'EN_소유자에게 인증 SMS가 발송되었습니다. 소유자에게 발송된 SMS의 인증 번호를 전달 받아, 입력한 후 인증 확인 버튼을 눌러주세요', undefined],
  I18N_AST_SMS_TO_USER: ['사용자 인증', 'EN_사용자 인증', undefined],
  I18N_AST_SMS_TO_USER_MSG: ['인증 SMS가 발송되었습니다. 발송된 문자의 인증 번호를 입력한 후 인증 확인 버튼을 눌러주세요.', 'EN_인증 SMS가 발송되었습니다. 발송된 문자의 인증 번호를 입력한 후 인증 확인 버튼을 눌러주세요.', undefined],
  I18N_AST_REQUEST_SMS: ['인증 문자 재발송', 'EN_인증 문자 재발송', undefined],
  I18N_AST_AUTH_OK: ['인증 확인', 'EN_인증 확인', undefined],

  // alert 창 문구 모음
  I18N_AST_AST_IS_ACTIVATED: ['KN_Asset is activated', 'Asset is activated', undefined],
  // 문자 인증 시, 핸드폰 번호가 유효하지 않을 경우
  I18N_AST_PHONE_NUM_NOT_VALIDATE: ['유효하지 않은 휴대폰 번호입니다.', 'EN_유효하지 않은 휴대폰 번호입니다.', undefined],
  // 인증 번호 요층에 실패 하였을 때
  I18N_AST_PHONE_FAIL_AUTH: ['인증번호 요청에 실패했습니다. 잠시 후 다시 시도해주세요.', 'EN_인증번호 요청에 실패했습니다. 잠시 후 다시 시도해주세요.', undefined],
  // 문자 인증 시간 초과 시 나오는 alert
  I18N_AST_SMS_TIME_OUT: ['인증 시간이 만료되었습니다. 인증 문자를 재발송해주세요.', 'EN_인증 시간이 만료되었습니다. 인증 문자를 재발송해주세요.', undefined],
  // 안내 alert
  I18N_AST_AUTH_SAVE_SUCCESS: ['권한 등록이 완료되었습니다.', 'EN_권한 등록이 완료되었습니다.', undefined],
  I18N_AST_AUTH_INCORRECT: ['코드가 일치하지 않습니다. 재시도 필요', 'EN_코드가 일치하지 않습니다. 재시도 필요', undefined],
  // 프로젝트 <-> 디바이스 매핑 삭제 성공 / 실패 메시지
  I18N_AST_SUCCESS_DELETE: ['삭제 성공', 'Deleted successfully.', undefined],
  I18N_AST_FAIL_DELETE: ['삭제 실패', 'Delete failed.', undefined],
  // 문자 인증 프로세스에서, 권한이 이미 있거나, 기기가 이미 등록되어 있는 경우의 안내
  I18N_AST_ALREADY_SHARED: ['이미 공유 받고 있는 기기입니다.', 'EN_이미 공유 받고 있는 기기입니다.', undefined],
  I18N_AST_ALREADY_REGISTER_DEVICE: ['이미 등록 된 기기입니다.', 'EN_이미 등록 된 기기입니다.', undefined],
  // 문자 인증 도중에 팝업을 닫았을 때 나오는 예외 메시지
  I18N_AST_RE_AUTH_SUCCESS: ['재인증이 완료되었습니다.', 'EN_재인증이 완료되었습니다.', undefined],
  I18N_AST_GUIDE_RE_AUTH: ['재인증을 받아야만 사용할 수 있습니다.', 'EN_재인증을 받아야만 사용할 수 있습니다.', undefined],
  // Settings 페이지에서 기기를 Disabled 시킬 때, 나오는 안내 메시지
  I18N_AST_SETTING_DISABLE_ASSET_TITLE: ['KN_Disable Asset', 'EN_Disable Asset', undefined],
  I18N_AST_SETTING_DISABLE_ASSET_MSG: ['시스템에 더이상 기기가 보이지 않습니다.', 'EN_시스템에 더이상 기기가 보이지 않습니다.', undefined],
  // Settings 페이지에서 기기를 삭제 시킬 때, 나오는 안내 메시지
  I18N_AST_SETTING_DELETE_ASSET_TITLE: ['Asset 삭제', 'Delete Asset', undefined],
  I18N_AST_SETTING_DELETE_ASSET_MSG: ['선택하신 개의 Asset을 삭제하시겠습니까? ', 'EN_선택하신 개의 Asset을 삭제하시겠습니까?', undefined],
  // Trend 에서 다운로드 눌렀을 때, 선택된 기기나 프로젝트가 없는 경우 에러메시지 처리
  I18N_AST_TREND_DOWNLOAD_NOT_SELECTED_PJT_OR_DEVS: ['선택된 프로젝트 또는 기기가 없습니다.', 'EN_선택된 프로젝트 또는 기기가 없습니다.', undefined],
  // Trend 에서 다운로드를 눌렀을 때, 선택된 기기나 프로젝트가 있으나, 해당 날짜에 파일이 존재하지 않는 경우
  I18N_AST_TREND_DOWNLOAD_NOT_EXIST_DEVICE_CSV_FILE: ['해당 기기의 일자 파일이 존재하지 않습니다', 'EN_해당 기기의 일자 파일이 존재하지 않습니다', undefined],
  I18N_AST_TREND_DOWNLOAD_NOT_EXIST_FILE: ['다운받기 위한 일자 데이터가 존재하지 않습니다.', 'EN_다운받기 위한 일자 데이터가 존재하지 않습니다.', undefined],
  // 프로젝트, 디바이스 삭제 성공 / 삭제 실패 예외 메시지
  I18N_AST_PJT_DELETE_SUCCESS: ['프로젝트 삭제 성공', 'EN_프로젝트 삭제 성공', undefined],
  I18N_AST_PJT_DELETE_FAIL: ['프로젝트 삭제 실패', 'EN_프로젝트 삭제 실패', undefined],
  I18N_AST_DEV_DELETE_SUCCESS: ['디바이스 삭제 성공', 'EN_디바이스 삭제 성공', undefined],
  I18N_AST_DEV_DELETE_FAIL: ['디바이스 삭제 실패', 'EN_디바이스 삭제 실패', undefined],

  // DatePicker에서 종료일이 입력 안되었을 때, 예외처리 문구
  I18N_AST_DATE_NOT_SELECT_END_DATE: ['종료일을 입력 해 주세요.', 'EN_종료일을 입력 해 주세요.', undefined],
  I18N_AST_DATE_NOT_END_DATE: ['종료 날짜 선택', 'EN_종료 날짜 선택', undefined],
  // Default Projects 에서의 ProjectDescription
  I18N_AST_DEFAULT_PROJECT_DESC: ['기본으로 생성되는 프로젝트 입니다.', 'EN_기본으로 생성되는 프로젝트 입니다.', undefined],


  //-------------------- 끝

  I18N_AST_ALL: ['전체', 'All', undefined],
  I18N_AST_ASSET: ['에셋', 'Asset', undefined],
  I18N_AST_PROJECT: ['프로젝트', 'Project', undefined],
  I18N_AST_DEVICE: ['기기 명', 'Asset', undefined],
  I18N_AST_MONITORING: ['모니터링', 'Monitoring', undefined],
  I18N_AST_TRENDS: ['트렌드', 'Trends', undefined],
  I18N_AST_TREND: ['트렌드', 'Trend', undefined],
  I18N_AST_ALARM: ['알람', 'Alarm', undefined],
  I18N_AST_PARAMETER: ['파라미터', 'Parameter', undefined],
  I18N_AST_SETTING: ['설정', 'Setting', undefined],
  I18N_AST_SETTINGS: ['설정', 'Settings', undefined],
  I18N_AST_HELP_GUIDE: ['도움말', 'Help Guide', undefined],
  I18N_AST_USER_ACCOUNT: ['계정 설정', 'User Account', undefined],


  I18N_AST_MONITOR_NOT_AUTH_DEVICE: ['소유권이 인증되지 않은 기기가 있습니다.', 'There are unauthenticated assets.', undefined],
  I18N_AST_MONITOR_NOT_AUTH_DEVICE_LINK: ['바로가기', 'Go to Setting', undefined],
  I18N_AST_MONITOR_TOTAL_ASSETS: ['전체 기기', 'Total Assets', undefined],
  I18N_AST_MONITOR_TOTAL_ENERGY: ['금일 사용량', 'Total Energy (24h)', undefined],
  I18N_AST_MONITOR_PROJECT_DETAILS: ['프로젝트 상세', 'Project Details', undefined],
  I18N_AST_MONITOR_THUMB_NAIL_IMAGE: ['미리보기 이미지', 'Thumb Nail Image', undefined],
  I18N_AST_MONITOR_PROJECT_NAME: ['프로젝트 명', 'Project Name', undefined],
  I18N_AST_MONITOR_DESCRIPTION: ['설명', 'Description', undefined],
  I18N_AST_MONITOR_MAX_TWENTY_CHARACTER: ['20자 이하 텍스트', 'Max. 20 Characters', undefined],
  I18N_AST_MONITOR_MAX_FIFTY_CHARACTER: ['50자 이하 텍스트', 'Max. 50 Characters', undefined],
  I18N_AST_MONITOR_REGISTER_ASSETS: ['기기 선택', 'Register Assets', undefined],
  I18N_AST_MONITOR_DIAGNOSTIC_FILE_DOWNLOAD: ['진단 파일 다운로드', 'Diagnostic File Download', undefined],
  I18N_AST_MONITOR_DIRECTION: ['방향', 'Direction', undefined],
  I18N_AST_MONITOR_SPEED: ['속도', 'Speed', undefined],
  I18N_AST_MONITOR_REDUCTION_RATE: ['에너지 절감률', 'Reduction Rate', undefined],
  I18N_AST_MONITOR_ENERGY_SAVING: ['에너지 절감량', 'Energy Saving', undefined],
  I18N_AST_MONITOR_ENERGY_SAVING_TOOLTIP: ['KR_Based on using 60 Hz. There may be deviations depending on your environment or situation.', 'Based on using 60 Hz. There may be deviations depending on your environment or situation.', undefined],
  I18N_AST_MONITOR_CO2_REDUCTION: ['CO2 절감량', 'CO2 Reduction', undefined],
  I18N_AST_MONITOR_CONTROL: ['원격 제어', 'Control', undefined],
  I18N_AST_MONITOR_TOTAL_HEALTH_ANALYSIS: ['AI 건강도 분석', 'Total Health Analysis', undefined],
  I18N_AST_MONITOR_AI_ASSET_ANALYSIS: ['AI 기기 분석', 'AI Asset Analysis', undefined],
  I18N_AST_MONITOR_AI_MOTOR_ANALYSIS: ['AI 모터 분석', 'AI Motor Analysis', undefined],
  I18N_AST_MONITOR_TOTAL_STATUS: ['전체 상태', 'Total Status', undefined],
  I18N_AST_MONITOR_TROUBLE_SHOOTING: ['문제 해결', 'Trouble Shooting', undefined],
  I18N_AST_MONITOR_DRIVE_STATUS: ['LV Drive 상태', 'Drive Status', undefined],
  I18N_AST_MONITOR_MOTOR_MONITORING: ['모터 모니터링', 'Motor Monitoring', undefined],
  I18N_AST_MONITOR_MOTOR_MONITORING_MEASURE: ['AI를 이용하여 모터의 건강도를 측정합니다.', 'Use AI to measure the health of the motor.', undefined],
  I18N_AST_MONITOR_BEARING_MEASURE: ['Bearing의 수명을 측정합니다.', 'The bearing of the motor may slightly damaged. The onsite engineer should perform maintenance on the motor.', undefined],
  I18N_AST_MONITOR_COIL_MEASURE: ['코일의 소손 정도를 판단합니다.', 'The coil winding condition of the motor is good.', undefined],
  I18N_AST_MONITOR_POWER_EFFICIENCY_MEASURE: ['모터의 토크 값과 전류의 상태를 측정하여 Power의 건강도를 판단합니다.', 'The power efficiency of the motor is good.', undefined],
  I18N_AST_MONITOR_RECOMMENDED_TIME_OF_USE: ['추천 사용 시간 기준으로 측정 됩니다.', 'Estimates based on the recommended time of use.', undefined],

  I18N_AST_MONITOR_VIEW_DETAILS: ['상세보기', 'View Details', undefined],
  I18N_AST_MONITOR_ANALYSIS_RESULT: ['분석 결과', 'Analysis Result', undefined],
  I18N_AST_MONITOR_ENERGY_USAGE: ['에너지 사용량', 'Energy Usage', undefined],

  I18N_AST_DEVICE_DETAIL: ['기기 상세', 'Asset Details', undefined],
  I18N_AST_DEVICE_THUMB_NAIL_IMAGE: ['미리보기 이미지', 'Thumb Nail Image', undefined],
  I18N_AST_DEVICE_NAME: ['기기명', 'Asset Name', undefined],
  I18N_AST_DEVICE_SERIAL_NUM: ['시리얼 번호', 'Serial Number', undefined],
  I18N_AST_DEVICE_MODEL_NAME: ['모델', 'Model Name', undefined],
  I18N_AST_DEVICE_LON_LAT: ['위경도', 'Latitude & Longitude', undefined],
  I18N_AST_DEVICE_DIS_ALARM: ['Disconnected 알람 간격', 'Disconnected Alarm', undefined],

  I18N_AST_TREND_ENERGY_USAGE: ['에너지 사용량', 'Energy Usage', undefined],
  I18N_AST_TREND_CUSTOM: ['기간 선택', 'Custom', undefined],
  I18N_AST_TREND_CUSTOM_TOOLTIP: ['30일 이전의 데이터 선택시 시간단위로만 볼 수 있습니다. 초단위 데이터는 다운로드를 이용해주세요.', 'Data older than 30 days is only available in hourly units. For second-unit data, please use the download button. ', undefined],
  I18N_AST_TREND_HISTORY: ['이력', 'History', undefined],
  I18N_AST_TREND_RECORDS: ['기록', 'Records', undefined],
  I18N_AST_TREND_TIME: ['발생 일시', 'Time', undefined],
  I18N_AST_TREND_TOTAL: ['전체', 'Total', undefined],
  I18N_AST_TREND_DOWNLOAD_UNIT: ['단위', 'Unit', undefined],
  I18N_AST_TREND_DOWNLOAD_TIME: ['시간', 'Time', undefined],
  I18N_AST_TREND_RECORDS_REGISTRATION_TIME: ['발생 일시', 'Registration Time', undefined],
  I18N_AST_TREND_RECORDS_CAPACITY: ['용량(kbyte)', 'Capacity (kbyte)', undefined],
  I18N_AST_TREND_RECORDS_PROJECT: ['프로젝트', 'Project', undefined],
  I18N_AST_TREND_RECORDS_ASSET: ['기기', 'Asset', undefined],
  I18N_AST_TREND_RECORDS_REFER: ['참조', 'Refer', undefined],

  I18N_AST_ALARM_DEVICE_ALARM: ['기기 알람', 'Device Alarm', undefined],
  I18N_AST_ALARM_SYSTEM_ALARM: ['시스템 알람', 'System Alarm', undefined],
  I18N_AST_ALARM_STATUS: ['상태', 'Status', undefined],
  I18N_AST_ALARM_DESC: ['설명', 'Description', undefined],
  I18N_AST_ALARM_PROJECT: ['프로젝트', 'Project', undefined],
  I18N_AST_ALARM_DEVICE: ['기기', 'Asset', undefined],
  I18N_AST_ALARM_OCCURRED_TIME: ['발생 시각', 'Occurred Time', undefined],
  I18N_AST_ALARM_RESOLVED_TIME: ['해제 시각', 'Resolved Time', undefined],
  I18N_AST_ALARM_CONFIRMED_BY: ['Confirmed by', 'Confirmed by', undefined],
  I18N_AST_ALARM_DETAILS: ['알람 상세', 'Alarm Details', undefined],

  I18N_AST_NEW_ALERTS: ['새 알람', 'New Alerts', undefined],
  I18N_AST_SEARCHING: ['검색', 'Searching', undefined],
  I18N_AST_SEARCHING_FOR_PROJECT: ['프로젝트 검색', 'Searching for Project', undefined],
  I18N_AST_SEARCHING_FOR_ASSET: ['기기 검색', 'Searching for Asset', undefined],
  I18N_AST_SEARCHING_FOR_ADDRESS: ['주소 검색', 'Searching for Address', undefined],

  I18N_AST_SETTING_ASSET: ['기기 관리', 'Asset', undefined],
  I18N_AST_SETTING_USER: ['사용자 관리', 'Share', undefined],
  I18N_AST_SETTING_ENABLE: ['기기 사용', 'Enable', undefined],
  I18N_AST_SETTING_STATUS: ['기기 상태', 'Status', undefined],
  I18N_AST_SETTING_TYPE: ['구분', 'Type', undefined],
  I18N_AST_SETTING_OWNER: ['소유자', 'Owner', undefined],
  I18N_AST_SETTING_NAME: ['기기명', 'Name', undefined],
  I18N_AST_SETTING_MODEL: ['모델', 'Model', undefined],
  I18N_AST_SETTING_LAST_UPDATE: ['최근 접속', 'Last Update', undefined],
  I18N_AST_SETTING_URL: ['URL', 'URL', undefined],
  I18N_AST_SETTING_SHARE: ['공유', 'Share', undefined],
  I18N_AST_SETTING_DETAILS: ['상세', 'Details', undefined],
  I18N_AST_SETTING_EMAIL_ALARM: ['이메일 알람', 'E-mail Alarm', undefined],
  I18N_AST_SETTING_MOBILE_ALARM: ['모바일 알람', 'Mobile Alarm', undefined],
  I18N_AST_SETTING_SIGN_OUT: ['로그아웃', 'Sign Out', undefined],
  I18N_AST_SETTING_UNAUTH_OWNER: ['본인 인증', 'Authentication', undefined],
  I18N_AST_SETTING_UNAUTH: ['인증 요청', 'Authentication', undefined],

  I18N_AST_PROFILE_USER_ACCOUNT: ['사용자 계정', 'User Account', undefined],
  I18N_AST_PROFILE_USER_ID: ['사용자 ID', 'User ID', undefined],
  I18N_AST_PROFILE_TYPE: ['권한', 'Type', undefined],
  I18N_AST_PROFILE_USER_INFORMATION: ['계정 정보', 'User Information', undefined],
  I18N_AST_PROFILE_NICKNAME: ['별명', 'Nickname', undefined],
  I18N_AST_PROFILE_MOBILE: ['모바일 번호', 'Mobile', undefined],
  I18N_AST_PROFILE_SETTING: ['계정 설정', 'Preference Setting', undefined],
  I18N_AST_PROFILE_SITE: ['사이트', 'Site', undefined],
  I18N_AST_PROFILE_ACCOUNT_SETTING: ['계정 관리', 'Account Setting', undefined],
  I18N_AST_PROFILE_NOTI_SETTING: ['알람 설정', 'Notification Setting', undefined],

  I18N_AST_MODIFY: ['수정', 'Modify', undefined],
  I18N_AST_CLEAR: ['초기화', 'Clear', undefined],
  I18N_AST_APPLY: ['적용', 'Apply', undefined],
  I18N_AST_DELETE: ['삭제', 'Delete', undefined],
  I18N_AST_CANCEL: ['취소', 'Cancel', undefined],
  I18N_AST_SAVE: ['저장', 'Save', undefined],
  I18N_AST_CLOSE: ['닫기', 'Close', undefined],
  I18N_AST_SEND: ['전송', 'Send', undefined],
  I18N_AST_OK: ['확인', 'OK', undefined],
  I18N_AST_TODAY: ['오늘', 'Today', undefined],
  I18N_AST_DOWNLOAD: ['다운로드', 'Download', undefined],
  I18N_AST_YESTERDAY: ['어제', 'Yesterday', undefined],
  I18N_AST_MIN: ['분', 'min', undefined],
  I18N_AST_HOUR: ['시간', 'hour', undefined],
  I18N_AST_DAY: ['일', 'day', undefined],
  I18N_AST_DAYS: ['일', 'days', undefined],
  I18N_AST_WEEKS: ['주', 'weeks', undefined],

  I18N_AST_TYPE: ['구분', 'Type', undefined],
  I18N_AST_GUIDE: ['가이드', 'Guide', undefined],
  I18N_AST_DETAILS: ['가이드', 'Details', undefined],
  I18N_AST_DESC: ['설명', 'Description', undefined],
  I18N_AST_REGISTRATION_TIME: ['등록 일시', 'Registration Time', undefined],
  I18N_AST_LAST_ACCESS: ['최근 접속', 'Last Access', undefined],
  I18N_AST_FILTER_DELETE: ['필터 초기화', 'Filter Delete', undefined],

  I18N_AST_MANUAL_WRITE_CONFIRM: ['저장 하시겠습니까?', 'Are you sure you want to save this post?', undefined],
  I18N_AST_MANUAL_CONFIRM: ['삭제 하시겠습니까?', 'Do you want to delete?', undefined],
  I18N_AST_CHK_FILE_TYPE: ['이미지 파일만 등록 가능합니다.', 'Only image files can be registered', undefined],
  I18N_AST_CHK_DUP_PJT_NAME: ['중복된 이름이 있습니다. 변경 부탁드립니다.', 'There are duplicate names. Please change it.', undefined],
  I18N_AST_CONFIRM_DELETE_PROJECT_TITLE: ['프로젝트 삭제', 'Delete Project', undefined],
  I18N_AST_CONFIRM_DELETE_PROJECT_MSG: ['해당 프로젝트가 삭제됩니다.', 'The Project will be deleted.', undefined],
  I18N_AST_CONFIRM_DELETE_ASSET_TITLE: ['Asset 삭제', 'Delete Asset', undefined],
  I18N_AST_CONFIRM_DELETE_ASSET_MSG: ['Asset이 삭제됩니다.', 'The Asset will be deleted.', undefined],
  I18N_AST_CONFIRM_DELETE_CONTINUE: ['계속 진행하시겠습니까?', 'Are you sure to proceed?', undefined],

  I18N_AST_RMC_CMDSRC_0: ["Smart Operator", "Smart Operator", undefined],
  I18N_AST_RMC_CMDSRC_1: ["통신 옵션", "Option Comm", undefined],
  I18N_AST_RMC_CMDSRC_2: ["App/PLC", "App/PLC", undefined],
  I18N_AST_RMC_CMDSRC_3: ["내장형 RS485 통신", "Built-in 485 Comm", undefined],
  I18N_AST_RMC_CMDSRC_4: ["단자대", "Terminal block", undefined],
  I18N_AST_RMC_CMDSRC_5: ["USB", "USB", undefined],

  I18N_AST_RMC_FREQSRC_0: ["Smart Operator speed", "Smart Operator speed", undefined],
  I18N_AST_RMC_FREQSRC_1: ["Smart Operator torque", "Smart Operator torque", undefined],
  I18N_AST_RMC_FREQSRC_2_4: ["업/다운 운전속도", "Up/Down operation speed", undefined],
  I18N_AST_RMC_FREQSRC_5: ["AI1", "AI1", undefined],
  I18N_AST_RMC_FREQSRC_6: ["AI2", "AI2", undefined],
  I18N_AST_RMC_FREQSRC_7: ["AI3", "AI3", undefined],
  I18N_AST_RMC_FREQSRC_9: ["펄스", "Pulse", undefined],
  I18N_AST_RMC_FREQSRC_10: ["내장형 RS485 통신", "Built-in 485 Comm", undefined],
  I18N_AST_RMC_FREQSRC_11: ["통신 옵션", "Option Comm", undefined],
  I18N_AST_RMC_FREQSRC_12: ["App/PLC", "App/PLC", undefined],
  I18N_AST_RMC_FREQSRC_13: ["조그", "Jog", undefined],
  I18N_AST_RMC_FREQSRC_14: ["PID", "PID", undefined],
  I18N_AST_RMC_FREQSRC_15: ["USB", "USB", undefined],
  //I18N_AST_RMC_FREQSRC_25_31: ["다단속 주파수", "다단속 주파수",undefined],
  I18N_AST_RMC_FREQSRC_25: ["다단속 주파수", "Multi-step speed Frequency", undefined],
  I18N_AST_RMC_FREQSRC_55: ["User Sequence", "User Sequence", undefined],
  I18N_AST_RMC_FREQSRC_56: ["확장 옵션 아날로그 1", "Ext Option Analog 1", undefined],
  I18N_AST_RMC_FREQSRC_57: ["확장 옵션 아날로그 2", "Ext Option Analog 2", undefined],
  I18N_AST_RMC_FREQSRC_58: ["확장 옵션 아날로그 3", "Ext Option Analog 3", undefined],
  I18N_AST_RMC_FREQSRC_59: ["Position", "Position", undefined],

  I18N_AST_RMC_ALERT_PARAM_MSG: [`운전제어를 위한 파라미터 설정이 올바르지 않습니다.
드라이브 파라미터 페이지에서 'Cmd Src' 와
'Freq Ref Src' 를 'Option Comm'로 변경해주세요.`, `Parameter settings for operation control are incorrect.
On the drive parameters page, type 'Cmd Src' and
Please change 'Freq Ref Src' to 'Option Comm'.`, undefined],

  I18N_AST_RMC_TOAST_CTL_OK: ['제어가 정상적으로 실행되었습니다.', 'EN_제어가 정상적으로 실행되었습니다.', undefined],
  I18N_AST_RMC_CTL_FAIL: ['제어 실패', 'Control Failure', undefined],
  I18N_AST_RMC_CTL_FAIL_MSG: ['제어 메시지를 전달하였으나 제어가 정상적으로 실행되지 않았습니다. 기기 상태를 확인해 주세요.',
    'The control request was not successfully applied. Please check your device status.', undefined],
  I18N_AST_RMC_AUTH_TIMEOUT_TIT: ['인증 기간 만료', 'Authentication Period Expiration', undefined],
  I18N_AST_RMC_AUTH_TIMEOUT_MSG: ['인증이 만료 되었습니다. 다시 제어를 활성화해 주세요.', 'Your authentication has expired. Please enable controls.', undefined],
  I18N_AST_RMC_UNDER_CTL_TIT: ['제어 중인 기기', 'The Device Being Controlled', undefined],
  I18N_AST_RMC_UNDER_CTL_MSG: [`제어 중인 기기 입니다.
제어를 위해서는 제어 중인 사용자가 ‘원격 제어’를 ‘Off’로 변경해야 합니다.`, `This device is being controlled.
To control this device, the user controling  must change 'Remote Control' to 'Off'.`, undefined],

  I18N_AST_RMC_AUTH_CTLSET_TIT: ['제어 권한 설정 오류', 'Control Permission Setting Error', undefined],
  I18N_AST_RMC_AUTH_CTLSET_MSG: [`해당 계정은 제어 권한이 없습니다. 기기 소유자({{owner}})에게 제어 권한을 요청해 주세요.`,
    `Your account doesn't have control permissions. Request control from the device owner({{owner}}).`, undefined],
  I18N_AST_RMC_FAIL_GET_CTLINFO_MSG: ['제어 가능정보를 가져오는데 실패하였습니다.', 'EN_제어 가능정보를 가져오는데 실패하였습니다.', undefined],
  I18N_AST_RMC_CTL_CONFIRM_MSG: ['정말 “{{from}}” 에서 “{{to}}”로 변경하시겠습니까?', 'Are you sure you want to change from “{{from}}” to “{{to}}”?', undefined],

  I18N_AST_RMC_AUTH_EXPIRED_TIT: ["인증 기간 만료", "Authentication Period Expiration", undefined],
  I18N_AST_RMC_AUTH_EXPIRED_MSG: [`인증이 만료 되었습니다.
다시 제어를 활성화해 주세요`, `Your authentication has expired.
Please enable controls.`, undefined],

  I18N_AST_DIR_STOP: ['정지', 'EN_정지', undefined],
  I18N_AST_DIR_DC_OPER: ['DC운전', 'EN_DC운전', undefined],

  I18N_AST_SPEED_ACCELERATING: ['가속', 'EN_가속', undefined],
  I18N_AST_SPEED_HW_OCS: ['HW과전류 억제', 'EN_HW과전류 억제', undefined],
  I18N_AST_SPEED_SW_OCS: ['SW과전류 억제', 'EN_SW과전류 억제', undefined],
  I18N_AST_SPEED_DECELERATING: ['감속', 'EN_감속', undefined],
  I18N_AST_SPEED_SPEED_SEARCHING: ['속도 검색중', 'EN_속도 검색중', undefined],
  I18N_AST_SPEED_DECELERATING_TO_STOP: ['감속정지', 'EN_감속정지', undefined],
  I18N_AST_SPEED_OPERATING_AT_CONSTANT_RATE: ['정속', 'EN_정속', undefined],
  I18N_AST_SPEED_DWELL_OPERATING: ['드웰', 'EN_드웰', undefined],

  I18N_AST_SHARE_TITLE: ['KR_Share to User', 'Share to User', undefined],
  I18N_AST_SHARE_ADD_MSG: ['KR_Enter the Account to Add', 'Enter the Account to Add', undefined],
  I18N_AST_SHARE_USER: ['KR_Share User', 'Share User', undefined],
  I18N_AST_SHARE_EMAIL: ['KR_Email ID', 'Email ID', undefined],
  I18N_AST_SHARE_MONITOR: ['KR_Monitor', 'Monitor', undefined],
  I18N_AST_SHARE_CONTROL: ['KR_Control', 'Control', undefined],
  I18N_AST_SHARE_LASTLOGIN: ['최근 로그인', 'Last Login', undefined],

  I18N_AST_SETTING_USER_ID: ['사용자 ID', 'User ID', undefined],
  I18N_AST_SETTING_USER_NICKNAME: ['닉네임', 'Nickname', undefined],
  I18N_AST_SETTING_USER_SHARED: ['KR_Shared Assets(EA)', 'Shared Assets(EA)', undefined],
  I18N_AST_SETTING_USER_LASTLOGIN: ['최근 로그인', 'Last Login', undefined],
  I18N_AST_SETTING_USER_DETAILS: ['상세', 'Details', undefined],

  I18N_AST_SETTING_USER_DETAILS_POP: ['KR_User Details', 'User Details', undefined],
  I18N_AST_SETTING_USER_SHARED_MSG: ['KR_Enter the Account to Share', 'Enter the Account to Share', undefined],
  I18N_AST_SETTING_USER_MY_ASSETS: ['KR_My Assets', 'My Assets', undefined],
  I18N_AST_SETTING_USER_ASSET_NAME: ['기기명', 'Asset Name', undefined],
  I18N_AST_SETTING_USER_MONITOR: ['KR_Monitor', 'Monitor', undefined],
  I18N_AST_SETTING_USER_CONTROL: ['KR_Control', 'Control', undefined],
  I18N_AST_SETTING_USER_ASSET_TIME: ['KR_Registration Time', 'Registration Time', undefined],

  I18N_AST_INVITATION_SEND_MAIL: ['KR_Send Invitation Mail', 'Send Invitation Mail', undefined],
  I18N_AST_INVITATION_EMAIL: ['KR_Email ID', 'Email ID', undefined],
  I18N_AST_INVITATION_LANGUAGE: ['KR_language', 'Language', undefined],
  I18N_AST_INVITATION_MAIL_MSG: ['KR_Mail Message', 'Mail Message', undefined],
  I18N_AST_INVITATION_USER_DETAILS: ['상세', 'Details', undefined],

  I18N_HELP_SEARCH_PLACEHOLDER: ["키워드 입력", "Enter Keywords", undefined],
  I18N_HELP_ADD_PAGE: ["페이지 추가", "Add Page", undefined],
  I18N_HELP_ADD_CATEGORY: ["새 카테고리", "New Category", undefined],
};

export type RES_ASSET = keyof typeof resi18n_asset;

const [ko, en, enus] = resi18nutil.getres(resi18n_asset);

export const resi18n_asset_ko = ko;
export const resi18n_asset_en = en;
export const resi18n_asset_enus = enus;



