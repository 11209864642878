import {IfAlarmFilterRes} from "../models/IfAlarmEntity";
import {DEV_STAT, getDevStatStr} from "asset/models/defs";
import {IfPageInfo} from "asset/models/IfPage";
import svcs from "services";
import {langutil} from "language/langutil";


export const getGuideUrl = (url: string): string => {
  if (!url) return '';
  if (url.startsWith('http://') || url.startsWith('https://')) return url;
  const lang = svcs.svcLang.lang();
  const prefix = langutil.urlPrefix(lang);
  const slash = url.startsWith("/") ? "" : "/";

  return 'https://sol.ls-electric.com' + prefix + "/rd" + slash + url;

};

export const getMappingFilterData = (filterList: IfAlarmFilterRes[], cls: string) => {

  if (!filterList) {
    return {};
  }

  switch (cls.toLowerCase()) {
    case 'status':
      return filterList.reduce((acc, cur) => {
        if (cur.status === null) return acc;
        acc[cur.status] = getDevStatStr(cur.status as DEV_STAT);
        return acc;
      }, {});
    case 'description':
      return filterList.reduce((acc, cur) => {
        if (cur.alarmType === null) return acc;
        acc[cur.alarmType] = cur.alarmDesc;
        return acc;
      }, {});
    case 'project':
      return filterList.reduce((acc, cur) => {
        if (cur.projectId === null) return acc;
        acc[cur.projectId] = cur.projectName;
        return acc;
      }, {});
    case 'asset':
      return filterList.reduce((acc, cur) => {
        if (cur.deviceId === null) return acc;
        acc[cur.deviceId] = cur.devName;
        return acc;
      }, {});
    case 'resolved time':
      return {'resolved': 'Resolved', 'unresolved': 'Unresolved'};
    default:
      return [];
  }
};

export type ConditionType = {
  filterCondition: { [key: string]: string }[];
  sortCondition: { [key: string]: string };
  keyword: string;
  pageInfo: IfPageInfo;
};

export type I18AlarmHeaderType =
  'I18N_AST_ALARM_STATUS'
  | 'I18N_AST_ALARM_DESC'
  | 'I18N_AST_ALARM_PROJECT'
  | 'I18N_AST_ALARM_DEVICE'
  | 'I18N_AST_ALARM_OCCURRED_TIME'
  | 'I18N_AST_ALARM_RESOLVED_TIME'
  | 'I18N_AST_ALARM_CONFIRMED_BY'
  | 'I18N_AST_DETAILS';

export const i18HeaderName: I18AlarmHeaderType[] = [
  'I18N_AST_ALARM_STATUS',
  'I18N_AST_ALARM_DESC',
  'I18N_AST_ALARM_PROJECT',
  'I18N_AST_ALARM_DEVICE',
  'I18N_AST_ALARM_OCCURRED_TIME',
  'I18N_AST_ALARM_RESOLVED_TIME',
  'I18N_AST_ALARM_CONFIRMED_BY',
  'I18N_AST_DETAILS'];


/*
--------- Fault 발생 시
Output_freq_before_trip	1029
Output_current_before_trip	1030
Inv_state_before_trip	1031
Output_DCLink_before_trip	1032
Input_terminal_state_before_trip	1033
Output_terminal_state_before_trip	1034
temperatureBeforeTrip   1040

--------- Warning 발생 시
outputFreqBeforeWarn	1058
outputCurrentBeforeWarn	1059
invStateBeforeWarn	1060
outputDclinkBeforeWarn	1061
inputTerminalStateBeforeWarn	1062
outputTerminalStateBeforeWarn	1063
temperatureBeforeWarn   1068



Trip Code : alarm type
Inv_state_before_trip : 1031, 파싱방법.
0 정지
1 0속 운전
2 가속 운전
3 감속 운전
4 정속 운전
5 토크 운전
Input_terminal_state_before_trip : DI인것 같은데, 어떻게 표시하면 되는지?
B7 DI8(기본 I/O)
B6 DI7(기본 I/O)
B5 DI6(기본 I/O)
B4 DI5(기본 I/O)
B3 DI4(기본 I/O)
B2 DI3(기본 I/O)
B1 DI2(기본 I/O)
B0 DI1(기본I/O)
Output_terminal_state_before_trip : DO 인것 같은데, 각 어떤 비트를 표시하면 되는지?
B2 DO3(기본 I/O)
B1 DO2(기본 I/O)
B0 DO1(기본 I/O)
 */
export class FaultInfo {
  outputFreq: number;
  outputCurr: number;
  outputDclink: number;
  invStatus: string;
  di: boolean[] = [false, false, false, false, false, false, false, false];
  do: boolean[] = [false, false, false];
  temperature: number;
}


const getInvStateName = (state: number) => {
  /*
  0 정지
  1 0속 운전
  2 가속 운전
  3 감속 운전
  4 정속 운전
  5 토크 운전
   */
  if (state === 0) return '정지';
  if (state === 1) return '0속 운전';
  if (state === 2) return '가속 운전';
  if (state === 3) return '감속 운전';
  if (state === 4) return '정속 운전';
  if (state === 5) return '토크 운전';

  return 'unknown';
};

const getFaultInfoMap = (faultInfo: string): { [k: string]: number } => {
  return (faultInfo || "").split(",").reduce<{ [k: string]: number }>((acc, cur) => {
    const kv = cur.split(":").map(ele => ele.trim());
    if (kv.length !== 2) {
      console.error("parse fault info", faultInfo, kv);
      return acc;
    }
    acc[kv[0]] = parseFloat(kv[1]);
    return acc;
  }, {});
};
/*
faultInfo 는 아래와 같은 형식이다.(addr:value,addr:value,,...)
1029: 0.0, 1030: 0.0, 1031: 0, 1032: 666, 1033: 0, 1034: 0
 */
export const parseFaultInfo = (faultInfo: string): FaultInfo => {
  /*
  Output_freq_before_trip	1029
Output_current_before_trip	1030
Inv_state_before_trip	1031
Output_DCLink_before_trip	1032
Input_terminal_state_before_trip	1033
Output_terminal_state_before_trip	1034
temperatureBeforeTrip   1040
   */
  const map = getFaultInfoMap(faultInfo);
  console.log("parsed faultinfo", map);
  const o = new FaultInfo();
  o.outputFreq = map['1029'];
  o.outputCurr = map['1030'];
  o.outputDclink = map['1032'];
  o.temperature = map['1040'];
  o.invStatus = getInvStateName(map['1031']);
  const vdi = map['1033'] || 0;
  const vdo = map['1034'] || 0;
  o.di = Array(8).fill(0).map((ele, idx) => ((vdi >> idx) & 0x01) ? true : false).reverse();
  o.do = Array(3).fill(0).map((ele, idx) => ((vdo >> idx) & 0x01) ? true : false).reverse();
  return o;
};

export const parseWarningInfo = (faultInfo: string) => {
  /*
  outputFreqBeforeWarn	1058
outputCurrentBeforeWarn	1059
invStateBeforeWarn	1060
outputDclinkBeforeWarn	1061
inputTerminalStateBeforeWarn	1062
outputTerminalStateBeforeWarn	1063
temperatureBeforeWarn   1068
   */
  const map = getFaultInfoMap(faultInfo);
  console.log("parsed faultinfo", map);
  const o = new FaultInfo();
  o.outputFreq = map['1058'];
  o.outputCurr = map['1059'];
  o.outputDclink = map['1061'];
  o.temperature = map['1068'];
  o.invStatus = getInvStateName(map['1060']);
  const vdi = map['1062'] || 0;
  const vdo = map['1063'] || 0;
  o.di = Array(8).fill(0).map((ele, idx) => ((vdi >> idx) & 0x01) ? true : false).reverse();
  o.do = Array(3).fill(0).map((ele, idx) => ((vdo >> idx) & 0x01) ? true : false).reverse();
  return o;
};

