// @flow
import * as React from 'react';
import {useState} from 'react';
import {ICreatePostFeedback} from "repositories/SsqDataRepository";
import {FeedbackType} from "definitions/FeedbackType";
import svcs from "services";
import {CommuFeedback} from "style/CommonStyledComponents";
import {IfLoginInfo} from "models/IfLoginInfo";
import {loginutil} from "util/loginutil";
import {useShowToast} from "hooks/useToast";

type Props = {
  documentId: number,
  doLogin,
  loginInfo: IfLoginInfo
};
export const QnaPageAsideWrap = (props: Props) => {
  const showToast = useShowToast();

  const trans = (key: string): string => {
    svcs.svcLang.setNamespace("I18N_NAMESPACE_COMMUNITY_PAGE");
    return svcs.svcLang.getTranslationByKey(key) || "";
  };
  const [feedbackBad, setFeedbackBad] = useState(false);
  const [feedbackGood, setFeedbackGood] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState('');
  const isClickedFeedback = () => {
    return feedbackBad || feedbackGood;
  };
  const handleClick = (cmd: string, data?) => {
    if (!feedbackComment || feedbackComment.length == 0) return;
    if (!loginutil.islogin(props.loginInfo)) return props.doLogin();

    if (confirm(trans('I18N_COMMUNITY_FEEDBACK_CONFIRM'))) {
      const feddback: ICreatePostFeedback = {
        feedbackType: feedbackGood ? FeedbackType.GOOD : FeedbackType.BAD,
        comment: feedbackComment,
        feedbackWriterId: "", // set feedbackWriterId in BFF
        feedbackWriterNickname: "", // set feedbackWriterNickname in BFF
        documentId: props.documentId
      };
      svcs.repoSsqData.postFeedback(feddback)
        .then((res) => {
          //console.log('피드백 저장 완료', res);
          showToast('msg', trans("I18N_COMMUNITY_FEEDBACK_ALERT"));
          setFeedbackBad(false);
          setFeedbackGood(false);
          setFeedbackComment('');

        }).catch((err) => {
        console.log('피드백 저장 실패', err);
      });
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && event.ctrlKey)
      setFeedbackComment(prevText => prevText + '\n');
    else if (event.key === 'Enter') {
      event.preventDefault();
      handleClick('clickFeedbackButton', '');
    }
  };

  return <CommuFeedback>
    <p className="title">
      {trans("I18N_COMMUNITY_BLOG_TALK_IDEA_TITLE")}</p>

    <div className={"feedback_btn"}>
      <div className="feedback_btn_Good">
        <button
          className={feedbackGood ? "clickGood" : ""}
          onClick={() => {
            if (!feedbackGood) {
              setFeedbackBad(false);
            }
            setFeedbackGood(!feedbackGood);
            setFeedbackBad(false);
          }}
        >{trans("I18N_COMMUNITY_BLOG_HELPFUL")}
        </button>
      </div>
      <div className="feedback_btn_Bad">
        <button
          className={feedbackBad ? "clickBad" : ""}
          onClick={() => {
            if (!feedbackBad) {
              setFeedbackGood(false);
            }
            setFeedbackGood(false);
            setFeedbackBad(!feedbackBad);
          }}
        >{trans("I18N_COMMUNITY_BLOG_NOT_HELPFUL")}
        </button>
      </div>
    </div>

    {isClickedFeedback() &&
      <div className="idea-review">
                <textarea maxLength={250} rows={10} cols={50}
                  placeholder={trans("I18N_COMMUNITY_BLOG_FEEDBACK_PLACEHOLDER")}
                  value={feedbackComment}
                  onChange={(e) => setFeedbackComment(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}/>
        <button className={"submit_button"} onClick={e => handleClick('clickFeedbackButton', '')}>
        </button>
      </div>}

  </CommuFeedback>;
};