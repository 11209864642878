// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import styled from "styled-components";
import {AssetImages} from "asset/images/AssetImages";
import * as colors from "asset/style/color";
import {fontFamilyBold, fontFamilyMedium} from "style/fonts";
import {Link, useHistory} from 'react-router-dom';
import {AssetOverlay} from "asset/style/AssetStyled";
import {useApiWeather, weatherLang} from "asset/apis/useApiWeather";
import {useDispatch, useSelector} from 'react-redux';
import {ACTION_TYPES} from "store/ActionTypes";
import {useAssetSelDev, useAssetSelPjt} from "store/reducers/reducerAsset";
import {i18ntrans} from "language/i18nkey";


export const AssetLeftMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const pos = useSelector((state: any): typeof state.weather.pos => state.weather?.pos);
  const dispatch = useDispatch();
  const [weather] = useApiWeather({lat: pos?.lat, lon: pos?.lon, lang: weatherLang()});
  useEffect(() => {
    dispatch({type: ACTION_TYPES.WEATHER.WEATHER, payload: weather});
  }, [weather]);

  const history = useHistory();

  const [selPjt] = useAssetSelPjt();
  const [selDev] = useAssetSelDev();
  const t = i18ntrans.asset;

  const renderLMList = () => {
    return <ul className="menu_list_wrap">
      {/*{renderLeftSubList(``, "icon01.svg", "Dashboard")}*/}
      {renderLeftSubList(`/asset/monitor`, "icon02.svg", t("I18N_AST_MONITORING"))}
      {renderLeftSubList(`/asset/trend`, "icon03.svg", t("I18N_AST_TRENDS"))}
      {renderLeftSubList(`/asset/alarm`, "icon04.svg", t("I18N_AST_ALARM"))}
      {renderLeftSubList(`/asset/parameter`, "icon05.svg", t("I18N_AST_PARAMETER"))}
    </ul>;
  };
  const renderLMBottomList = () => {
    return <ul className="menu_list_wrap">
      {renderLeftSubList(`/asset/settings`, "bt_icon01.svg", t("I18N_AST_SETTINGS"))}
      {renderLeftSubList(`/asset/help`, "bt_icon02.svg", t("I18N_AST_HELP_GUIDE"))}
      {renderLeftSubList(`/asset/profile`, "bt_icon03.svg", t("I18N_AST_USER_ACCOUNT"))}
    </ul>;
  };
  const renderLeftSubList = (link: string, image: string, title: string) => {
    return <li>
      <Link to={link} target={link.startsWith("https://") ? "_blank" : "_self"} onClick={(e) => {
        if (link === '/asset/monitor') {
          e.preventDefault();
          if (selDev?.deviceId) {
            history.push('/asset/monitordevice/' + selDev?.deviceId);
          } else if (selPjt?.projectId) {
            history.push('/asset/monitorproject/' + selPjt?.projectId);
          } else {
            history.push(link);
          }
        } else {
          e.preventDefault();
          const params = new URLSearchParams();
          if (selPjt?.projectId) params.append('projectId', String(selPjt.projectId));
          if (selDev?.deviceId) params.append('deviceId', selDev.deviceId);
          const url = `${link}?${params.toString()}`;
          history.push(url);
        }
        closeLeftMenu();
      }} className={(history.location.pathname.startsWith(link) ? "menufocus" : undefined)}>
        <div className={"menu_icon"}>
          <img alt={image} src={AssetImages.common.leftmenu.icon(image)} className="icon_default"/>
          <img alt={image} src={AssetImages.common.leftmenu.iconOn(image)} className="icon_on"/>
        </div>
        <div className="menu_tit">
          {title}
        </div>
      </Link>
      <div className="menu_tooltip">
        {title}
      </div>
    </li>;
  };

  const toggleLeftMenu = () => setIsOpen(!isOpen);

  const closeLeftMenu = () => isOpen && setIsOpen(false);


  return <AssetLeftMenuWrap>
    <AssetLeftMenuCont className={`${isOpen ? 'leftmenu_open' : ''}`}>
      <div>
        <Link to={'/asset/monitor'}>
          <div className="logo"/>
        </Link>
        <p className="open_btn" onClick={() => toggleLeftMenu()}>
          <img src={AssetImages.common.leftmenu.openBtn()} alt={"leftmenu_btn.svg"}/>
        </p>
        {renderLMList()}
      </div>
      <div className="leftmenu_bt">
        {renderLMBottomList()}
        <div className="LS_ci"/>
      </div>
    </AssetLeftMenuCont>
    <p className="close_btn" onClick={() => toggleLeftMenu()} style={{opacity: isOpen ? '1' : '0', display: isOpen ? 'block' : 'none'}}>
      <img src={AssetImages.common.icon.closeWhite()} alt={"icon_close_white.svg"}/>
    </p>
    <p className="open_btn mobile" onClick={() => {
      setIsOpen(true);
    }}>
      <img src={AssetImages.common.leftmenu.openBtnMo()} alt={"leftmenu_btn_mo.svg"}/>
    </p>
    {isOpen &&
      <AssetOverlay
        className={"overlay"}
        onClick={() => toggleLeftMenu()}
      />
    }
  </AssetLeftMenuWrap>;
};


const AssetLeftMenuWrap = styled.div`
  .close_btn {
    position: absolute;
    top: 24px;
    left: 300px;
    z-index: 100;
  }

  @media screen and (min-width: 1200px) {
    .close_btn {
      display: none;
    }
  }
  @media screen and (max-width: 1199px) {
    .mobile {
      display: block !important;
    }

    .open_btn.mobile {
      position: absolute;
      top: 10px;
      left: 14px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 330px) {
    .close_btn {
      left: 280px;
    }
  }
`;
const AssetLeftMenuCont = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: ${colors.lnb01};
  width: 92px;
  height: 100%;
  transition: width 0.5s;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;

  .logo {
    width: 48px;
    height: 48px;
    margin-left: 6px;
    background: url(${AssetImages.common.leftmenu.logo()}) left center no-repeat;
    transition: width 0.2s 0.1s;
  }

  .open_btn {
    cursor: pointer;
  }

  .menu_list_wrap {
    margin-top: 48px;

    li {
      position: relative;

      + li {
        margin-top: 12px;
      }

      > a {
        display: flex;
        align-items: center;
        gap: 25px;

        .menu_icon {
          height: 60px;

          .icon_on {
            display: none;
          }
        }

        .menu_tit {
          font-size: 1.8rem;
          ${fontFamilyMedium};
          color: ${colors.text04};
          display: none;
          opacity: 0;
        }
      }

      &:active, & .menufocus {
        .menu_icon {
          background: ${colors.lnb02};;
          border-radius: 14px;

          .icon_default {
            display: none;
          }

          .icon_on {
            display: block;
          }
        }

        .menu_tit {
          color: #fff
        }
      }
    }


    .menu_tooltip {
      background: #000000;
      color: #fff;
      height: 32px;
      font-size: 1.4rem;
      ${fontFamilyMedium};
      position: absolute;
      left: 57px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      align-items: center;
      padding: 0 16px;
      border-radius: 2px;
      box-shadow: 0 3px 5px 0 rgba(0, 26, 77, 0.14);
      white-space: nowrap;

      &:before {
        display: inline-block;
        content: '';
        width: 6px;
        height: 8px;
        background: url(${AssetImages.common.leftmenu.toolTipArrow()});
        position: absolute;
        left: -6px;
      }
    }
  }

  .leftmenu_bt {
    .menu_list_wrap {
      margin: 0 0 8px 0;

      li + li {
        margin-top: 2px;
      }
    }
  }

  .LS_ci {
    width: 60px;
    height: 60px;
    background: url(${AssetImages.common.leftmenu.LSlogo()}) left center no-repeat;
    transition: width 0.2s 0.1s;
  }

  &.leftmenu_open {
    width: 348px;

    .logo {
      margin: 0;
      width: 263px;
      background: url(${AssetImages.common.leftmenu.logoFull()}) left center no-repeat;
    }

    .open_btn {
      display: inline-block;
      transform: scaleX(-1);
    }

    .menu_list_wrap li {
      .menu_tit {
        display: block;
        opacity: 1;;
      }
    }

    .LS_ci {
      width: 156px;
      background: url(${AssetImages.common.leftmenu.LSlogoFull()}) left center no-repeat;
    }
  }

  @media screen and (min-width: 1200px) {

    .menu_list_wrap li:hover,
    .menu_list_wrap li .menufocus {
      .menu_icon {
        .icon_default {
          display: none;
        }

        .icon_on {
          display: block;
        }
      }

      .menu_tooltip {
        display: flex
      }
    }

    &.leftmenu_open {
      .menu_list_wrap > li > a {
        width: 200px;
      }

      .menu_list_wrap li:hover {
        .menu_tit {
          color: #fff;
          ${fontFamilyBold};
        }

        .menu_tooltip {
          display: none
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    &:not(.leftmenu_open) {
      width: 0;
      padding: 0;

      * {
        display: none;
      }

      > div {
        opacity: 0;
        display: block;
      }
    }

    &.leftmenu_open {
      width: 270px;
      opacity: 1;
      z-index: 100;
      padding-top: 27px;

      .logo {
        background-size: cover;
        height: 24px;
        width: 221px;
        margin-left: 10px;
      }

      > div {
        opacity: 1;
        transition: all 0.3s 0.2s;
      }

      .open_btn {
        display: none;
      }

      .menu_list_wrap {
        margin-top: 16px;
      }
    }
  }
`;