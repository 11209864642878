import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Modal} from "ctls/modal/Modal";
import {useRefComponent} from "hooks/useRefComponent";
import {SsqImages} from "images/SsqImages";
import svcs from "services";
import 'react-datepicker/dist/react-datepicker.css';
import {PartnerQnaPopupArea, PartnerQnaPopupWrap} from "communitypage/qna/popup/qna-popup-style";
import {DatePickerWrap, InputWrapForSelect} from "style/CommonStyledComponents";
import DatePicker from 'react-datepicker';
import {useApiAdminUsers} from "docwrite/community/popup/useApiAdminUsers";
import {IPartnerQnaClosedEmail, IPartnerQnaModifyEmail, ISsqDataCreatePartnerDoc, ISsqDataDocument} from "repositories/SsqDataRepository";
import {LoginInfo} from "models/IfLoginInfo";
import {PartnerDocStatus} from "definitions/PartnerDocStatus";
import {InquiryType} from "definitions/InquiryType";
import {IAccountInfo} from "repositories/AccountService";
import axios from "axios";
import {useApiNicknameInfo} from "../../../asset/pages/settings/useApiNicknameInfo";
import {docwriteutil} from "docwrite/community/docwriteutil";
import {langutil} from "language/langutil";
import {loginutil} from "util/loginutil";

interface Props {
  loginInfo: LoginInfo;
  docLang: string;
  startChange: (p: any) => void;
  start: Date;
  doc: ISsqDataCreatePartnerDoc;
  handleCmd: (cmd: string | 'refresh-doc-detail', data?) => void,
  onDocStatus: Function,
  question: ISsqDataDocument
  log: any;
}

interface ISrchParam {
  inquiryType: string;
  mngrUserId: string;
  partnerDocStatus: string;
  partnerId: number;
}

export const PopPartnerQnaStateModify = forwardRef<{ show: (param: { doc: ISsqDataCreatePartnerDoc, question: ISsqDataDocument }) => Promise<any> },
  Props>((props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const promise = useRef<{ resolve: any; reject: any }>({resolve: null, reject: null});
  const [keyword, setKeyword] = useState("");
  const admins = useApiAdminUsers(props.docLang, props.loginInfo?.department, true);
  const [doc, setDoc] = useState<ISsqDataCreatePartnerDoc>();
  const [question, setQuestion] = useState<ISsqDataDocument>();
  const [partnerName, setPartnerName] = useState('');
  const [mngrNickName, setMngrNickName] = useState('');
  const [emailManagers, setEmailManagers] = useState<string[]>([]);
  const [updatedDocs, setUpdatedDocs] = useState<any>(null);
  const [logLists, setLogLists] = useState([]);

  const [clickSave, setClickSave] = useState(false);
  const [employeeList, setEmployeeList] = useState(new Array<IAccountInfo>);
  const nicknameData = useApiNicknameInfo(doc?.mngrUserId);
  const [shouldSendEmail, setShouldSendEmail] = useState(false);

  const [srchParam, setSrchParam] = useState<ISrchParam>({
    inquiryType: '',
    mngrUserId: '',
    partnerId: 0,
    partnerDocStatus: ''
  });

  const handleClickOpen = () => {
    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    show: ({doc, question}: { doc: ISsqDataCreatePartnerDoc, question: ISsqDataDocument }) => {
      setDoc(doc);
      setQuestion(question);
      setVisible(true);
      return new Promise<any>((resolve, reject) => {
        promise.current.resolve = resolve;
        promise.current.reject = reject;
      });
    }
  }));


  const handleClose = async () => {
    setVisible(false);
    props.startChange(doc.dueDate);

    // promise.current.reject()를 비동기적으로 처리
    if (promise.current.reject) {
      promise.current.reject();
    }
  };
  //전체 임직원 목록 불러옴
  useEffect(() => {
    if (loginutil.iswels(props.loginInfo)) {
      axios.get("/api/boris/getEmployeeInfoList").then(res => {
        setEmployeeList(res.data);
      });
    }
    //파트너명 불러오기
    axios.get('/api/guest/partner/name/' + props.doc.partnerId).then((res) => {
      const result = res.data;
      setPartnerName(result.partnerName);
    });
  }, []);

  useEffect(() => {
    setSrchParam(srchParam);
  }, [srchParam]);

  useEffect(() => {
    if (!visible) return;
  }, [visible]);

  const getTranslation = (key: string) => {
    svcs.svcLang.setNamespace("I18N_NAMESPACE_SERVICE");
    return svcs.svcLang.getTranslationByKey(key) || "";
  };
  const datePicker = (date: Date, event: (p) => void) => {
    return <span className={"date-pickers"}>
      <DatePicker selected={date} onChange={(e) => event(e)} dateFormat="yy-MM-dd" placeholderText="YY-MM-DD" showMonthDropdown
        showYearDropdown formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}/>
    </span>;
  };

  //select박스 클릭 이벤트
  const handleClick = (e, cmd: string, data?) => {
    switch (cmd) {
      case 'inquiryType' :
        setSrchParam({...srchParam, inquiryType: data});
        break;

      case 'partnerDocStatus' :
        setSrchParam({...srchParam, partnerDocStatus: data});
        break;

      case 'mngrUserId' :
        setSrchParam({...srchParam, mngrUserId: data});
        break;
    }
  };

  // React 요소의 children에서 텍스트 추출
  const extractTextAndPropsFromChildren = (children: React.ReactNode): { text: string, props: any[] } => {
    let text = '';
    let propsArray: any[] = [];

    React.Children.forEach(children, child => {
      if (typeof child === 'string') {
        text += child;
      } else if (React.isValidElement(child)) {
        // Collect text and properties from child
        const childResult = extractTextAndPropsFromChildren(child.props.children);
        text += childResult.text;
        propsArray = [...propsArray, child.props];  // Collect all props
      }
    });

    return {text, props: propsArray};
  };


  //매니저 닉네임 불러오기
  const mngrUserNickName = async (mngrUserId: string): Promise<string | null> => {
    try {
      const res = await axios.get('/api/guest/account/nameNicknameLastlogin', {
        params: {id: mngrUserId} // Send `id` as a single value
      });
      setMngrNickName(res.data.nickname);
      return res.data.nickname;
    } catch (error) {
      console.error("닉네임 가져오기 실패", error);
      return null;
    }
  };

  //상태 변경 시 메일전송
  const sendPartnerModifyMail = async (logList, emailManager, updatedDoc: ISsqDataCreatePartnerDoc) => {
    console.log(logList, "log", "실행되니?");

    const nickname = await mngrUserNickName(updatedDoc.mngrUserId);

    // 비동기 작업을 처리하고 결과를 문자열로 변환
    const logEntries = await Promise.all(
      logList.map(async (user, index) => {
        let message = '';
        let mngrChangeMessages = '';
        let propsArray: any[] = [];

        if (React.isValidElement(user.message)) {
          const result = extractTextAndPropsFromChildren(user.message.props.children);
          message = result.text;
          propsArray = result.props;
        } else {
          message = user.message;
        }

        // Properties에서 IDs 추출
        const ids = propsArray.map(prop => prop.id).filter(id => id != null);
        const nicknames = await Promise.all(ids.map(id => mngrUserNickName(id)));

        // 받아온 닉네임 3개 시
        if (nicknames.length === 3) {
          mngrChangeMessages = `${nicknames[0]}님이 담당자를 ${nicknames[1]}에서 ${nicknames[2]}로 변경`;
        } else {
          mngrChangeMessages = '';
        }

        if (logList.length === 1) {
          // If there's only one log entry
          if (nicknames.length === 3) {
            // If there are exactly 3 nicknames
            message = mngrChangeMessages;
          } else {
            message = `${nicknames.join(', ')} ${message}`;
          }
        } else {
          // If there are multiple log entries
          if (nicknames.length === 3) {
            message = `${mngrChangeMessages}`;
          } else {
            message = `${nicknames.join(', ')} ${message}`;
          }
        }

        return {
          timestamp: user.timestamp,
          text: message
        };
      })
    );

    const emailMessage = logEntries.reduce((acc, {timestamp, text}, index, array) => {
      // 줄바꿈 조건: 현재 timestamp와 다음 timestamp가 다를 경우
      if (index < array.length - 1 && timestamp !== array[index + 1].timestamp) {
        return acc + text + ' - ' + timestamp + '<br>';
      } else {
        return acc + text + ' - ' + timestamp + '<br>';
      }
    }, '');

    const emailModifyReq: IPartnerQnaModifyEmail = {
      userId: question.writerId,
      lang: question.exposeSiteLanguage,
      docTitle: question.docTitle,
      documentUrl: langutil.urlPrefix(question.exposeSiteLanguage) + `/partner/qna/document/${question.id}`,
      docType: "Partner Q&A",
      dueDate: props.start == null ? null : props.start,
      userNickName: question.writerNickname,
      partnerName: partnerName,
      mngrNickName: nickname,
      message: emailMessage,
      mngrId: emailManager,
    };

    await axios.post(`/api/guest/sendPartnerQnaModify`, emailModifyReq);
  };


  //완료메일
  const sendPartnerClosedMail = async (emailManager) => {
    const emailReq: IPartnerQnaClosedEmail = {
      userId: question.writerId,
      lang: question.exposeSiteLanguage,
      docTitle: question.docTitle,
      documentUrl: langutil.urlPrefix(question.exposeSiteLanguage) + `/partner/qna/document/${question.id}`,
      mngrId: emailManager
    };

    console.log(question.writerId, emailReq.userId, "send Mail")
    await axios.post(`/api/guest/sendPartnerQnaClosed`, emailReq);
  };


  useEffect(() => {
    const sendEmail = async () => {
      console.log("props.log updated:", props.log, shouldSendEmail, logLists);
      if (shouldSendEmail && logLists) {
        try {
          srchParam.partnerDocStatus !== "CLOSED" && await sendPartnerModifyMail(props.log, emailManagers, updatedDocs);
          setShouldSendEmail(false); // 메일 전송 후 상태를 false로 변경하여 반복 전송 방지
        } catch (error) {
          console.error("메일 전송 중 오류 발생:", error);
        }
      }
    };

    if (shouldSendEmail) {
      sendEmail();
    }
  }, [shouldSendEmail]);

  const performActions = async () => {
    await props.handleCmd('refresh-doc-detail');
    setLogLists(props.log);
    setShouldSendEmail(true); // 이메일 전송 상태를 업데이트하여 useEffect 트리거
  };

  //저장하기 버튼 클릭
  const save = async () => {
    try {
      const newUpdatedDoc = {
        ...doc,
        dueDate: props.start,
        inquiryType: srchParam.inquiryType !== '' ? srchParam.inquiryType : doc.inquiryType,
        partnerDocStatus: srchParam.partnerDocStatus !== '' ? srchParam.partnerDocStatus : doc.partnerDocStatus,
        mngrUserId: srchParam.mngrUserId !== '' ? srchParam.mngrUserId : doc.mngrUserId,
      };

      const closeDate = {
        ...newUpdatedDoc,
        docId: newUpdatedDoc.docId,
        closeDate: new Date(),
      };

      // 1. Update the document
      await axios.put('/api/member/partner/doc/update', newUpdatedDoc);

      // 3. Fetch manager IDs and prepare email manager list
      const managerIds = (await axios.get('/api/guest/partner/manager/all/' +
        [doc.partnerId, newUpdatedDoc.inquiryCategory, newUpdatedDoc.inquiryType].join("/"))).data;
      const userIds = managerIds.map(manager => manager.userId);
      let newEmailManagers = [...userIds];
      if (!newEmailManagers.includes(doc.mngrUserId)) {
        newEmailManagers.push(doc.mngrUserId);
      }

      // Check if  is different and push if needed
      if (srchParam.mngrUserId.trim() !== '' && srchParam.mngrUserId !== doc.mngrUserId && !newEmailManagers.includes(srchParam.mngrUserId)) {
        newEmailManagers.push(srchParam.mngrUserId);
      }

      setEmailManagers(newEmailManagers);
      setUpdatedDocs(newUpdatedDoc);
      console.log(newEmailManagers, "Manager list updated");

      // 2. Handle closing if the status is "CLOSED"
      if (srchParam.partnerDocStatus === "CLOSED") {
        await axios.put('/api/member/partner/doc/update/closeDate', closeDate);
        await sendPartnerClosedMail(emailManagers);
      }

      // 4. Retrieve manager nickname asynchronously
      await mngrUserNickName(newUpdatedDoc.mngrUserId);

      // 5. Save history
      await savePartnerDocHistory(newUpdatedDoc.docId, props.loginInfo.userId,
        srchParam.partnerDocStatus === "CLOSED" ? closeDate : newUpdatedDoc);

      // 6. Update document status and notify user
      props.onDocStatus(newUpdatedDoc.partnerDocStatus);
      alert("상태가 수정되었습니다.");

      // 7. Refresh document detail
      await handleClose();
      await performActions();

    } catch (error) {
      console.error("Document refresh or email sending failed", error);
    }
  };

  const savePartnerDocHistory = async (docId: number, userId: string, doc: ISsqDataCreatePartnerDoc) => {
    await docwriteutil.savePartnerLog(docId, userId, doc);
  };

  const renderPopup = () => {
    return <PartnerQnaPopupArea>
      <PartnerQnaPopupWrap>
        <div className={"popup_tit"}>
          <button className={"close-button"} onClick={handleClose}>
            <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
          </button>
          <div className="tit_lt">
            <p className={"title"}>
              상태 수정하기
            </p>
          </div>
          <div className={"popup_noti"}>
            저장시 고객에게 변경사항에 대한 메일이 발송됩니다.
          </div>
        </div>
        <div className={"popup_input"}>
          <div className={"input_wrap"}>
            <p className={"tit"}>
              문의 유형
            </p>
            <div className={"input_row"}>
              <InputWrapForSelect className={"select_wrap"}>
                <select defaultValue={doc.inquiryType} onChange={(e) => {
                  handleClick(e, "inquiryType", e.target.value);
                }}>
                  <option value={InquiryType.TECH}>
                    기술문의
                  </option>
                  <option value={InquiryType.SALES}>
                    영업 문의
                  </option>
                  <option value={InquiryType.SERVICE}>
                    서비스
                  </option>
                  <option value={InquiryType.ETC}>
                    기타
                  </option>
                </select>
              </InputWrapForSelect>
            </div>
          </div>
          <div className={"input_wrap"}>
            <p className={"tit"}>
              상태
            </p>
            <div className={"input_row"}>
              <InputWrapForSelect className={"select_wrap"}>
                <select defaultValue={doc.partnerDocStatus}
                  onChange={(e) => {
                    handleClick(e, "partnerDocStatus", e.target.value);
                  }}>
                  <option value={PartnerDocStatus.OPEN}>
                    Open
                  </option>
                  <option value={PartnerDocStatus.PROCESSING}>
                    PROCESSING
                  </option>
                  <option value={PartnerDocStatus.CLOSED}>
                    CLOSED
                  </option>
                </select>
              </InputWrapForSelect>
            </div>
          </div>
          <div className={"input_wrap"}>
            <p className={"tit"}>
              담당자
            </p>
            <div className={"input_row"}>
              <InputWrapForSelect className={"select_wrap"}>
                <select defaultValue={nicknameData?.nickname} onChange={(e) => {
                  handleClick(e, "mngrUserId", e.target.value);
                }}>
                  <option value={nicknameData?.name}>{nicknameData?.name}</option>
                  {employeeList.filter(filer => filer.id !== doc.mngrUserId).map(ele => <option key={ele.id} value={ele.id}>{ele.name}</option>)}
                </select>
              </InputWrapForSelect>
            </div>
          </div>
          <div className={"input_wrap"}>
            <p className={"tit"}>
              예상 완료일
            </p>
            <DatePickerWrap className="input_row">
              {datePicker(props.start, props.startChange)}
            </DatePickerWrap>
          </div>
        </div>
        <div className="popup_btn">
          <div className="Submit-Button cancle" onClick={() => {
            handleClose();
          }}>취소
          </div>
          <div className="Submit-Button save" onClick={() => {
            save().then(r => r);
            setClickSave(true);
          }}>저장
          </div>
        </div>
      </PartnerQnaPopupWrap>

    </PartnerQnaPopupArea>;
  };

  return (<>
      {visible ? (<Modal onClick={(e) => e.stopPropagation()}>
        {renderPopup()}
      </Modal>) : null}
    </>
  );
});
PopPartnerQnaStateModify.displayName = "PopPartnerQnaStateModify";
export const usePopPartnerQnaState = () => useRefComponent<Props>(PopPartnerQnaStateModify);