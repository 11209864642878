// @flow
import * as React from 'react';
import {IfLoginInfo} from "models/IfLoginInfo";
import {PartnerQuestionProcessWrap} from "communitypage/partner/qna/partner-qna-popup-style";
import {PartnerQnaRating} from "communitypage/partner/qna/PartnerQnaRating";
import {PopPartnerQnaStateModify} from "communitypage/partner/qna/PopPartnerQnaStateEdit";
import {ISsqDataCreatePartnerDoc, ISsqDataCreatePartnerDocLog, ISsqDataDocument} from "repositories/SsqDataRepository";
import {Nickname} from "asset/pages/settings/useApiNicknameInfo";
import {UserPartnerManagerProfileImage} from "header/userprofile/UserPartnerManagerProfileImage";
import svcs from "services";
import {useEffect, useRef, useState} from "react";
import {formatDate, formatDateTime, formatSimpleDateDot} from "util/formatDateTime";
import clsx from "clsx";

type Props = {
  trans,
  loginInfo: IfLoginInfo,
  displayLoginPopup: Function;
  partnerDoc: ISsqDataCreatePartnerDoc;
  partnerDocLog: ISsqDataCreatePartnerDocLog;
  startChange: (p) => void;
  handleCmd: (cmd: string | 'refresh-doc-detail', data?) => void,
  question: ISsqDataDocument
};

export const PartnerQnaProcess = (props: Props) => {
  const refStateModifyPopup = useRef<{
    show: (param: { doc: ISsqDataCreatePartnerDoc, question: ISsqDataDocument }) => Promise<any>
  }>(null);

  const differences = [];       //로그 다른점
  const [docStatus, setDocStatus] = useState('');

  //변경된 문서 상태 받기
  const handleDocStatus = (data) => {
    setDocStatus(data);
  };

  //처음 db에 저장된 문서 상태
  useEffect(() => {
    setDocStatus(props.partnerDoc.partnerDocStatus);
  }, []);

  //문서 상태 변경
  useEffect(() => {
    handleDocStatus(props.partnerDoc.partnerDocStatus);
  }, [props.partnerDoc.partnerDocStatus]);

  const trans = (key: string): string => {
    svcs.svcLang.setNamespace("I18N_NAMESPACE_COMMUNITY_PAGE");
    return svcs.svcLang.getTranslationByKey(key) || "";
  };

  //log 불러옴(updateTime 기준으로 값 비교)
  const sortedHistories = [...(props.partnerDocLog.histories || [])].sort(
    (a, b) => new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime()
  );

  //로그 내용에서 날짜 다른지 계산
  const datesAreDifferent = (date1, date2) => {
    return date1.getTime() !== date2.getTime();
  };

  //로그 내용
  if (sortedHistories.length > 1) {
    const [latest, previous] = sortedHistories;

    // Parse partnerLog JSON strings
    const latestLog = JSON.parse(latest.partnerLog);
    const previousLog = JSON.parse(previous.partnerLog);

    // Check for differences
    for (const key in latestLog) {
      if (latestLog[key] !== previousLog[key]) {
        differences.push({field: key, newValue: latestLog[key], oldValue: previousLog[key]});
      }
    }
  }

  //변경된 log에 따라 문구 출력
  const getChangeMessages = (latest, previous) => {
    const latestLog = JSON.parse(latest.partnerLog);
    const previousLog = JSON.parse(previous.partnerLog);
    const timestamp = formatDateTime(new Date(latest.updateTime));

    const messages = [];

    if (latestLog.mngrUserId !== previousLog.mngrUserId) {
      messages.push({
        message: (
          props.question.exposeSiteLanguage == 'ko-KR' ?
            <div>
              {<Nickname id={latest.updateUserId}/>} 님이 담당자를 {<Nickname id={previousLog.mngrUserId}/>}에서
              {<Nickname id={latestLog.mngrUserId}/>}로 변경
            </div>
            : <div>
              {<Nickname id={latest.updateUserId}/>} modified EN_담당자 {<Nickname id={previousLog.mngrUserId}/>} to {<Nickname
              id={latestLog.mngrUserId}/>}
            </div>
        ),
        timestamp
      });
    }

    if (datesAreDifferent(new Date(latestLog.dueDate), new Date(previousLog.dueDate))) {
      if (previousLog.dueDate == null) {
        messages.push({
          message: (
            props.question.exposeSiteLanguage == 'ko-KR' ?
              <div>
                {<Nickname id={latest.updateUserId}/>}님이 완료 예정일을 {formatDate(new Date(latestLog.dueDate))}로 변경
              </div>
              : <div>
                {<Nickname id={latest.updateUserId}/>} modified EN_완료 예정일 to {formatDate(new Date(latestLog.dueDate))}
              </div>
          ),
          timestamp
        });
      } else {
        messages.push({
          message: (
            props.question.exposeSiteLanguage == 'ko-KR' ?
              <div>
                {<Nickname id={latest.updateUserId}/>}님이 {formatDate(new Date(previousLog.dueDate)) || '없음'}에서
                {formatDate(new Date(latestLog.dueDate)) || '없음'}로 변경
              </div>
              : <div>
                {<Nickname id={latest.updateUserId}/>} modified {formatDate(new Date(previousLog.dueDate)) || '없음'} to
                {formatDate(new Date(latestLog.dueDate)) || '없음'}
              </div>
          ),
          timestamp
        });
      }
    }

    if (latestLog.partnerDocStatus !== previousLog.partnerDocStatus) {
      if (latestLog.partnerDocStatus !== "CLOSED") {
        messages.push({
          message: (
            props.question.exposeSiteLanguage == 'ko-KR' ?
              <div>
                {<Nickname id={latest.updateUserId}/>}님이 {previousLog.partnerDocStatus} 상태를 {latestLog.partnerDocStatus}로 변경
              </div>
              : <div>
                {<Nickname id={latest.updateUserId}/>} modified {previousLog.partnerDocStatus} to {latestLog.partnerDocStatus}
              </div>
          ),
          timestamp
        });
      } else if (latestLog.partnerDocStatus == "CLOSED") {
        messages.push({
          message: (
            props.question.exposeSiteLanguage == 'ko-KR' ?
              <div>
                {<Nickname id={latest.updateUserId}/>}님이 완료상태로 변경
              </div>
              : <div>
                {<Nickname id={latest.updateUserId}/>} modified to EN_완료상태
              </div>
          ),
          timestamp
        });
      }
    }

    if (latestLog.customerScore !== previousLog.customerScore) {
      messages.push({
        message: (
          props.question.exposeSiteLanguage == 'ko-KR' ?
            <div>
              {<Nickname id={latest.updateUserId}/>}님이 별점을 {latestLog.customerScore}로 변경
            </div>
            : <div>
              {<Nickname id={latest.updateUserId}/>} modified score to {latestLog.customerScore}
            </div>
        ),
        timestamp
      });
    }

    if (latestLog.inquiryType !== previousLog.inquiryType) {
      messages.push({
        message: (
          props.question.exposeSiteLanguage == 'ko-KR' ?
            <div>
              {<Nickname id={latest.updateUserId}/>}님이 {previousLog.inquiryType}를 {latestLog.inquiryType}로 변경
            </div>
            : <div>
              {<Nickname id={latest.updateUserId}/>} modified {previousLog.inquiryType} to {latestLog.inquiryType}
            </div>
        ),
        timestamp
      });
    }

    return messages;
  };


  //변경된 모든 history 내용 출력
  const allChangeMessages = sortedHistories.flatMap((latest, index) => {
    const previous = sortedHistories[index + 1];
    if (previous) {
      return getChangeMessages(latest, previous);
    }
    return [];
  });

  //초기 '접수중' 출력
  let initialLogMessage = [];
  if (sortedHistories.length > 0) {
    const earliestLog = sortedHistories[sortedHistories.length - 1];
    const earliestMessage = {
      message: (
        <div>
          {<Nickname id={earliestLog.updateUserId}/>}님이 문의 접수중
        </div>
      ),
      timestamp: formatDateTime(new Date(earliestLog.updateTime))
    };
    initialLogMessage = [earliestMessage];
  }

  const allMessages = [...allChangeMessages, ...initialLogMessage];

  //완료예정일 계산
  const calculateDaysLeft = (targetDate) => {
    const currentDate = new Date(); // 현재 날짜
    const endDate = new Date(targetDate); // 목표 날짜
    // 두 날짜 간의 차이 계산 (밀리초 기준)
    const timeDiff = endDate.getTime() - currentDate.getTime();
    // 차이를 일 단위로 변환
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysLeft;
  };

  const daysLeft = calculateDaysLeft(formatDate(new Date(props.partnerDocLog.dueDate)));

  //초기 시간 가져오기
  const mostRecentUpdateTime = allMessages.length > 0
    ? new Date(allMessages[0].timestamp)
    : null;

  return (
    <PartnerQuestionProcessWrap>
      <div className={"info-wrap"}>
        <div className={"profile"}>
          <UserPartnerManagerProfileImage headerStyle={'header'} partnerDoc={props.partnerDocLog}/>
          <div>
            <p>담당자</p>
            <p className={"name"}><Nickname id={props.partnerDocLog.mngrUserId}></Nickname></p>
          </div>
        </div>
        <div className="process">
          <div className={clsx({
            'complete': docStatus === 'OPEN' || docStatus === 'CLOSED'
              || docStatus === 'PROCESSING' || docStatus === 'CLOSED'
          })}>
            <div className="num"><span>1</span></div>
            <p className="tit">접수</p>
          </div>
          <div className={clsx({'complete': docStatus === 'PROCESSING' || docStatus !== 'OPEN' && docStatus !== 'PROCESSING'})}>
            <div className="num"><span>2</span></div>
            <p className="tit">진행</p>
          </div>
          <div className={clsx({'now': docStatus === 'CLOSED'})}>
            <div className="num"><span>3</span></div>
            <p className="tit">닫힘</p>
          </div>
        </div>
        <div className={"right"}>
          {(props.partnerDocLog.dueDate !== null && props.partnerDocLog.partnerDocStatus !== "CLOSED") && <div className={"complete-date"}>
            <p>완료 예정일</p>
            <div className={"date"}>
              <div className={"d-flex"}>
                {daysLeft > 0 && <span className={"dday-label"}>{"D -" + daysLeft}</span>}
                {daysLeft == 0 && <span className={"dday-label"}>D-DAY</span>}
                {daysLeft < 0 && <span className={"dday-label delayed"}>지연</span>}
                {daysLeft < 0 && <span className={"dday-label delayed"}>{"D +" + Math.abs(daysLeft)}</span>}
              </div>
              {formatSimpleDateDot(new Date(props.partnerDocLog.dueDate))}
            </div>
          </div>}
          {(props.partnerDocLog.partnerDocStatus == 'CLOSED') && <div className={"rating"}>
            <PartnerQnaRating partnerDoc={props.partnerDoc} loginInfo={props.loginInfo} partnerDocLog={props.partnerDocLog}
              handleCmd={props.handleCmd} question={props.question}
            />
          </div>}
          {props.loginInfo.loginType === 'WELS' &&
            <button onClick={() => refStateModifyPopup.current.show({doc: props.partnerDoc, question: props.question})}
              className={"edit-btn"}>수정</button>}
        </div>

      </div>
      <div className={"step-wrap"}>
        {allMessages.map((item, index) => {
          const isComplete = mostRecentUpdateTime && new Date(item.timestamp).getTime() !== mostRecentUpdateTime.getTime();
          return (
            <div
              key={index}
              className={clsx({'complete': isComplete})}
            >
              {item.message} <span>{item.timestamp}</span>
            </div>
          );
        })}
      </div>

      <PopPartnerQnaStateModify ref={refStateModifyPopup} doc={props.partnerDoc} startChange={props.startChange} start={props.partnerDoc.dueDate}
        docLang={''} loginInfo={props.loginInfo} handleCmd={props.handleCmd} onDocStatus={handleDocStatus} question={props.question}
        log={allMessages.filter(item => {
          return mostRecentUpdateTime && new Date(item.timestamp).getTime() == mostRecentUpdateTime.getTime();
        })}
      />

    </PartnerQuestionProcessWrap>
  );
};
