import useSWR, {KeyedMutator} from "swr";
import {IfProjectDeviceDetailRes, IfProjectDeviceRes} from "asset/models/IfProjectDeviceEntity";
import {nulllist} from "asset/utils/asset-util";
import {useEffect, useState} from "react";


export const useApiProjectDeviceListByProjectId = ({
  projectId,
  useYn
}: { projectId?: number, useYn?: string }): [IfProjectDeviceRes[], KeyedMutator<IfProjectDeviceRes[]>, boolean] => {
  const {data, mutate, isLoading} = useSWR<IfProjectDeviceRes[]>(projectId && ['/api/member/asset/projectdevice/listByProjectId', {
    projectId,
    useYn
  }]);
  return [data || nulllist, mutate, isLoading];
};

export const useApiProjectDeviceListDetailByProjectId = ({projectId, userNowTime, verified, useYn}: {
  projectId?: number
  userNowTime?: Date,
  verified: boolean,
  useYn: "Y" | "" | "N"
}): [IfProjectDeviceDetailRes[], KeyedMutator<IfProjectDeviceRes[]>, boolean] => {
  const {data, mutate, isLoading} = useSWR<IfProjectDeviceDetailRes[]>(['/api/member/asset/projectdevice/listDetailByProjectId', {
    projectId,
    userNowTime,
    verified,
    useYn
  }]);
  return [data || nulllist, mutate, isLoading];
};

export const useApiProjectDeviceListByUserId = ({
  userId,
  useYn,
  verified
}: { userId?: string, useYn?: string, verified: boolean }): [IfProjectDeviceRes[], KeyedMutator<IfProjectDeviceRes[]>] => {
  const {data, mutate} = useSWR<IfProjectDeviceRes[]>(userId && ['/api/member/asset/projectdevice/listByUserId', {userId, useYn, verified}]);
  return [data || nulllist, mutate];
};

export const useApiDevPjtsMap = ({
  userId,
  useYn,
  verified
}: { userId?: string, useYn?: string, verified: boolean }): [{ [K: string]: IfProjectDeviceRes[] }, KeyedMutator<IfProjectDeviceRes[]>] => {
  const {data, mutate} = useSWR<IfProjectDeviceRes[]>(userId && ['/api/member/asset/projectdevice/listByUserId', {userId, useYn, verified}]);
  const [map, setMap] = useState<{ [K: string]: IfProjectDeviceRes[] }>({});
  useEffect(() => {
    setMap((data || []).reduce<{ [K: string]: IfProjectDeviceRes[] }>((acc, cur) => {
      if (!acc[cur.deviceId]) acc[cur.deviceId] = [];
      acc[cur.deviceId].push(cur);
      return acc;
    }, {}));
  }, [data]);
  return [map, mutate];
};