import {ACTION_TYPES} from "store/ActionTypes";
import svcs from "services";
import {SsqImages} from "images/SsqImages";
import axios from "axios";
import {i18ntrans} from "language/i18nkey";


export const isVal = (v) => (v === null || v === undefined) ? false : true;
export const ssqutil = {
  toarray: (obj: any) => {
    if (!obj) return obj;
    if (Array.isArray(obj)) return obj;
    return [obj];
  },
  loading: (loading: boolean) => {

    return {type: ACTION_TYPES.MAIN.LOADING, payload: loading};
  },
  lang: () => {
    return svcs.svcLang.lang();
  },
  isMobile: () => {
    if (window.matchMedia("screen and (max-width: 764px)").matches) {
      return true;
    } else {
      return false;
    }
  },
  isProduction: () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return false;
    } else {
      return true;
    }
  },
  imgOnErrorNoImage: (e) => {
    e.target.src = SsqImages.common.icon.noImage();
    e.target.className = "no-image";
    e.target.alt = "no_image.svg";
  },
  download: (content, filename, contentType?) => {
    // contentType = "text/csv;charset=utf-8;";
    // contentType = "application/octet-stream;charset=utf-8;";
    // contentType = "text/html; charset=utf-8;";
    if (!contentType) contentType = "application/octet-stream;charset=utf-8;";
    let a = document.createElement("a");
    let blob = new Blob([`\ufeff${content}`], {type: contentType});
    //var blob = new Blob(["\uefbbbf" + content], { type: contentType });
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(a.href);
  },
  downloadurl: (url, filename, timeout = 5) => {
    let a = document.createElement("a");
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    //a.setAttribute('_target', '');
    // a.href = url;
    // a.download = filename;
    document.body.appendChild(a);

    //a.target = '';
    a.click();
    ///setTimeout(() => document.body.removeChild(a), timeout * 1000 * 60);
    //document.body.removeChild(a);
    //a.parentNode.removeChild(a);
  },
  snakeToCamel: str =>
    str.toLowerCase().replace(/([-_][a-z0-9])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    ),
  geturllasttok: (url: string) => {
    let a = (url || "").split('/');
    if (a.length === 0) return "";
    return a.slice(-1)[0];
  },
  base64font: (): Promise<string> => {
    return axios.get('/fonts/malgun.ttf', {responseType: 'blob'}).then(res => {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result as string;
          const base64 = result.slice(result.indexOf(',') + 1);
          resolve(base64);
        };
        reader.readAsDataURL(res.data);
      });
    });
  },
  base64fontbold: (): Promise<string> => {
    //return axios.get('/fonts/SpoqaHanSansNeo-Bold.ttf', {responseType: 'blob'}).then(res => {
    return axios.get('/fonts/NotoSansKR-Bold.ttf', {responseType: 'blob'}).then(res => {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result as string;
          const base64 = result.slice(result.indexOf(',') + 1);
          resolve(base64);
        };
        reader.readAsDataURL(res.data);
      });
    });
  },
  base64fontmedium: (): Promise<string> => {
    //return axios.get('/fonts/SpoqaHanSansNeo-Medium.ttf', {responseType: 'blob'}).then(res => {
    return axios.get('/fonts/NotoSansKR-Medium.ttf', {responseType: 'blob'}).then(res => {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result as string;
          const base64 = result.slice(result.indexOf(',') + 1);
          resolve(base64);
        };
        reader.readAsDataURL(res.data);
      });
    });
  },
  base64fontregular: (): Promise<string> => {
    //return axios.get('/fonts/SpoqaHanSansNeo-Regular.ttf', {responseType: 'blob'}).then(res => {
    return axios.get('/fonts/NotoSansKR-Regular.ttf', {responseType: 'blob'}).then(res => {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result as string;
          const base64 = result.slice(result.indexOf(',') + 1);
          resolve(base64);
        };
        reader.readAsDataURL(res.data);
      });
    });
  },
  copyToClipboard(str: string) {
    const trans = i18ntrans.community;
    if (!document.queryCommandSupported("copy")) {
      return alert(trans('I18N_BROWSER_DOES_NOT_SUPPORT'));
    }

    let dummy = document.createElement("input");
    let text = str;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    //window.alert(trans('I18N_URL_COPIED_TO_CLIPBOARD'));
  },
  getBlobUrlForLargeFile(url: string) {
    if (!(url || "").startsWith("https://ssqbloblocal.blob.core.windows.net")) {
      return url;
    }
    return url.replace("https://ssqbloblocal.blob.core.windows.net", "https://ssqcdn.azureedge.net");
  },
  getCdnUrl(url: string) {
    if ((url || "").startsWith("https://ssqbloblocal.blob.core.windows.net")) {
      return url.replace("https://ssqbloblocal.blob.core.windows.net", "https://ssqcdn.azureedge.net");
    }
    if ((url || "").startsWith("/")) {
      return "https://ssqcdn.azureedge.net/ssqblob" + url;
    }
    return "https://ssqcdn.azureedge.net/ssqblob/" + url;
  },
  isVal,
};

