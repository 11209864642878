// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {
  PartnerQnaPagePopupTitleTag,
  PartnerQnaPagePopupTop,
  PartnerSolutionInfo,
  PartnerSolutionResult,
  PartnerSolutionTop,
  QnaPageFooter,
} from "communitypage/partner/qna/partner-qna-popup-style";
import SimpleUserProfile from "communitypage/common/SimpleUserProfile";
import {docutil} from "util/docutil";
import clsx from "clsx";
import {IfLoginInfo} from "models/IfLoginInfo";
import useOutsideClick from "hooks/useOutsideClick";
import {QuestionComment} from "communitypage/qna/popup/QuestionComment";
import {PopReport} from "docwrite/popup/PopReport";
import {IApiError, IDocGoodBadInfo, ISsqDataCreatePartnerDoc, ISsqDataDocument} from "repositories/SsqDataRepository";
import {IfTagListEntity} from "models/IfTagListEntity";
import {IAnswerDocument, IfLoginPopupConf} from "communitypage/qna/popup/qna-popup-common";
import axios, {AxiosError} from "axios";
import svcs from "services";
import {langutil} from "language/langutil";
import CommunityPostInfo from "communitypage/common/CommunityPostInfo";
import CommunityPostHoverText from "communitypage/common/CommunityPostHoverText";
import {IfDocCommentInfo} from "models/IfSsqDataDocCommentEntity";
import {CommentWrap} from "style/CommonStyledComponents";
import {roleutil} from "util/roleutil";
import {parseSanitize} from "util/commonUtils";
import {getInquiryTypeLangKey} from "definitions/InquiryType";
import {getPartnerDocStatusLangKey} from "definitions/PartnerDocStatus";

type Props = {
  question: ISsqDataDocument,
  trans,
  handleClick: (cmd: string, data?) => void,
  handleCmd: (cmd: string | 'show-login' | 'popup-menu' | 'state-question', data?) => void,
  loginInfo: IfLoginInfo,
  ssqDataRepository,
  renderPopup: (id: string) => void,
  popupConf: IfLoginPopupConf
  openToast?: (type: string) => void;
  displayLoginPopup: Function;
  commentList: Array<IfDocCommentInfo>;
  partnerDoc: ISsqDataCreatePartnerDoc;
};
export const PartnerQuestionView = (props: Props) => {
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);

  const [seq, setSeq] = useState(0);
  const [toastType, setToastType] = useState("");

  const [moreHover, setMoreHover] = useState(false);
  const [shareHover, setShareHover] = useState(false);
  const [bookMarkHover, setBookMarkHover] = useState(false);
  const [goodHover, setGoodHover] = useState(false);
  const [badHover, setBadHover] = useState(false);

  const [rndKr, setRndKr] = useState(false);
  const [rndEn, setRndEn] = useState(false);
  const [rndEnUs, setRndEnUs] = useState(false);

  useOutsideClick(ref, () => {
    setShowMenu(false);
  }, [showMenu]);

  const [bookMark, setBookMark] = useState(false);
  const [questionUserGoodBad, setQuestionUserGoodBad] = useState('');
  const [tags, setTags] = useState([] as Array<IfTagListEntity>);

  const {country, lang} = langutil.splitLangCode(svcs.svcLang.lang());
  const [comment, setComment] = useState([]);

  const popReport = useRef(null as PopReport);

  useEffect(() => {
    axios.post(`/api/guest/ssqData/bookmark/check`, {
      userId: props.loginInfo?.userId,
      docId: props.question.id
    }).then(response => {
      setBookMark(response.data || false);
    });

    axios.post('/api/guest/ssqData/docgoodbad/mycheck', {
      docId: props.question.id, userId: props.loginInfo?.userId
    }).then(response => {
      setQuestionUserGoodBad(response.data.goodBad || "");
    });


    axios.get('/api/guest/ssqData/doctaglist/taggrplist', {
      params: {
        docId: props.question.id,
        lang: svcs.svcLang.getLanguage()
      }
    }).then((res) => {
      setTags(res?.data || []);
    });

    axios.get('/api/guest/ssqData/doccomment/list', {
      params: {docId: props.question.id}
    }).then((res) => {
      setComment(res?.data || []);
    });

  }, [props.question.id]);

  const handleClick = (cmd: string | 'modify' | 'delete' | 'report' | 'bookmark', data?) => {

    if (cmd === 'modify') {
      console.log("--question modify", props.question.id);
      props.question.docType == "RND_QNA_Q"
        ? window.open(`/${country}/${lang}/community/new/${props.question.id}?docgrp=rndqna`)
        : window.open(`/${country}/${lang}/community/new/${props.question.id}?docgrp=qna`);
    } else if (cmd === 'bookmark') {
      if (!props.loginInfo.userId) {
        props.handleCmd('show-login');
        return;
      }
      /* 북마크 선택, 취소 (toggle 기능) */
      axios.post(`/api/guest/ssqData/bookmark/toggle`, {
        userId: props.loginInfo?.userId,
        docId: props.question.id,
        isBookmark: !bookMark
      }).then(response => {
        props.openToast(bookMark ? 'bookmark' : 'bookmark-clear');
        setBookMark(!bookMark);
        setSeq(seq + 1);
        setToastType(bookMark ? 'bookmark' : 'bookmark-clear');
        console.log("--bookMark", bookMark);
      });
    } else if (cmd === 'delete') {

      console.log("--question delete");
      if (confirm(props.trans("I18N_COMMUNITY_CONFIRM_DELETE"))) {
        // props.ssqDataRepository.deleteCommunityDocument(props.question.id).then(r => {
        //   if (r === 200) {
        //     alert(props.trans("I18N_COMMUNITY_CONFIRM_DELETE_SUCCESS"));
        //     window.open(`/${country}/${lang}/community/qna`, '_self');
        //   } else {
        //     alert(props.trans("I18N_COMMUNITY_ERROR_DELETE"));
        //   }
        // });
        props.ssqDataRepository.deleteCommunityDocumentAxios(props.question.id).then(r => {
          alert(props.trans("I18N_COMMUNITY_CONFIRM_DELETE_SUCCESS"));
          ["RND_QNA_Q"].includes(props.question.docType) ? window.open(`/${country}/${lang}/community/rnd?tab=qna`, '_self')
            : window.open(`/${country}/${lang}/community/qna`, '_self');
        }).catch((e: AxiosError<IApiError>) => {
          const msg = e?.response?.data?.message || "";
          if (msg.startsWith("QNA_Q_DEL_EXIST_ANSWER:")) {
            alert(props.trans("I18N_COMMUNITY_ERROR_DELETE_QNA_Q"));
          } else {
            alert(props.trans("I18N_COMMUNITY_ERROR_DELETE"));
          }
        });
      }
    } else if (cmd === 'report') {
      if (!props.loginInfo.userId) {
        props.handleCmd('show-login');
        return;
      }
      popReport.current.show({docId: props.question.id, reportType: 'DOC'})
        .then(res => {
          console.log("res", res);
        }).catch(e => {
        console.error("E", e);
      });
      console.log("--question report", data);
    } else {
      console.error("cmd ", cmd, "data ", data);
    }
  };


  /* 추천, 비추천 생성 */
  const createDocGoodBad = async (data: IDocGoodBadInfo) => {
    const response = await axios.post('/api/guest/ssqData/docgoodbad/create', data);
    console.log("추천 생성 완료", response, data.goodBad);
    return response.data;
  };

  /* 추천, 비추천 삭제 */
  const deleteDocGoodBad = async (data: IDocGoodBadInfo) => {
    const response = await axios.post('/api/guest/ssqData/docgoodbad/delete', data);
    console.log("추천 삭제 완료", response, data.goodBad);
    return response.data;
  };

  /* 추천, 비추천 Request 요청 */
  const requestUserGoodBad = async (document, type: string) => {
    if (!props.loginInfo?.userId) {
      props.handleCmd('show-login');
      return console.log("로그인되지 않음");
    }

    const requestData: IDocGoodBadInfo = {
      docId: document.id,
      userId: props.loginInfo?.userId,
      docType: document.docType,
      docTitle: document.docTitle,
      goodBad: type,
      updtDt: new Date(0)
    };


    await updateUserGoodBad(type, document, requestData);

  };

  useEffect(() => {
    checkAdminRnd();
  }, []);

  const updateUserGoodBad = async (type: string, document: IAnswerDocument, requestData) => {
    if (type === "GOOD") {
      let question = props.question;
      if (questionUserGoodBad !== "GOOD") {
        const count = await createDocGoodBad(requestData);
        console.log("--질문 Good 생성", type, count);
        question.userGoodCnt = count.goodCount;
        question.userBadCnt = count.badCount;
        setQuestionUserGoodBad('GOOD');
      } else {
        const count = await deleteDocGoodBad(requestData);
        console.log("--질문 Good 삭제", type, count);
        question.userGoodCnt = count.goodCount;
        question.userBadCnt = count.badCount;
        //this.setState({questionUserGoodBad: "", questionDetail:question});
        setQuestionUserGoodBad('');
      }
      return document;
    } else if (type === 'BAD') {
      let question = props.question;
      if (questionUserGoodBad !== "BAD") {
        const count = await createDocGoodBad(requestData);
        console.log("--질문 Bad 생성", type, count);
        question.userGoodCnt = count.goodCount;
        question.userBadCnt = count.badCount;
        setQuestionUserGoodBad('BAD');
      } else {
        const count = await deleteDocGoodBad(requestData);
        console.log("--질문 Bad 삭제", type, count);
        question.userGoodCnt = count.goodCount;
        question.userBadCnt = count.badCount;
        setQuestionUserGoodBad('');
      }
      return document;

    } else {
      console.error("unknown type ", type);
    }
  };

  const checkAdminRnd = () => {
    if (props.loginInfo.allowAdminKoKr && roleutil.hasRole("RND", props.loginInfo.userRoles)
      && props.question.exposeSiteLanguage == "ko-KR" && props.loginInfo.allowAdminManage) {
      return setRndKr(true);
    } else if (props.loginInfo.allowAdminEn && roleutil.hasRole("RND", props.loginInfo.userRoles)
      && props.question.exposeSiteLanguage == "en" && props.loginInfo.allowAdminManage) {
      return setRndEn(true);
    } else if (props.loginInfo.allowAdminEnUs && roleutil.hasRole("RND", props.loginInfo.userRoles)
      && props.question.exposeSiteLanguage == "en-US" && props.loginInfo.allowAdminManage) {
      return setRndEnUs(true);
    }
    return false;
  };

  const trans = (key: string): string => {
    svcs.svcLang.setNamespace("I18N_NAMESPACE_ADMIN_PAGE");
    return svcs.svcLang.getTranslationByKey(key) || "";
  };

  return (
    <div className="content">

      <PartnerQnaPagePopupTop>
        <div className="view-header">
          <div className="view-header-item">
            <div className="v-header-left">
              <div className="qna-check qna-check-header">
                <div className="icon-qna q"></div>
              </div>
              <CommentWrap className="view-comment-wrap">
                <SimpleUserProfile userId={props.question.writerId} displayLoginPopup={props.displayLoginPopup}/>
                <CommunityPostInfo type={"view"}
                  commentList={props.commentList}
                  ssqDocument={props.question}
                  time={props.question.updateTime}
                  count={props.question.clickCount}></CommunityPostInfo>
              </CommentWrap>
            </div>
            <div className="v-header-right">
              <ul className="comment-box">
                <li>
                  <button className={clsx("view-like", props.loginInfo?.userId && questionUserGoodBad === "GOOD" ? 'on' : '')}
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                      setGoodHover(true);
                    }}
                    onMouseLeave={(e) => {
                      e.stopPropagation();
                      setGoodHover(false);
                    }}
                    onClick={(e) => requestUserGoodBad(props.question, "GOOD")}>
                    {props.question.userGoodCnt}</button>
                  {goodHover && <CommunityPostHoverText type={'good'}/>}
                </li>


                <li>
                  <button className={clsx("view-bad", props.loginInfo?.userId && questionUserGoodBad === "BAD" ? 'on' : '')}
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                      setBadHover(true);
                    }}
                    onMouseLeave={(e) => {
                      e.stopPropagation();
                      setBadHover(false);
                    }}
                    onClick={(e) => requestUserGoodBad(props.question, "BAD")}>
                    {props.question.userBadCnt}</button>
                  {badHover && <CommunityPostHoverText type={'bad'}/>}
                </li>

                <li>
                  <button className={clsx("view-bookmark", props.loginInfo?.userId && bookMark ? 'on' : '')}
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                      setBookMarkHover(true);
                    }}
                    onMouseLeave={(e) => {
                      e.stopPropagation();
                      setBookMarkHover(false);
                    }}
                    onClick={(e) => handleClick('bookmark')}>
                  </button>
                  {bookMarkHover && <CommunityPostHoverText type={'bookMark'}/>}
                </li>
                <li className="view-share"
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                    setShareHover(true);
                  }}
                  onMouseLeave={(e) => {
                    e.stopPropagation();
                    setShareHover(false);
                  }}
                  onClick={async () => {
                    docutil.copyToClipboardCurrentUrl();
                    const doc = props.question;
                    await docutil.postUserShare(doc.id, doc.docType, doc.docTitle);
                    props.openToast('share');
                    setSeq(seq + 1);
                    setToastType('share');
                  }}>
                  {shareHover && <CommunityPostHoverText type={'share'}/>}
                </li>

              </ul>
              <button className={clsx("btn-list-modify")} ref={ref}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  setMoreHover(true);
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  setMoreHover(false);
                }}
                onClick={() => {
                  setShowMenu(!showMenu);
                }}>
                {moreHover && <CommunityPostHoverText type={'more'}/>}
              </button>

            </div>
          </div>

          {props.loginInfo.userId === props.question.writerId ?
            <div className={clsx("modify-item-body")} style={{display: showMenu ? 'block' : ''}} ref={ref}>
              <button className={clsx("modify-btn modify")}
                onClick={() => handleClick('modify')}
              >{props.trans('I18N_COMMUNITY_MODIFY')}
              </button>
              <button className="modify-btn modify"
                onClick={() => handleClick('delete')}
              > {props.trans('I18N_COMMUNITY_DELETE')}
              </button>
            </div>
            : //  로그인 사용자가 작성자가 아니면서 관리자인 경우.
            ((rndKr || rndEn || rndEnUs == true) && props.question.docType == 'RND_QNA_Q') ||
            (!['RND_QNA_Q'].includes(props.question.docType) && (props.loginInfo.allowAdminKoKr || props.loginInfo.allowAdminEn || props.loginInfo.allowAdminEnUs === true)) ?
              <div className={clsx("modify-item-body")}
                ref={ref}
                style={{display: showMenu ? 'block' : ''}}>
                <button className={clsx("modify-btn modify")}
                  onClick={() => {
                    handleClick('report');
                    props.handleCmd('popup-menu', false);
                    setShowMenu(false);
                  }}
                >{props.trans('I18N_COMMUNITY_COMMENT_REPORT')}
                </button>
                <button className={clsx("modify-btn modify")}
                  onClick={() => {
                    handleClick('modify');
                    setShowMenu(false);
                  }}
                >{props.trans('I18N_COMMUNITY_MODIFY')}
                </button>
              </div>
              : // 일반사용자.
              <div className={clsx("modify-item-body")}
                ref={ref}
                style={{display: showMenu ? 'block' : ''}}>
                <button className={clsx("modify-btn notify modify")}
                  onClick={() => {
                    handleClick('report');
                    props.handleCmd('popup-menu', false);
                    setShowMenu(false);
                  }}
                >{props.trans('I18N_COMMUNITY_COMMENT_REPORT')}
                </button>
              </div>
          }
        </div>
        <div className="mobile-black" style={{display: showMenu ? 'block' : 'none'}}></div>
      </PartnerQnaPagePopupTop>


      {/*상단 제목 태그들*/}
      <PartnerQnaPagePopupTitleTag>
        <div className="view-content">
          <div className="view-content-tag">
            <ul>
              <li
                className={clsx(`doc-type`,
                  props.partnerDoc.partnerDocStatus == 'PROCESSING' ? 'proc' : props.partnerDoc.partnerDocStatus == 'CLOSED' ? 'close' : null)}>
                {trans(getPartnerDocStatusLangKey(props.partnerDoc.partnerDocStatus))}
              </li>
              {tags.map((tag, index) => (
                <li key={index}>#{tag.tag}</li>
              ))}
            </ul>
          </div>

          {/* 제목*/}
          <PartnerSolutionTop>

            <div className={"solution-tit"}>
              {props.question.docTitle}
              <div className="comment-info-wrap">
                <CommentWrap className="view-comment-wrap view-comment-wrap_mo">
                  <SimpleUserProfile userId={props.question.writerId} displayLoginPopup={props.displayLoginPopup}/>
                  <CommunityPostInfo type={"view"}
                    commentList={props.commentList}
                    ssqDocument={props.question}
                    time={props.question.updateTime}
                    count={props.question.clickCount}></CommunityPostInfo>
                </CommentWrap>
              </div>
            </div>

            {/*모바일에서 글쓴 사람 정보 출력*/}
            <ul className="comment-box solution-profile">
              <SimpleUserProfile userId={props.question.writerId}
                displayLoginPopup={props.displayLoginPopup}/>
              <CommunityPostInfo type={"qna-q"}
                commentList={props.commentList}
                ssqDocument={props.question}
                time={props.question.updateTime}
                count={props.question.clickCount}></CommunityPostInfo>
            </ul>
            {/*확인 필요 끝*/}

          </PartnerSolutionTop>
          <PartnerSolutionInfo>
            <p>제품<span>{props.partnerDoc.inquiryCategory}</span></p>
            <p>유형<span>{trans(getInquiryTypeLangKey(props.partnerDoc.inquiryType))}</span></p>
          </PartnerSolutionInfo>

          {/* 내용*/}
          <PartnerSolutionResult>
            <div className={"SsqEditor ck-content"}>{parseSanitize(props.question.description)}</div>
          </PartnerSolutionResult>

        </div>
        {/*하단 다운로드, 댓글 출력*/}
        <QnaPageFooter>
          <QuestionComment //state={props.state}
            question={props.question}
            popupConf={props.popupConf}
            key={props.question.id || 0}
            loginInfo={props.loginInfo}
            trans={props.trans}
            handleClick={props.handleClick}
            handleCmd={props.handleCmd}
            renderPopup={props.renderPopup}
            displayLoginPopup={props.displayLoginPopup}
          />
        </QnaPageFooter>

      </PartnerQnaPagePopupTitleTag>
      <PopReport ref={popReport} trans={null} ssqDataRepository={null} languageService={null}/>
    </div>
  );
};