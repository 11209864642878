import {differenceInMonths} from 'date-fns';
import {differenceInDays} from 'date-fns';

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return (year + "-" +
    (month > 9 ? month : "0" + month) + "-" +
    (day > 9 ? day : "0" + day));
};

export const formatSimpleDate = (date: Date): string => {
  const year = date.getFullYear().toString().substr(-2);
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return (year + "-" +
    (month > 9 ? month : "0" + month) + "-" +
    (day > 9 ? day : "0" + day));
};

export const formatSimpleDateDot = (date: Date): string => {
  const year = date.getFullYear().toString().substr(-2);
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return (year + "." +
    (month > 9 ? month : "0" + month) + "." +
    (day > 9 ? day : "0" + day));
};

export const formatTime = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return ((hours > 9 ? hours : "0" + hours) + ":" +
    (minutes > 9 ? minutes : "0" + minutes) + ":" +
    (seconds > 9 ? seconds : "0" + seconds));
};
export const formatHour = (date: Date): string => {
  const hours = date.getHours();
  return hours > 9 ? '' + hours : "0" + hours;
};

export const formatHourMin = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return (hours > 9 ? '' + hours : "0" + hours) + ":" +
    (minutes > 9 ? minutes : "0" + minutes);
};
export const formatSimpleTime = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return ((hours > 9 ? hours : "0" + hours) + ":" + (minutes > 9 ? minutes : "0" + minutes));
};

export const formatStartToEnd = (start: Date, end: Date): string => {
  return formatSimpleDate(start) + " " + formatSimpleTime(start) + " ~ " + formatSimpleTime(end);
};

export const formatDateStartToEnd = (start: Date, end: Date): string => {
  return formatDate(start) + " - " + formatDate(end);
};

export const formatDateTimeStartToEnd = (start: Date, end: Date): string => {
  return formatSimpleDate(start) + "  " + formatSimpleTime(start) + " - " + formatSimpleDate(end) + "  " + formatSimpleTime(end);
};

export const formatDateTime = (date: Date): string => {
  return formatDate(date) + " " + formatTime(date);
};

export const formatDateHour = (date: Date): string => {
  return formatDate(date) + " " + formatHour(date);
};

export const formatDateHourMin = (date: Date): string => {
  return formatDate(date) + " " + formatHourMin(date);
};


export const dispDateTime = (dstr: string, def: string): string => {
  if (!dstr) return def || '';
  return formatDateTime(new Date(dstr));
};

export const dispTime = (dstr: string, def: string): string => {
  if (!dstr) return def || '';
  return formatTime(new Date(dstr));
};

export const formatDateTime2 = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const dstr = '' + year +
    (month > 9 ? month : "0" + month) +
    (day > 9 ? day : "0" + day);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const tstr = '' + (hours > 9 ? hours : "0" + hours) +
    (minutes > 9 ? minutes : "0" + minutes) +
    (seconds > 9 ? seconds : "0" + seconds);
  return dstr + "_" + tstr;
};

export const isOverOneMonth = (start: Date, end: Date): boolean => {
  const diffInMonths = differenceInMonths(end, start);
  return diffInMonths >= 1;
};

export const isOverOneDay = (start: Date, end: Date): boolean => {
  const diffInDays = differenceInDays(end, start);
  return diffInDays >= 1;
};

export const formatYmd = (str: string): string => {
  const d = new Date(str);
  return formatDate(d);
};

export const combinedDateAndTime = (date: Date, time: Date): Date => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    0,
    0
  );
};