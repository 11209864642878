import {DocType} from "definitions/DocType";
import {formatDate} from "util/formatDateTime";
import {i18ns, i18ntrans} from "language/i18nkey";
import svcs from "services";
import {DocLanguageType} from "definitions/DocLanguageType";
import axios from "axios";
import {DownloadType, emptyCreateUserDownloadRequest, ICreateUserDownloadRequest, IFile} from "repositories/SsqDataRepository";
import {downloadtypes} from "docwrite/community/toprow/DropDownCmmntyDocType";
import {cdn_imag_url_base} from "conf/gconf";

const sample_logo_64 = cdn_imag_url_base + "/service/sample_logo_64.svg";
const sw_service_logo = cdn_imag_url_base + "/service/swservice_logo.png";

export const docutil = {
  approvalRequried: (docType: string): boolean => {
    if ([
      DocType.TECHNICAL_GUIDE,
      DocType.APPLICATION_NOTE,
      DocType.SAMPLE /* LS Sample */
    ].includes(docType)) {
      return true;
    }

    if (downloadtypes.includes(docType)) {
      return true; // download 타입의 문서는 승인을 받도록 처리함. 
    }

    return false;
  },
  isTechDoc: (docType: string): boolean => {
    if ([
      DocType.TECHNICAL_GUIDE,
      DocType.APPLICATION_NOTE,
      DocType.SAMPLE /* LS Sample */
    ].includes(docType)) {
      return true;
    }
    return false;
  },
  isDocument: (docType: string): boolean => {
    if (downloadtypes.includes(docType)) {
      return true; // download 타입의 문서는 승인을 받도록 처리함.
    }

    return false;
  },

  isPartner: (docType: string): boolean => {
    if ([DocType.PARTNER_QNA_Q].includes(docType)) {
      return true;
    }
    return false;
  },
  diffsec: (now: Date, start: Date) => {
    let diff = (now.getTime() - start.getTime()) / 1000;
    return diff;
  },
  diffTime: (strdate) => {
    const t = i18ntrans.community;

    let diff = (new Date().getTime() - new Date(strdate).getTime()) / 1000;
    if (diff < 60) return t('I18N_COMMUNITY_NOW');
    diff /= 60;
    if (diff < 60) {
      const minutes = Math.floor(diff);
      if (minutes == 1) return `${minutes}${t('I18N_COMMUNITY_MINUTE')}`;
      return `${minutes}${t('I18N_COMMUNITY_MINUTES')}`;
    }
    diff /= 60;
    if (diff < 24) return `${Math.floor(diff)}${t('I18N_COMMUNITY_HOUR')}`;
    return formatDate(new Date(strdate));
  },
  getDocStat: (docType, finished) => {
    if ([DocType.TIP, DocType.USER_SAMPLE, DocType.TECH_GENERAL].includes(docType)) {
      return "-";
    }
    const t = i18ntrans.community;
    const kv = {
      'QNA_Q': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA_FINISH')],
      'QNA_A': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA_FINISH')],
      'DOC_NOTI': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_DOC_NOTI'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_DOC_NOTI_FINISH')],
      'DOC_EVT': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_DOC_EVT'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_DOC_EVT_FINISH')],
      'JOB_OFFER': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_OFFER'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_OFFER_FINISH')],
      'JOB_SEARCH': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_SEARCH'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_SEARCH_FINISH')],
      'RND_QNA_Q': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA_FINISH')],
      'RND_QNA_A': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA_FINISH')],
      'PARTNER_QNA_Q': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA_FINISH')],
      'PARTNER_QNA_A': [t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA'), t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA_FINISH')],
    };
    if (kv[docType]) {
      if (finished) return kv[docType][1];
      return kv[docType][0];
    }

    //if (finished) return "-";
    return "-";
  },
  copyToClipboardCurrentUrl() {
    const trans = i18ntrans.community;
    if (!document.queryCommandSupported("copy")) {
      return alert(trans('I18N_BROWSER_DOES_NOT_SUPPORT'));
    }

    let dummy = document.createElement("input");
    let text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    //window.alert(trans('I18N_URL_COPIED_TO_CLIPBOARD'));
  },
  async postUserShare(docId, docType, docTitle) {
    await svcs.repoSsqData.postUserShare({
      documentId: docId,
      userId: "set userId in BFF",
      docType: docType,
      docTitle: docTitle,
      updateTime: new Date()
    });
  },
  getFileLangStr(fileLang: string): string {
    const trans = i18ntrans.solution;
    switch (fileLang) {
      case DocLanguageType.ALL:
        return trans("I18N_FILE_LANG_ALL");
      case DocLanguageType.KO:
        return trans("I18N_FILE_LANG_KO");
      case DocLanguageType.EN:
        return trans("I18N_FILE_LANG_EN");
      case DocLanguageType.ETC:
        return trans("I18N_FILE_LANG_ETC");
      default:
        return "-";
    }
  },
  /* 추천, 비추천 Count 정보 호출 */
  getCountDocGoodBad(docId) {
    return axios.get(`/api/guest/ssqData/docgoodbad/count/${docId}`);
  },

  async updateDownloadCount(fileId: number) {
    await svcs.repoSsqData.addDownloadCount([fileId]);
  },

  async postUserDownloadByFile(downloadFile: IFile, document: any) {
    let req: ICreateUserDownloadRequest = {
      ...emptyCreateUserDownloadRequest,
      documentId: document.id,
      docTitle: document.docTitle,
      docType: document.docType,
      downloadType: DownloadType.FILE,
      fileKey: downloadFile.fileKey,
      blobUrlForLargeFile: downloadFile.blobUrlForLargeFile,
      fileName: downloadFile.fileName,
      updateTime: new Date(),
    };

    await svcs.repoSsqData.postUserDownload(req);
  },

  isSample: (docType: string) => {
    return [DocType.USER_SAMPLE, DocType.SAMPLE].includes(docType);
  },

  isSwService: (docType: string) => {
    return [DocType.SW_SERVICE].includes(docType);
  },

  getSimpleUserProfileTrans(key: string): string {
    svcs.svcLang.setNamespace(i18ns.cmmnty);
    return svcs.svcLang.getTranslationByKey(key) || "";
  },

  countDown: (target: Date, utc: number) => {
    const countTimer = [];
    const calculateSecond = 1000;
    const calculateMinute = calculateSecond * 60;
    const calculateHour = calculateMinute * 60;
    const calculateDay = calculateHour * 24;

    const now = new Date();
    now.setMinutes(now.getMinutes() + (now.getTimezoneOffset()) + (utc * 6));
    const nowNum = +now;
    const countDate = target.getTime() - nowNum;

    countTimer[0] = Math.floor(countDate / calculateDay); // 일
    countTimer[1] = Math.floor((countDate % calculateDay) / calculateHour); // 시간
    countTimer[2] = Math.floor((countDate % calculateHour) / calculateMinute); // 분
    countTimer[3] = Math.floor((countDate % calculateMinute) / calculateSecond); // 초
    return countTimer;
  },

  utcDateConvert: (beforeDate: Date) => {
    let date = new Date(beforeDate);
    //date.setMinutes(date.getMinutes() + (date.getTimezoneOffset()) + (utc * 6) - ((utc - 90) * 6));
    date.setMinutes(date.getMinutes() + (date.getTimezoneOffset()));
    return date;
  },

  uploadToDateConvert: (beforeDate: Date) => {
    let date = new Date(beforeDate);
    date.setMinutes(date.getMinutes() - (date.getTimezoneOffset()));
    return date;
  },

  displayDateConvert: (beforeDate: Date, utc: number) => {
    let date = new Date(beforeDate);
    date.setMinutes(date.getMinutes() + ((date.getTimezoneOffset()) * 2) + (utc * 6));
    return date;
  },

  isdefaultsamplelogo: (id: string) => {
    /*if ([sample_logo_64].includes(url)) {
      return true;
    }*/
    if ((id || "").startsWith("/static/media/")) return true;
    return false;
  },

  getdefaultsamplelogourl: (id: string) => {
    id = id || "";
    if (id === "/static/media/sample_01") return sample_logo_64;
    return "";
  },

  getdefaultsamplelogoid: (url: string) => {
    url = url || "";
    if (url === sample_logo_64) return "/static/media/sample_01";
    return "";
  },


  isdefaultswservicelogo: (id: string) => {

    if ((id || "").startsWith("/static/media/")) return true;
    return false;
  },

  getdefaultswservicelogourl: (id: string) => {
    id = id || "";
    if (id === "/static/media/swservice_01") return sw_service_logo;
    return "";
  },

  getdefaultswservicelogoid: (url: string) => {
    url = url || "";
    if (url === sw_service_logo) return "/static/media/swservice_01";
    return "";
  },

};