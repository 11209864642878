import * as React from "react";
import useSWR from "swr";
import {dispDateTime} from "util/formatDateTime";
import {swrFetcher} from "util/swrutil";


export class NicknameInfo {
  id: string;
  nickname: string;
  name: string;
  lastLogin: string;
  company: string;
  department: string;
  familyName: string;
}

export const useApiNicknameInfo = (id: string): NicknameInfo => {
  const {data} = useSWR<NicknameInfo>(id && ['/api/guest/account/nameNicknameLastlogin', {id}], swrFetcher, {dedupingInterval: 5000});
  return data;
};


export const Nickname = ({id}) => {
  const data = useApiNicknameInfo(id);
  return <React.Fragment>{data?.nickname}</React.Fragment>;
};

export const Name = ({id}) => {
  const data = useApiNicknameInfo(id);
  return <React.Fragment>{data?.name}</React.Fragment>;
};

export const FamilyName = ({id}) => {
  const data = useApiNicknameInfo(id);
  return <React.Fragment>{data?.familyName}</React.Fragment>;
};

export const Company = ({id}) => {
  const data = useApiNicknameInfo(id);
  return <React.Fragment>{data?.company}</React.Fragment>;
};

export const Department = ({id}) => {
  const data = useApiNicknameInfo(id);
  return <React.Fragment>{data?.department}</React.Fragment>;
};


export const LastLogin = ({id}) => {
  const data = useApiNicknameInfo(id);
  return <React.Fragment>{dispDateTime(data?.lastLogin, '')}</React.Fragment>;
};