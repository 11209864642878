import React from "react";
import {fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import styled from "styled-components";
import svcs from "services";
import {i18ns} from "language/i18nkey";
import {LANG_KO_KR} from "language/langutil";
import clsx from "clsx";

interface Props {
  type?: string;
}

const CommunityPostHoverText = (props: Props) => {

  const trans = (key: string) => {
    svcs.svcLang.setNamespace(i18ns.cmmnty);
    return svcs.svcLang.getTranslationByKey(key) || "";
  };

  const type = (type: string) => {
    switch (type) {
      case 'edit-image':
        return <div className={"hover-profile-page edit-image"}>
          {trans('I18N_PROFILE_PAGE_EDIT_IMAGE')}
        </div>;

      case 'edit-nickname':
        return <div className={"hover-profile-page edit-nickname"}>
          {trans('I18N_PROFILE_PAGE_EDIT_NICKNAME')}
        </div>;

      case 'cancel-button':
        return <div className={"hover-profile-page cancel-button"}>
          {trans('I18N_PROFILE_PAGE_CANCEL_BUTTON')}
        </div>;

      case 'save-button':
        return <div className={"hover-profile-page save-button"}>
          {trans('I18N_PROFILE_PAGE_SAVE_BUTTON')}
        </div>;

      case 'share':
        return <div className={"hover share"}>
          {trans('I18N_COMMUNITY_HOVER_SHARE')}
        </div>;

      case 'more':
        return <div className={"hover more"}>
          {trans('I18N_DOC_VIEW_MORE')}
        </div>;

      case 'bookMark':
        return <div className={"hover bookmark"}>
          {trans('I18N_COMMUNITY_HOVER_BOOKMARK')}
        </div>;

      case 'good':
        return <div className={"hover good"}>
          {trans('I18N_COMMUNITY_HOVER_GOOD')}
        </div>;

      case 'bad':
        return <div className={"hover bad"}>
          {trans('I18N_COMMUNITY_HOVER_BAD')}
        </div>;

      case 'download':
        return <div className={'hover download'}>
          {trans('I18N_SOLUTION_DOWNLOAD')}
        </div>;


      case 'good-a':
        return <div className={"hover good-a"}>
          {trans('I18N_COMMUNITY_HOVER_GOOD')}
        </div>;

      case 'bad-a':
        return <div className={"hover bad-a"}>
          {trans('I18N_COMMUNITY_HOVER_BAD')}
        </div>;

      case 'more-a':
        return <div className={"hover more-a"}>
          {trans('I18N_DOC_VIEW_MORE')}
        </div>;


      case 'share-profile' :
        return <div className={"hover profile-share"}>
          {trans('I18N_COMMUNITY_HOVER_SHARE_PROFILE')}
        </div>;

      case 'download-profile':
        return <div className={'hover profile-download'}>
          {trans('I18N_SOLUTION_DOWNLOAD_PROFILE')}
        </div>;

      case 'write-profile' :
        return <div className={"hover profile-write"}>
          {trans('I18N_DOC_WRITE_PROFILE')}
        </div>;

      case 'alarm-profile' :
        return <div className={"hover profile-alarm"}>
          {trans('I18N_ALARM_PROFILE')}
        </div>;

      case 'writer-profile' :
        return <div className={"hover-profile profile-writer"}>
          {trans('I18N_COMMUNITY_HOVER_TIME')}
        </div>;

      case 'modify-profile' :
        return <div className={"hover-profile profile-modify"}>
          {trans('I18N_COMMUNITY_MODIFY')}
        </div>;

      case 'selector-HMI' :
        return <div className={"hover-selector software"}>
          {trans('I18N_SELECTOR_HMI_SOFTWARE')}
        </div>;

      case 'selectorDoc':
        return <div className={"hover-product-selector doc"}>
          {trans('I18N_PRODUCT_SELECTOR_DOC_HOVER')}
        </div>;

      case 'selectorDocOption':
        return <div className={"hover-product-selector docOption"}>
          {trans('I18N_PRODUCT_SELECTOR_DOC_HOVER')}
        </div>;

      case 'selectorOption':
        return <div className={clsx(svcs.svcLang.getLanguage() === LANG_KO_KR ? "hover-product-selector optionKo" : "hover-product-selector option")}>
          {trans('I18N_PRODUCT_SELECTOR_OPTION_HOVER')}
        </div>;

      case 'selectorAddOption':
        return <div className={"hover-product-selector addOption"}>
          {trans('I18N_PRODUCT_SELECTOR_ADD_HOVER')}
        </div>;

      case 'selectorAdd':
        return <div className={"hover-product-selector add"}>
          {trans('I18N_PRODUCT_SELECTOR_ADD_HOVER')}
        </div>;

      case 'selectorDelete':
        return <div className={"hover-product-selector delete"}>
          {trans('I18N_COMMUNITY_DELETE')}
        </div>;

      case 'selectorShare':
        return <div className={"hover-product-selector share"}>
          {trans('I18N_COMMUNITY_HOVER_SHARE')}
        </div>;

      case 'selectorShare-add':
        return <div className={"hover-product-selector shareAdd"}>
          {trans('I18N_COMMUNITY_HOVER_SHARE')}
        </div>;
      case 'selectorShare-efficiency':
        return <div className={"hover-product-selector efficiency"}>
          {trans('I18N_PRODUCT_SELECTOR_EFFICIENCY')}
        </div>;

      case 'selectorAddedDelete':
        return <div
          className={clsx(svcs.svcLang.getLanguage() === LANG_KO_KR ? "hover-product-selector addedDeleteKo" : "hover-product-selector addedDelete")}>
          {trans('I18N_COMMUNITY_DELETE')}
        </div>;

      case 'selectorRelationDoc':
        return <div className={"hover-product-selector relationDoc"}>
          {trans('I18N_PRODUCT_SELECTOR_RELATION_DOC')}
        </div>;

      case 'selectorPdf':
        return <div className={"hover-product-selector pdf"}>
          {trans('I18N_PRODUCT_SELECTOR_PDF')}
        </div>;

      case 'selectorXls':
        return <div className={"hover-product-selector xls"}>
          {trans('I18N_PRODUCT_SELECTOR_XLS')}
        </div>;

      case 'selectorOpen':
        return <div className={clsx(svcs.svcLang.getLanguage() === LANG_KO_KR ? "hover-product-selector open" : "hover-product-selector open_en")}>
          {trans('I18N_PRODUCT_SELECTOR_OPEN')}
        </div>;

      case 'selectorModify':
        return <div className={"hover-product-selector modify"}>
          {trans('I18N_COMMUNITY_MODIFY')}
        </div>;

      case 'selectorGrid' :
        return <div className={"hover-proudct-aside grid"}>
          {trans('I18N_ASIDE_GRID')}
        </div>;

      case 'selectorSlider' :
        return <div className={"hover-proudct-aside slider"}>
          {trans('I18N_ASIDE_SLIDER')}
        </div>;

      case 'selectorHmiGrid' :
        return <div className={"hover-proudct-aside hmi-grid"}>
          {trans('I18N_ASIDE_GRID')}
        </div>;

      case 'selectorHmiSlider' :
        return <div className={"hover-proudct-aside hmi-slider"}>
          {trans('I18N_ASIDE_SLIDER')}
        </div>;


      case 'selectorSave':
        return <div className={"hover-product-selector save"}>
          {trans('I18N_PRODUCT_SELECTOR_SAVE')}
        </div>;

      case 'selectorScreen':
        return <div className={clsx(svcs.svcLang.getLanguage() === LANG_KO_KR ? "hover-product-selector screenKo" : "hover-product-selector screen")}>
          {trans('I18N_PRODUCT_SELECTOR_SCREEN')}
        </div>;

      case 'selectorShareScreen':
        return <div className={"hover-product-selector shareScreen"}>
          {trans('I18N_COMMUNITY_HOVER_SHARE')}
        </div>;


    }
  };

  return (
    <CommunityPostInfoStyle>
      <ul className="simple-post-item">
        {type(props.type)}
      </ul>
    </CommunityPostInfoStyle>
  );
};
export default CommunityPostHoverText;


export const CommunityPostInfoStyle = styled.div`

  .simple-post-item {
    display: flex;
    align-items: center;
  }

  li {
    margin-right: 1rem;
    font-size: 1.2rem;
    ${fontFamilyMedium};
    color: #7783A1;
    line-height: 2.4;
    position: relative;
  }

  .hover {
    position: absolute;
    background: white;
    border: 0.5px solid #C7D6EA;
    border-radius: 4px;
    height: 1.8rem;
    line-height: 1.6rem;
    min-width: 42px;
    padding: 0 0.6rem;
    text-align: center;
    z-index: 2;
    font-size: 9px;
    white-space: nowrap;
    color: #7783A1;
    ${fontFamilyRegular};
  }

  .hover-profile {
    position: absolute;
    background: white;
    border: 0.5px solid #C7D6EA;
    border-radius: 4px;
    height: 1.8rem;
    line-height: 1.6rem;
    min-width: 30px;
    padding: 0 0.6rem;
    text-align: center;
    z-index: 2;
    font-size: 9px;
    white-space: nowrap;
    color: #7783A1;
    ${fontFamilyRegular};
  }

  .hover.more {
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.share {
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.bookmark {
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.good {
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.bad {
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.good-a {
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.more-a {
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.bad-a {
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.download {
    top: -1.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.profile-alarm {
    top: -.2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.profile-write {
    top: -.4rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.profile-download {
    top: -.1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.profile-share {
    top: .1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .hover-profile.profile-writer {
    bottom: 2.2rem;
    right: 1.3%;
    transform: translateX(-50%);
  }

  .hover-profile.profile-modify {
    bottom: 2.2rem;
    left: 7%;
    transform: translateX(-50%);
  }

  .hover-selector {
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    border: 0.5px solid #C7D6EA;
    border-radius: 4px;
    height: 1.8rem;
    line-height: 1.6rem;
    min-width: 30px;
    padding: 0 0.6rem;
    text-align: center;
    z-index: 2;
    font-size: 9px;
    white-space: nowrap;
    color: white;
    ${fontFamilyRegular};
  }

  .hover-selector.software {
    transform: translateY(-190%) translateX(-50%);
  }

  .hover-product-selector {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    border: 0.5px solid #C7D6EA;
    border-radius: 4px;
    height: 1.8rem;
    line-height: 1.6rem;
    min-width: 30px;
    padding: 0 0.6rem;
    text-align: center;
    z-index: 2;
    font-size: 9px;
    white-space: nowrap;
    color: #7783A1;
    ${fontFamilyRegular};
  }

  .hover-profile-page {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    border: 0.5px solid #C7D6EA;
    border-radius: 4px;
    height: 1.8rem;
    line-height: 1.6rem;
    min-width: 30px;
    padding: 0 0.6rem;
    text-align: center;
    z-index: 2;
    font-size: 9px;
    white-space: nowrap;
    color: #FFFFFF;
    ${fontFamilyRegular};
  }

  .hover-proudct-aside {
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    border: 0.5px solid #C7D6EA;
    border-radius: 4px;
    height: 1.8rem;
    line-height: 1.6rem;
    min-width: 30px;
    padding: 0 0.6rem;
    text-align: center;
    z-index: 2;
    font-size: 9px;
    white-space: nowrap;
    color: #FFFFFF;
    ${fontFamilyRegular};
  }

  .hover-profile-page.edit-image {
    transform: translateX(-69%) translateY(242%);
  }

  .hover-profile-page.edit-nickname {
    transform: translateX(-68%) translateY(-27%);
  }

  .hover-profile-page.cancel-button {
    transform: translateX(-92%) translateY(-32%);
  }

  .hover-profile-page.save-button {
    transform: translateX(-89%) translateY(-32%);
  }

  .hover-product-selector.doc {
    transform: translateX(-15%) translateY(-176%);
  }

  .hover-product-selector.docOption {
    transform: translateX(-14%) translateY(-180%);
  }

  .hover-product-selector.optionKo {
    transform: translateX(-9%) translateY(-176%);
  }

  .hover-product-selector.option {
    transform: translateX(-18%) translateY(-176%);
  }


  .hover-product-selector.addOption {
    transform: translateX(35%) translateY(-176%);
  }

  .hover-product-selector.add {
    transform: translateX(-10%) translateY(-176%);
  }

  .hover-product-selector.delete {
    transform: translateX(-50%);
    top: 0;
    left: 21.5rem;
  }

  .hover-product-selector.share {
    transform: translateX(-50%);
    top: 5rem;
    right: 4rem;
  }

  .hover-product-selector.shareAdd {
    transform: translateX(-.5rem);
    top: -.4rem;
    right: -1rem;
  }

  .hover-product-selector.addedDeleteKo {
    right: 1.5rem;
    transform: translateX(-80%) translateY(-150%);
  }

  .hover-product-selector.addedDelete {
    transform: translateX(5%) translateY(-150%);
  }


  .hover-product-selector.relationDoc {
    transform: translateX(-28%) translateY(-160%);
  }

  .hover-product-selector.pdf {
    transform: translateX(-28%) translateY(-180%);
  }

  .hover-product-selector.xls {
    transform: translateX(-28%) translateY(-180%);
  }

  .hover-product-selector.modify {
    top: -10px;
    right: -17px;
    position: absolute;
  }

  .hover-proudct-aside.grid {
    top: 158px;
    right: 245px;
    position: absolute;
  }

  .hover-proudct-aside.hmi-grid {
    top: 102px;
    right: 183px;
    position: absolute;
  }


  .hover-proudct-aside.slider {
    top: 158px;
    right: 198px;
    position: absolute;
  }

  .hover-proudct-aside.hmi-slider {
    top: 102px;
    right: 137px;
    position: absolute;
  }

  .hover-product-selector.open {
    transform: translateX(-35%) translateY(-180%);
  }

  .hover-product-selector.open_en {
    transform: translateX(-11%) translateY(-180%);
  }

  .hover-product-selector.save {
    transform: translateX(-30%) translateY(-180%);
  }

  .hover-product-selector.screen {
    transform: translateX(-50%);
    top: 5rem;
    right: -2rem;
  }

  .hover-product-selector.screenKo {
    transform: translateX(-50%);
    top: 5rem;
    right: -1rem;
  }

  .hover-product-selector.shareScreen {
    transform: translateX(-32%);
    top: 0;
    right: 1.5rem;
  }

  .hover-product-selector.efficiency {
    transform: translateX(-50%);
    left: 50%;
    top: calc(-100% - 2px);
  }


  @media screen and (max-width: 1199px) {

    .hover-product-selector.share {
      transform: translateX(-50%);
      top: 5rem;
      right: .6rem;
    }
  }


  @media screen and (max-width: 764px) {
    .hover-profile-page {
      display: none;
    }

    .hover.profile-alarm {
      display: none;
    }

    .hover.profile-write {
      display: none;
    }

    .hover.profile-download {
      display: none;
    }

    .hover.profile-share {
      display: none;
    }

    .hover-profile.profile-modify {
      display: none;
    }

    .hover-proudct-aside.grid {
      display: none;
    }

    .hover-proudct-aside.hmi-grid {
      display: none;
    }


    .hover-proudct-aside.slider {
      display: none;
    }

    .hover-proudct-aside.hmi-slider {
      display: none;
    }

    .hover-profile.profile-writer {
      display: none;
    }

    .hover-selector.software {
      display: none;
    }

    .hover-product-selector.doc {
      display: none;
    }

    .hover-product-selector.docOption {
      display: none;
    }

    .hover-product-selector.optionKo {
      display: none;
    }

    .hover-product-selector.option {
      display: none;
    }

    .hover-product-selector.addOption {
      display: none;
    }

    .hover-product-selector.add {
      display: none;
    }

    .hover-product-selector.efficiency {
      display: none;
    }

    .hover-product-selector.delete {
      display: none;
    }

    .hover-product-selector.share {
      display: none;
    }

    .hover-product-selector.addedDeleteKo {
      display: none;
    }

    .hover-product-selector.addedDelete {
      display: none;
    }

    .hover-product-selector.relationDoc {
      display: none;
    }

    .hover-product-selector.pdf {
      display: none;
    }

    .hover-product-selector.xls {
      display: none;
    }

    .hover-product-selector.open {
      display: none;
    }

    .hover-product-selector.save {
      display: none;
    }

    .hover-product-selector.screen {
      display: none;
    }

    .hover-product-selector.screenKo {
      display: none;
    }

    .hover-product-selector.shareScreen {
      display: none;
    }

    .hover-product-selector.shareAdd {
      display: none;
    }

  }


`;