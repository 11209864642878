import {SiteLanguageType} from "definitions/SiteLanguageType";
import {
  ICreateLogItem,
  IPartnerDocHistoryCreateRequest,
  ISsqDataCreatePartnerDoc,
  ISsqDataDocumentAdminPopup,
  IVersion,
  NO_VERSION_TAG
} from "repositories/SsqDataRepository";
import svcs from "services";
import {i18ns} from "language/i18nkey";
import {IfChkDocTagListEntry} from "models/IfTagListEntity";
import {MENU_TYPE} from "definitions/MenuType";
import {ACTION_TYPE} from "definitions/ActionType";
import axios from "axios";


export const docwriteutil = {
  getTitlePlaceholderText: function (lang: string) {
    switch (lang) {
      case SiteLanguageType.KO_KR:
        return "제목 (한국어, 50자 이하 텍스트)";
      case SiteLanguageType.EN:
        return "Title (English, 75 characters or less)";
      case SiteLanguageType.EN_US:
        return "Title (English, 75 characters or less)";
      default:
        return "";
    }
  },
  getTitleLimit(lang: string) {
    if (lang === SiteLanguageType.KO_KR) {
      return 50;
    } else if (lang === SiteLanguageType.EN) {
      return 75;
    } else if (lang === SiteLanguageType.EN_US) {
      return 75;
    }
    return 0;
  },
  isUseVersion(versions: IVersion[]) {
    if (versions.length <= 0) return false;
    if (versions.length === 1 && versions[0].version === NO_VERSION_TAG) {
      return false;
    }
    return true;

  },
  getadmintrans(key: string): string {
    svcs.svcLang.setNamespace(i18ns.admin);
    return svcs.svcLang.getTranslationByKey(key) || "";
  },
  lang(doc: ISsqDataDocumentAdminPopup): string {
    if (doc?.exposeSiteLanguage) return doc.exposeSiteLanguage;
    return svcs.svcLang.lang();
  },
  async saveLog(menuType: MENU_TYPE, actionType: ACTION_TYPE, doc: ISsqDataDocumentAdminPopup) {
    const postLogItem: ICreateLogItem = {
      userId: "Set In BFF",
      userName: "Set In BFF",
      nickName: "Set In BFF",
      menuType,
      actionType,
      logContent: JSON.stringify(doc),
      updateTime: new Date()
    };
    console.log('craete log ', postLogItem);
    await svcs.repoSsqData.postMemberLogItem(postLogItem);
  },

  async savePartnerLog(docId: number, userId: string, doc: ISsqDataCreatePartnerDoc) {
    const postPartnerLogItem: IPartnerDocHistoryCreateRequest = {
      partnerLog: JSON.stringify(doc),
      docId: Number(docId),
      updateUserId: userId,
    };
    (await axios.post('/api/member/partner/doc/save/log', postPartnerLogItem)).status;
  },
  
  async saveTemp(inputDoc, viewType, docId, tempId, tags: { taglist, prods, apploads, industs, doctypes }) {
    let doc: any = {...inputDoc};
    doc.tags = tags;
    const res = await svcs.repoSsqData.saveTemporaryData2(
      JSON.stringify(doc),
      docId,
      tempId,
      viewType
    );

    //console.log("save result ", res);
    return res;
  }
};


export const reduceCheckedTagList = (tot: number, taglist: Array<IfChkDocTagListEntry>) => {
  if (tot > 100) {
    let cnt = tot - 100;
    let list = taglist.slice().reverse();
    list.forEach(ele => {
      if (cnt <= 0) return;
      if (ele.checked) {
        ele.checked = false;
        cnt--;
      }
    });
  }
};