import {
  emptySsqDataDocument,
  IPartnerDocLog,
  ISsqDataCreatePartnerDoc,
  ISsqDataCreatePartnerDocLog,
  ISsqDataDocument
} from "repositories/SsqDataRepository";
import {ICategoryForSearch} from "repositories/category/CategoryStore";
import {LoginInputValues} from "communitypage/blog/BlogPagePopup";
import {SocialSignupInfo} from "login/LoginService";
import {IfDocCommentInfo} from "models/IfSsqDataDocCommentEntity";

export interface IAnswerDocument extends ISsqDataDocument {
  exposeSiteLanguage?: string;
  isUserGood?: boolean;
  isUserBad?: boolean;
  isComment: string;
}


export function getEmptyAnswerDoc(): IAnswerDocument {
  return {
    ...emptySsqDataDocument,
    id: null,
    exposeSiteLanguage: "",
    isUserGood: false,
    isUserBad: false,
    isComment: "",
  };
}

export class LoginPopupConf {
  showLoginPopup: boolean;
  showLoginFailPopup: boolean;
  showSignupPopup: boolean;
  showSendVerifyEmailPopup: boolean;
  showSignupSocialPopup: boolean;
  showTermsDetailPopup: boolean;
  showTermsDetailPrivatePopup: boolean;
  showPasswordResetPopup: boolean;
  showSendPasswordResetEmailPopup: boolean;
  showUserPopup: boolean;
}

export interface IfLoginPopupConf extends LoginPopupConf {
}


export interface QnaPagePopupState {
  questionDetail: ISsqDataDocument;
  commentList: Array<IfDocCommentInfo>;

  clickCount: number
  answers: IAnswerDocument[];
  productCategories: ICategoryForSearch[];
  industryCategories: ICategoryForSearch[];
  applicationLoadCategories: ICategoryForSearch[];
  isMounted: boolean,

  showPopupMenu: boolean;

  loginInputValues: LoginInputValues;
  popupConf: IfLoginPopupConf;
  signupEmail: string;
  socialSignupInfo: SocialSignupInfo
  resetPasswordEmail: string
  termsType: string

  questionUserGoodBad: string | "GOOD" | "BAD";

  newAnswer: IAnswerDocument;
  seq: number;
  toastType: string;

}

export interface PartnerQnaPagePopupState {
  partnerDoc: ISsqDataCreatePartnerDoc
  partnerDocLog: ISsqDataCreatePartnerDocLog;
  questionDetail: ISsqDataDocument;
  commentList: Array<IfDocCommentInfo>;

  clickCount: number
  answers: IAnswerDocument[];
  productCategories: ICategoryForSearch[];
  industryCategories: ICategoryForSearch[];
  applicationLoadCategories: ICategoryForSearch[];
  isMounted: boolean,

  showPopupMenu: boolean;

  loginInputValues: LoginInputValues;
  popupConf: IfLoginPopupConf;
  signupEmail: string;
  socialSignupInfo: SocialSignupInfo
  resetPasswordEmail: string
  termsType: string

  questionUserGoodBad: string | "GOOD" | "BAD";

  newAnswer: IAnswerDocument;
  seq: number;
  toastType: string;

}
