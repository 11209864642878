import styled from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import img_cancel_icon from "images/banner/cancel_icon.svg";

export const BottomPopupArea = styled.div`
  position: sticky;
  height: 10rem;
  width: 100%;
  bottom: 0;
  z-index: 97;

  .bold-txt {
    ${fontFamilyBold}
  }

  .navigation-bar {
    background: #26282ECC;
    position: absolute;
    top: 68%;
    left: calc(50% - 37.8rem);
    height: 20px;
    width: 80px;
    z-index: 1;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: left;

    .swiper-pagination.swiper-pagination-fraction {
      position: relative;
      color: #FFFFFF;
      font-size: 1rem;
      ${fontFamilyRegular};
      top: 0;
      margin-left: 1rem;
      width: auto;
    }

    .swiper-event-button-prev {
      margin: 0px 2px 0px 4px;
      display: flex;
      align-items: center;
    }

    .swiper-event-button-next {
      display: flex;
      align-items: center;
      transform: rotate(-180deg);
    }

    .swiper-event-button-auto {
      margin: 0px 4px 0px 2px;
      display: flex;
      align-items: center;
    }
  }


  .banner {
    background: #FFFFFF;
  }

  .image-slide {
    float: left;
    vertical-align: bottom;
    height: 100%;
    margin-right: 3rem
  }

  .bottom-empty-image {
    width: 16.2rem;
  }

  .close-button {
    position: absolute;
    background: url(${img_cancel_icon}) no-repeat center center;
    width: 2.8rem;
    height: 2.8rem;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-right: 3.2rem;
    z-index: 101;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    background: #EEF1F6;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.2);
  }


  .banner-card {
    /*width: 100%;*/
    width: 120rem;
    height: 100%;
    padding-left: 3.2rem;
    padding-right: 3.2rem;


    .slide-txt {
      margin-top: 3rem;
      width: 100%;
      white-space: nowrap;

      .slide-text {
        font-size: 18px;
        ${fontFamilyBold};
        display: inline-block;
      }

      .url-button {
        border: 1px solid #C7D6EA;
        border-radius: 4px;
        color: #666E7A;
        padding: 0.8rem 1.8rem 0.8rem 1.8rem;
        font-size: 1.4rem;
        ${fontFamilyMedium};
        width: auto;
        display: inline-block;
        float: right;

        :hover, :active {
          background: #407EDB1A;
        }
      }
    }
  }


  /* PC 사이즈 규격*/

  .swiper-slide {
    text-align: left;
    display: flex; /* 내용을 중앙정렬 하기위해 flex 사용 */
    align-items: center; /* 위아래 기준 중앙정렬 */
    justify-content: center; /* 좌우 기준 중앙정렬 */

  }

  @media screen and (max-width: 1300px) {
    .banner-card {
      padding-right: 10.2rem;
    }
  }
  @media screen and (max-width: 999px) {
    .banner-card {
      .slide-txt {
        .url-button {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1199px) {
    .navigation-bar {
      left: 22.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    height: 19.1rem;

    .image-slide {
      float: right;
      vertical-align: bottom;
      margin-right: 0rem;
      bottom: 0;
      position: absolute;
      height: 60%;
      right: 2rem;
      padding-right: 0rem;
    }

    .banner-card {
      .slide-txt {
        white-space: normal;
        word-break: keep-all;
        z-index: 2;
        position: relative;
      }
    }

    .navigation-bar {
      left: 3.2rem;
      top: 70%;
    }

    .close-button {
      top: 24%;
      margin-right: 1.1rem;
    }
  }
`;
