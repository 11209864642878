import styled from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import {EditorStyle} from "style/SsqEditorStyle";
import {SsqImages} from "images/SsqImages";
import img_icon_c_comment from "images/common/icon_comment.svg";
import {AllPopupWrap} from "footer/Footer";

export const CommunityNotLogin = styled.div`

  min-height: 24rem;
  position: relative;
  background: #fff;

  .qna-check {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }

  .qna-check .icon-qna {
    width: 40px;
    height: 40px;
  }

  .qna-check .icon-qna.a {
    background: url(${SsqImages.community.icon.communityQnaCheck()}) no-repeat;
  }

  .qna-check .icon-qna.red-a {
    background: url("${SsqImages.community.icon.communityQnaBigA()}") no-repeat center;
  }

  .qna-check .icon-qna.a > span {
    color: #34A597;
    ${fontFamilyMedium};
    font-size: 1rem;
    padding: 0 0.2rem;
    display: inline;
  }

  .not-login {
    display: flex;
    width: 60rem;
    align-items: center;
    margin: auto;
    height: 24rem;
  }

  .not-login > .left {
    margin-right: 8rem;
    padding-bottom: 3rem;
  }

  .not-login > .left img {
    vertical-align: middle;
  }

  .not-login > .right {
    text-align: center;
  }

  .not-login > .right > .title-lg {
    width: 100%;
    color: #2A2C2F;
    font-size: 1.6rem;
  }

  .not-login > .right .title-sm {
    width: 100%;
    color: #909FB7;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    ${fontFamilyRegular};
    margin-top: .4rem;
    line-height: 2rem;
  }

  .not-login > .right button.mem-status-btn {
    display: block;
    font-size: 1.6rem;
    ${fontFamilyBold};
    color: #fff;
    height: 4.8rem;
    background: #407EDB;
    margin: auto;
    padding: 0 6rem;
    white-space: nowrap;
    border-radius: 5rem;
  }

  @media screen and (max-width: 764px) {

    .not-login {
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      padding-bottom: 6rem;
      padding-top: 3rem;
    }

    .not-login > .left {
      width: 100%;
      text-align: center;
      margin-right: 0rem;
    }

    .not-login > .right {
      width: 100%;
    }

  }
`;

export const FileAddPluse = styled.div`
  .version_row {
    position: relative;
    border-top: .1rem solid #C7D6EA;
  }

  .version_row .not-use-version {
    margin-top: 1px;
    padding: 2.4rem;
    background: #fff;
  }

  .file_add_btn {
    color: #407DD8;
    ${fontFamilyBold};
    font-size: 1.2rem;
    line-height: 16px;
  }

  .version_row .file_add_btn img {
    margin-right: 0.8rem;
    vertical-align: top;
  }
`;

export const SolutionTop = styled.div`
  margin-bottom: 3rem;
  //padding: 4rem 4rem 0;

  .solution-tit-wrap {
    display: flex;
    justify-content: space-between;
  }

  .solution-info {
    display: flex;
    font-size: 1.2rem;
    color: #7783A1;
    margin-top: .8rem;
    ${fontFamilyRegular};


    span + span:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 15px;
      background: #C7D6EA;
      vertical-align: middle;
      margin: 0 4px;
    }

    span b {
      color: #407EDB;
      font-weight: inherit !important;
      margin-left: .4rem;
    }
  }

  .solution-tit {
    font-size: 2.5rem;
    ${fontFamilyBold};
    width: 95%;
    line-height: 1.2;

    .level {
      display: inline-block;
      background: #7783A1;
      color: #fff;
      border-radius: 16px;
      width: 4.8rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 9px;
      text-align: center;
      margin: .5rem 0 .5rem 0rem;
      vertical-align: top;
    }
  }

  button + button {
    margin-left: .8rem
  }

  .solution-btn button {
    height: 3rem;

    img {
      vertical-align: middle
    }

    :hover {
      background: rgba(64, 125, 217, 0.1);
    }

    :active {
      background: rgba(64, 126, 219, 0.3);
    }
  }

  @media screen and (max-width: 764px) {
    position: relative;
    padding: .2rem 0 0 0 !important;
    .solution-tit {
      width: 100%
    }

    .solution-btn {
      position: absolute;
      right: 16px;
      bottom: -5px;
    }
  }
`;
export const SolutionResult = styled.div`
  ${EditorStyle};

  .SsqEditor {
    padding-bottom: 4rem;
    overflow-x: auto;
  }

  .download {
    border-top: 1px dashed #90A0B6;

    > div:last-child {
      padding: 0 4rem 4rem;
    }

    .down_tit {
      font-size: 1.8rem;
      ${fontFamilyBold};
      margin-bottom: 2.4rem;
      padding: 4rem 4rem 0;
    }

    .accordion-down > div {
      display: inline-block;
      font-size: 1.2rem;
    }

    .down-title {
      margin-right: .7rem;

      > a {
        color: #407EDB;
        font-size: 1.6rem;
        //font-size: 1.3rem !important;
      }
    }

    .down-icon {
      vertical-align: middle;
      margin-right: .7rem;
    }

    .down-info {
      color: #7783A1;

      span {
        ${fontFamilyRegular}
      }
    }

    .down-info span:after {
      content: '';
      width: .1rem;
      height: 1.5rem;
      background: #C7D6EA;
      margin: 0 .5rem;
      display: inline-block;
      vertical-align: middle;
    }

    .down-info span:last-child:after {
      display: none;
    }

    .down-info span b {
      color: #407EDB;
      font-weight: normal !important;
      margin-left: .5rem;
    }
  }

  .Result-bt {
    padding: 2.4rem 0;
    background: #F7F8FB;
    border-top: solid 1px #C7D6EA;
    display: flex;
    justify-content: space-between;

    > div {
      width: 50%;
      padding: 0 4rem
    }

    > div + div {
      border-left: solid 1px #C7D6EA
    }
  }

  @media screen and (max-width: 764px) {
    .SsqEditor {
      padding: 0 16px 16px 0;
    }

    .download {
      .down_tit {
        padding: 16px 16px 0 !important;
        margin-bottom: 16px !important;
      }

      > div:last-child {
        padding: 0 16px 16px !important;
      }
    }

    .Result-bt {
      padding: 0;
      display: block;

      > div {
        width: 100%;
        padding: 24px 16px;
      }

      > div + div {
        border-top: solid 1px #C7D6EA;
        border-left: none;
      }
    }
  }
`;

export const SolutionRelated = styled.div`
  + div {
    margin-top: 1.6rem;
  }

  .related_tit {
    font-size: 1.2rem;

    img {
      vertical-align: middle;
    }
  }

  .related_list {
    color: #407DD8;
    font-size: 1.2rem;

    > div {
      display: inline-block;
      line-height: 2.4rem;

      span {
        cursor: pointer;
      }

      .comma {
        color: #90A0B6;
        margin-right: .4rem;
      }
    }
  }

  .related_list.one-line > div {
    display: block;
  }

`;


//0914 -추가 시작

export const QnaPagePopupTop = styled.div`
  .review-input.modify {
    padding: 2.2rem 2.4rem !important;
  }

  .view-header {
    width: 100%;
    border-bottom: .1rem solid #C7D6EA;
    margin-bottom: 3.5rem;
    padding: 0 1.6rem;
    position: relative;
  }

  .view-header-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    //overflow-x: auto;
    height: 6.4rem;
  }

  .view-header-item::-webkit-scrollbar {
    display: none;
  }

  .v-header-left {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .v-header-right {
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
  }

  .qna-check {
    margin-right: 1.2rem;
    display: inline-flex;
  }

  .qna-check .icon-qna {
    width: 40px;
    height: 40px;
  }

  .qna-check .icon-qna.red-a {
    background: url("${SsqImages.community.icon.communityQnaBigA()}") no-repeat center;
  }

  .qna-check .icon-qna.q {
    background: url("${SsqImages.community.icon.communityQnaQ()}") no-repeat center;
  }

  .qna-check .icon-qna.a {
    background: url(${SsqImages.community.icon.communityQnaCheck()}) no-repeat;
  }

  .qna-check .icon-qna.a > span {
    color: #34A597;
    ${fontFamilyMedium};
    font-size: 1rem;
    padding: 0 0.2rem;
    display: inline;
  }

  .btn-list-modify {
    width: 2rem;
    height: 2rem;
    background-image: url("${SsqImages.community.icon.communitySpotMore()}");
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: block;
  }

  .modify-item-body {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    width: 80px;
    position: absolute;
    right: 0.8rem;
    top: 4.8rem;
    z-index: 2;
    display: none;
  }

  .modify-btn {
    color: #2A2C2F;
    ${fontFamilyMedium};
    font-size: 12px;
    width: 80px;
    height: 48px;
    line-height: 48px;
  }

  .modify-btn:first-child {
    border-radius: 4px 4px 0 0;
    background: #fff;
  }

  .modify-btn:last-child {
    border-radius: 0 0 4px 4px;
    border-top: 1px solid #C7D6EA;
    background: #fff;
  }

  .modify-btn.notify {
    border-radius: .4rem;
    border: none;
  }

  .post-button.send {
    background: #407EDB url("${SsqImages.community.icon.communityBtnSend()}") no-repeat 1.3rem center;
    width: auto;
    padding: 0 2rem 0 4rem;
    margin-left: 1.2rem;

    :hover {
      background: #3B71C4 url("${SsqImages.community.icon.communityBtnSend()}") no-repeat 1.3rem center;
    }
  }

  .delete-button {
    width: auto
  }

  .batch-button {
    color: #7783A1 !important;
    width: auto !important;
    padding: 0 2rem;
  }

  .batch-button.white {
    color: #fff !important;
  }


  @media screen and (max-width: 764px) {

    .modify-item-body {
      width: 90%;
      position: fixed;
      left: 50%;
      top: 50%;
      max-width: 20rem;
      transform: translate(-50%, -50%);
      z-index: 101;
    }

    .modify-btn {
      width: 100%;
      max-width: 20rem;
    }

    .mobile-black {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
      background: rgba(0, 0, 0, .5);
      display: none;
    }

  }

`;

//제목 상단의 태그 css
export const QnaPagePopupTitleTag = styled.div`

  .view-content .view-content-tag ul li {
    ${fontFamilyRegular};
    font-size: 1.2rem;
    color: #7783A1;
    display: inline-block;
    margin-right: 1.2rem;
    margin-bottom: 1rem;
  }

  .view-content .view-content-tag ul > li.type {
    margin-right: 1.8rem;
  }

  .view-content .view-content-tag ul > li.doc-type.qna_q {
    ${fontFamilyMedium};
  }

  .view-content .view-content-tag ul > li.doc-type.tip {
    font-size: 1.2rem;
    margin-right: 1rem;
    ${fontFamilyMedium};;
    line-height: 1.6;
    width: 5rem;
    text-align: center;
    border-radius: 1rem;
    background: #34a597;
    color: #fff;
  }

  .view-content .view-content-tag ul > li.doc-type.doc_evt {
    font-size: 1.2rem;
    margin-right: 1rem;
    ${fontFamilyMedium};
    line-height: 1.6;
    width: 5rem;
    text-align: center;
    border-radius: 1rem;
    background: #407EDB;
    color: #fff;
  }

  .view-content .view-content-tag ul > li.doc-type.doc_noti {
    font-size: 1.2rem;
    margin-right: 1rem;
    ${fontFamilyMedium};
    line-height: 1.6;
    width: 5rem;
    text-align: center;
    border-radius: 1rem;
    background: #407EDB;
    color: #fff;
  }

  .view-content .view-content-tag ul > li.doc-type.job_offer {
    font-size: 1.2rem;
    margin-right: 1rem;
    ${fontFamilyMedium};
    line-height: 1.6;
    width: 5rem;
    text-align: center;
    border-radius: 1rem;
    background: #756CD9;
    color: #fff;
  }

  .view-content .view-content-tag ul > li.doc-type.job_search {
    font-size: 1.2rem;
    margin-right: 1rem;
    ${fontFamilyMedium};
    line-height: 1.6;
    width: 5rem;
    text-align: center;
    border-radius: 1rem;
    background: #756CD9;
    color: #fff;
  }

`;


//페이지 전체 css
export const QnaPagePopupMain = styled.div`
  .community-view {
    width: 100%;
    height: 100%;
    min-height: 90rem;
    background: rgb(238, 241, 246);
    position: relative;
    //top: 6rem;
  }

  .view-container {
    padding: 3.2rem 0 4rem;
  }

  .community__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 40px;
  }

  .community__content div > .content {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
    border-radius: 4px;
    width: 84rem;
    //min-height: 40rem;
    margin-bottom: 1.6rem;
  }

  .community__content div > .content.write {
    margin-bottom: 0
  }

  .community__content div > .content.write .cke_chrome {
    border: none;
  }

  community__content .content.write .cke_chrome .cke_top {
    border: none;
    background: #F5F8FD !important;
  }

  ul.comment-box.solution-profile {
    display: flex;
    margin-top: 2rem;
    display: none;
  }

  ul.comment-box.solution-profile.qna-view {
    margin-top: -2rem;
    margin-bottom: 2rem;
  }

  ul.comment-box {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* margin-top: 1rem; */
  }

  ul.comment-box > li {
    margin-right: .6rem;
    font-size: 1.2rem;
    color: #7783A1;
    ${fontFamilyMedium};
    flex-shrink: 0;
    line-height: 2;
    position: relative;
  }

  .v-header-right ul.comment-box > li {
    margin-right: 1.6rem;
  }

  .v-header-left ul.comment-box > li {
    margin-right: .6rem;
  }

  ul.comment-box .c-txt {
    height: 1.8rem;
    line-height: 1.8rem;
    padding: 0 1rem;
    font-size: 1rem;
    border-radius: 2rem;
  }

  ul.comment-box .c-name {
    color: #2A2C2F;
    font-size: 1.3rem;
  }

  ul.comment-box .c-name span:first-child {
    width: 3.2rem;
    height: 3.2rem;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #F7B91B;
    border-radius: 100%;
    overflow: hidden;
    margin-right: .4rem;
    font-size: 1.6rem;
    line-height: 3rem;
  }

  ul.comment-box .c-name span:last-child {
    vertical-align: middle;
    line-height: 3.2rem;
  }

  ul.comment-box .c-name span img {
    width: 100%;
    height: 100%;
  }

  ul.comment-box .c-name span.unknown {
    border: none;
  }

  ul.comment-box .c-time {
    background-image: url("${SsqImages.community.icon.communityTime()}");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1.6rem;
    padding-left: 1.8rem;
    ${fontFamilyRegular};
  }

  ul.comment-box .c-comment {
    background: url(${img_icon_c_comment}) no-repeat left center;
    padding-left: 1.8rem;
    color: #407EDB;
  }

  ul.comment-box .c-view {
    color: #407EDB;
    background-image: url("${SsqImages.community.icon.communityView()}");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1.6rem;
    padding-left: 1.8rem;
    ${fontFamilyRegular};
  }

  ul.comment-box .c-good {
    color: #407EDB;
    padding-left: 1.8rem;
    background-image: url("${SsqImages.community.icon.communityGood()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .c-not-bad {
    color: #FF215D;
    padding-left: 1.8rem;
    background-image: url("${SsqImages.community.icon.communityNotBad()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .finish-button {
    min-width: 96px;
    width: auto;
    height: 40px;
    background-color: #7783A1;
    color: #fff;
    margin-right: 1rem;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 1rem;

    img {
      margin: auto;
    }

    div {
      margin: auto;
    }
  }

  ul.comment-box .finish-button.on {
    width: auto;
    height: 40px;
    background-color: #407EDB;
    color: #fff;
    margin-right: 1rem;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 1rem;

    img {
      margin: auto;
    }

    div {
      margin: auto;
    }
  }

  ul.comment-box .view-like {
    height: 2.4rem;
    line-height: 2.4rem;
    background-image: url("${SsqImages.community.icon.communityGood24()}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 2.8rem;
    color: #407EDA;
    ${fontFamilyRegular};
  }

  ul.comment-box .view-like.on {
    background-image: url("${SsqImages.community.icon.communityGoodOn24()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .view-bad {
    height: 2.4rem;
    line-height: 2.4rem;
    background-image: url("${SsqImages.community.icon.communityNotBad24()}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    padding-left: 2.8rem;
    color: #FF215D;
    ${fontFamilyRegular};
  }

  ul.comment-box .view-bad.on {
    background-image: url("${SsqImages.community.icon.communityNotBadOn24()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .view-bookmark {
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    background-image: url("${SsqImages.community.icon.communityBookmark()}");
    background-repeat: no-repeat;
    background-position: center;
    display: block;
  }

  ul.comment-box .view-bookmark.on {
    background-image: url("${SsqImages.community.icon.communityBookmarkOn()}");
    background-repeat: no-repeat;
    background-position: left center;
  }

  ul.comment-box .view-share {
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    background-image: url("${SsqImages.common.icon.shareImg()}");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  /*----- 오른쪽 내용  aside  시작 -----*/

  .editor_list {
    padding: 0 0 2.8rem;

    .list_empty {
      font-size: 1.2rem;
      color: #7783A1;

      img {
        vertical-align: middle;
        margin-right: .8rem;
      }

      .list {
        ${fontFamilyRegular};
        margin: 4px;
        padding-left: 12px;
        text-indent: -12px;
        line-height: 1.5;
      }

      .title {
        display: inline-block;
        padding-bottom: 10px;
      }

      .em {
        color: #407EDB;
      }
    }

  }

  aside.community-view-aside {
    //width: 33%;
  }

  .aside-content {
    //position: fixed;
    //height: 65%;
    width: 32rem;
    overflow-y: auto;
    padding-bottom: 3rem;
  }

  .community__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .commu_bottom_cont {
      display: flex;
      width: calc(100% / 1);
      justify-content: flex-start;

      > div {
        width: 100%;
      }
    }
  }

  /*----- 오른쪽 내용  aside  끝 -----*/

  .view-content {
    padding: 0 4rem;
  }


  .view-footer {
    border-top: 1px dashed #ddd;
  }

  .comment-info-wrap {
    display: none;
  }

  /*----------------------------------------*/
  /*---------- 타블렛 모바일 공통 ----------*/
  /*----------------------------------------*/

  @media screen and (max-width: 1199px) {

    .view-container {
      padding-top: 0;
    }

    .SsqEditor img {
      height: auto;
      margin: 35px 0px;
      max-width: 100% !important;
    }

    .aside-content {
      position: relative;
      height: auto;
      width: 100%;
      overflow-y: auto;
      padding: 0 3rem;

      > div {
        display: flex;
        justify-content: end;
      }
    }

    aside.community-view-aside {
      width: 100%;
    }

    aside .solution-list {
      display: none;
    }

    .community__content > .content {
      width: 100%;
    }

    .community__content {
      flex-wrap: wrap;
    }

    .community__content > div {
      width: 100%;
    }

    .community__content div > .content {
      width: 100%;
    }

    .review-input.on {
      padding: 2.2rem 2.4rem !important;
    }

  }
}

/*----------------------------------------*/
/*---------- 모바일 사이즈 ----------*/
/*----------------------------------------*/

@media screen and (max-width: 764px) {

  .v-header-left ul.comment-box {
    display: none;
  }

  .comment-info-wrap {
    margin-top: 2rem;
    display: block !important;

    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .community__content {
    .commu_bottom_cont {
      flex-direction: column;
    }
  }

  .view-outer {
    border-bottom: 1px solid #C7D6EA;
    padding: 0 3rem 3rem !important;
  }

  .aside-content {
    margin-top: 0;
  }

  .aside-content {
    .view-content {
      padding: 0 1.6rem;
    }

    .community__content > div {
      width: 100%;
    }

    .community__content div > .content {
      width: 100%;
    }

    .SsqEditor img {
      height: auto;
      margin: 35px 0px;
      /* max-width: 100% !important; */
    }

    .SsqEditor {
      overflow-x: auto;
    }

    .SsqEditor table {
      max-width: 100%;
      table-layout: fixed;
      word-break: break-word;
      width: 100% !important;
    }

    /*-- 상단에 나오는 수정화면과, 끝부분 댓글에 나오는 화면을 같이 쓰는 부분 --*/

    .v-header-left {
      margin-right: 4rem;
    }

    .content.write .v-header-left {
      margin-right: .4rem;
    }

    .view-content .view-content-tag {
      padding: 0 1.6rem
    }

    .v-header-left .comment-box {
      display: none;
    }

    .v-header-left .qna-type-info {
      color: #7783A1;
      font-size: 1.2rem;
      line-height: 1.8rem;
      padding-left: 1.6rem;
      display: block;
    }


    .view-content .view-content-tag ul li.blog-type {
      display: none
    }

    ul.comment-box.solution-profile {
      display: flex;
      flex-wrap: wrap;
    }

    .v-header-left .comment-box.qna-write {
      display: flex;
      @media screen and (max-width: 764px) {
        display: none;
      }
    }

    .qna-check-header {
      margin-left: 1.6rem
    }

    /*-- 상단에 나오는 수정화면과, 끝부분 댓글에 나오는 화면을 같이 쓰는 부분 --*/
  }
`;

export const QnaPageAside = styled.div`

  .solution-list h1 > button {
    font-size: 1.3rem;
    color: #2A2C2F;
    line-height: 2.8rem;
    position: relative;
    padding-left: 1.6rem;
    ${fontFamilyMedium};
    font-style: normal;
  }

  .solution-list h1 > button::before {
    display: inline-block;
    content: "";
    width: 4px;
    height: 32px;
    background: rgb(199, 214, 234);
    position: absolute;
    left: 0px;
  }

  .solution-list h2 > button {
    font-size: 1.1rem;
    color: #2A2C2F;
    line-height: 2.1rem;
    padding-left: 3.2rem;
    position: relative;
    ${fontFamilyMedium};
    font-style: normal;
  }

  .solution-list h2 > button::before {
    display: inline-block;
    content: "";
    width: 4px;
    height: 24px;
    background: rgb(199, 214, 234);
    position: absolute;
    left: 0px;
  }

  .solution-list h3 > button {
    font-size: 0.9rem;
    color: #7783A1;
    line-height: 1.4rem;
    padding-left: 4.8rem;
    position: relative;
    ${fontFamilyMedium};
    font-style: normal;
  }

  .solution-list h3 > button::before {
    display: inline-block;
    content: "";
    width: 4px;
    height: 100%;
    background: rgb(199, 214, 234);
    position: absolute;
    left: 0px;
  }
`;


export const QnaPageFooter = styled.div`

  /*다운로드 부분*/

  .content-download {
    padding: 4rem
  }

  .content-download > p.title {
    color: #2A2C2F;
    font-size: 1.8rem;
    ${fontFamilyBold};
  }

  .content-download > ul.down-list {
    //margin-top: 2.4rem;
  }

  .content-download > ul.down-list .down-icon {
    position: relative;
  }

  .content-download > ul.down-list > li {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .content-download > ul.down-list li button.down-title {
    color: #407EDB;
    ${fontFamilyMedium};
    font-size: 1.3rem;
    padding-right: 0.7rem;
      //background-image: url("${SsqImages.common.icon.downloadImg}");
    background-repeat: no-repeat;
    background-position: right center;
  }

  .content-download > ul.down-list li ul.file-info {
    display: flex;
    margin-left: .4rem;
  }

  .content-download > ul.down-list li ul.file-info li {
    ${fontFamilyMedium};
    font-size: 1.2rem;
    color: #7783A1;
    border-right: 1px solid #C7D6EA;
    padding: 0 .6rem;
  }

  .content-download > ul.down-list li ul.file-info li:last-child {
    border: none;
  }

  .content-download > ul.down-list li ul.file-info li > span.count {
    color: #407EDB;
    margin-left: .4rem;
  }

  .down-title {
    margin-right: .7rem;

    > a {
      color: #407EDB;
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 764px) {
    .content-download > ul.down-list li ul.file-info {
      margin-left: -0.6rem;
      margin-top: .4rem;
    }
  }

  /*하단 댓글*/

  .review-content {
    //padding: .8rem 0;
    background: #F7F8FB;
    //border-top: .1rem solid #EEF1F6;
  }

  .review-content:first-child {
    border-top: .1rem solid #EEF1F6;
  }

  .review-content-item {
    display: flex;
    flex-wrap: nowrap;
    /* align-items: center;                 */
    padding: 1rem 2.4rem;
  }

  .review-content-item > .left {
    flex-shrink: 0;
  }

  .review-content-item > .right {
    padding-top: 0.6rem;
  }

  .review-content-item > .right > ul.comment-box {
    display: inline-block;
  }

  .review-content-item > .right > ul.comment-box > li {
    display: inline-block;
  }

  .comment-content {
    margin-right: 1rem;
    ${fontFamilyRegular};
    font-size: 1.3rem;
    color: #2A2C2F;
    line-height: 2.4rem;
    white-space: pre-wrap;
  }

  .review-content-item .right .comment-content {
    order: 1;
    width: 100%;
    margin-right: 0;
  }

  .review-content-item > .right > .list-modify-body {
    position: relative;
    //vertical-align: middle;
    //display: inline-block;
  }

  .review-content-item ul.comment-box > li {
    margin-right: .6rem;
  }

  .right > .list-modify-body > .modify-item-body {
    top: 2.8rem;
    display: none;
  }

  @media screen and (max-width: 764px) {
    .right > .list-modify-body > .modify-item-body {
      top: 50%;
    }

    .review-content-item .right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .review-content-item .right .comment-box {
      order: 0;
    }

    .review-content-item .right .comment-content {
      order: 1;
      width: 100%;
      margin-right: 0;
    }


    .review-input.on {
      padding: 2.2rem 2.4rem !important;
    }

    .review-input > textarea {
      ${fontFamilyRegular};
      font-size: 16px;

      transform: scale(0.81);
      transform-origin: left;
    }

    .review-input > textarea::placeholder {
      color: #90A0B6;
      font-size: 1.7rem;
    }


  }

  /*댓글 작성*/

  .review-content-item .right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .review-input {
    padding: 0 0 0 2.4rem;
    display: flex;
    align-items: center;
    border-top: 1px solid #EEF1F6;
    background: #fff;
  }

  .review-input > textarea {
    width: calc(100% - 5rem);
    margin-right: 1rem;
    ${fontFamilyRegular};

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .review-input > textarea::placeholder {
    color: #90A0B6;
  }

  button.ent {
    width: 6.4rem;
    height: 6.4rem;
    background-position: 50%;
    background-image: url("${SsqImages.boris.CommentsBtn()}");
    background-repeat: no-repeat;
    align-self: flex-end;
  }

  button.review-del {
    width: 2.4rem;
    height: 2.4rem;
    background: url("${SsqImages.community.icon.communityReviewDel()}") no-repeat;
    margin-right: 1.6rem;
    align-self: flex-end;
  }

  button.review-enter {
    width: 2.4rem;
    height: 2.4rem;
    background: url("${SsqImages.community.icon.communityReviewEnter()}") no-repeat;
    flex-shrink: 0;
    align-self: flex-end;
  }

`;

export const QnaPageFooterCategory = styled.div`

  .view-outer {
    display: flex;
  }

  .view-outer .view-outer-left {
    width: 50%;
  }

  .view-outer .view-outer-right {
    width: 50%
  }

  .conn-category {
    margin-top: 3.2rem;
  }

  .conn-category > .title {
    color: #2A2C2F;
    font-size: 1.2rem;
    ${fontFamilyMedium};
    line-height: 2.4rem;
  }

  .conn-category > .title > span.link-icon {
    height: 2.4rem;
    background-image: url("${SsqImages.product.linkicon}");
    background-repeat: no-repeat;
    background-position: right center;
    display: inline-block;
    padding-right: 2.6rem;
  }

  .conn-category > ul li {
    margin-top: .8rem;
  }

  .conn-category > ul li > a {
    ${fontFamilyMedium};
    color: #407DD8;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1199px) {
    .view-outer {
      padding: 0 3rem;
      flex-wrap: wrap;
    }

    .view-outer .view-outer-left {
      width: 100%;
    }

    .view-outer .view-outer-right {
      width: 100%
    }
  }
`;


export const PartnerQnaPopupWrap = styled.div`
  padding: 0 !important;

  .popup_tit {
    padding: 3.2rem 3.2rem 0.8rem;
  }

  .popup_input {
    padding: 0.8rem 3.2rem 0;
    height: calc(100% - 9.5rem - 9rem);
    overflow-y: auto;
    margin-bottom: 9rem;

    .input_wrap {
      + .input_wrap {
        margin-top: 1.6rem;
      }

      p.tit {
        font-size: 1.3rem;
        margin-bottom: 4px;
      }
    }

    .input_row {
      gap: 8px;

      select {
        ${fontFamilyRegular};
        border: 1px solid #C7D6EA;
        border-radius: 0.4rem;
        width: 100%;
        height: 4rem;
        font-size: 1.3rem;
        padding: 0 1.2rem;
        color: #2A2C2F
      }

      .date-pickers .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          width: 100%;
          height: 40px;
        }
      }

      + .input_row {
        margin-top: 8px;
      }
    }

  }
`;
export const PartnerQnaPopupArea = styled(AllPopupWrap)`
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .popup_noti {
    position: relative;

    .requ {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 1rem;
      color: #7783A1 !important;

      span:first-child {
        color: #E6003E
      }
    }
  }

  .popup_btn {
    width: 100%;
    background-color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    height: 9rem;
    border-radius: 0 0 4px 4px;
    padding: 0 3rem 0 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Submit-Button {
      margin: 0;

      &.cancle {
        width: 112px;
        background: #7783A1;
      }

      &.save {
        width: calc(100% - 120px);
      }
    }
  }

  @media screen and (max-width: 1199px) {

    > div {
      display: block !important;
      width: 100% !important;
      max-width: 100%;
      height: 100% !important;
      border-radius: 0 !important;
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
    }
  }
`;
